import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cogoToast from 'cogo-toast';
import {Link} from 'react-router-dom';
import {Row, Col, Form, Button} from 'react-bootstrap';
import {Breadcrumbs, BreadcrumbItem} from "sancho";
import MarkdownEditor from '../components/MarkdownEditor';
import CountrySelect from '../components/CountrySelect';
import CitySelect from '../components/CitySelect';
import CurrencySelect from '../components/CurrencySelect';
import ProductTypeSelect from '../components/ProductTypeSelect';
import SupplierCodeSelect from '../components/SupplierCodeSelect';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import _extend from 'lodash/extend';
import _split from 'lodash/split';

import {
  createProduct,
} from '../actions/product';

class CreateProductPage extends Component {
  constructor(props) {
    super(props);

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onMarkdownChange = this.onMarkdownChange.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);

    this.state = {
      form: {
        lang: "en-US"
      },
      isSubmitClicked: false,
      files: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.newAddedActivityId && this.props.newAddedActivityId) {
      this.props.history.push(`/product/${this.props.newAddedActivityId}`)
    }
  }

  onFieldChange(e) {
    this.setState({
      form: _extend(this.state.form, {
        [e.target.name]: e.target.value
      })
    })
  }

  onSelectChange(name, value) {
    this.setState({
      form: _extend(this.state.form, {
        [name]: value
      })
    })
  }

  onMarkdownChange(name, value) {
    if (name.includes('-')) {
      const deep = {
        [_split(name, '-')[1]]: value
      }

      this.setState({
        form: _extend(this.state.form, {
          [_split(name, '-')[0]]: _extend(this.state.form[_split(name, '-')[0]], deep)
        })
      })
    } else {
      this.setState({
        form: _extend(this.state.form, {
          [name]: value
        })
      })
    }
  }

  onSubmitClick() {
    if (
      this.state.form.title &&
      this.state.form.producttypecode &&
      this.state.form.countryId &&
      this.state.form.cityId &&
      this.state.form.enginecode
    ) {
      this.setState({
        isSubmitClicked: false
      })
      this.props.createProduct(this.state.form)
    } else {
      this.setState({
        isSubmitClicked: true
      })
      cogoToast.error(<div>Something is missed.</div>)
    }
  };

  onKeyDown(event) {
    if (event.key === "Enter") {
			this.onSubmitClick()
    }
  };

  onImageUpload(e) {
    console.log(e)
    e.preventDefault();
    let files = e.target.files;
    if(files && files.length > 0) {
      console.log(files)
       // this.setState({device_photos: files});
    } else {
      console.log("no files selected");
    }
  }

  getFiles(files){
    this.setState({ files: files })
  }

  render () {

    return (
      <div>
        <title>Create Product | Product Management System</title>
        <div className="container">
          <Breadcrumbs className="mt-1 mb-3">
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Create Product</BreadcrumbItem>
          </Breadcrumbs>

          <h1 className="text-center">Create Product</h1>

          <Form.Group as={Row} controlId="title">
            <Form.Label column lg="2">Title *</Form.Label>
            <Col>
              <Form.Control
                type="text"
                autoComplete="off"
                name="title"
                onChange={(e) => this.onFieldChange(e)}
                value={this.state.form.title ? this.state.form.title : ''}
                isInvalid={this.state.isSubmitClicked && !this.state.form.title}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="subtitle">
            <Form.Label column lg="2">Subtitle</Form.Label>
            <Col>
              <Form.Control
                type="text"
                autoComplete="off"
                name="subtitle"
                onChange={(e) => this.onFieldChange(e)}
                value={this.state.form.subtitle} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="producttypecode">
            <Form.Label column lg="2">Product Type *</Form.Label>
            <Col>
              <ProductTypeSelect
                handleSelect={this.onSelectChange}
                selectedCode={this.state.form.producttypecode}
                name="producttypecode"
                isInvalid={this.state.isSubmitClicked && !this.state.form.producttypecode}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="country">
            <Form.Label column lg="2">Country *</Form.Label>
            <Col>
              <CountrySelect
                handleSelect={this.onSelectChange}
                selectedCode={this.state.form.countryId}
                name="countryId"
                isInvalid={this.state.isSubmitClicked && !this.state.form.countryId}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="cityId">
            <Form.Label column lg="2">City *</Form.Label>
            <Col>
              <CitySelect
                handleSelect={this.onSelectChange}
                countryId={this.state.form.countryId}
                selectedCode={this.state.form.cityId}
                name="cityId"
                isInvalid={this.state.isSubmitClicked && !this.state.form.cityId}
                isDisabled={!this.state.form.countryId}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="supplierCode">
            <Form.Label column lg="2">Supplier Code *</Form.Label>
            <Col>
              <SupplierCodeSelect
                handleSelect={this.onSelectChange}
                selectedCode={this.state.form.enginecode}
                name="enginecode"
                isInvalid={this.state.isSubmitClicked && !this.state.form.enginecode}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="travelersNotes">
            <Form.Label column lg="2">Travelers Notes</Form.Label>
            <Col>
              <Form.Control
                type="text"
                autoComplete="off"
                name="travelersNotes"
                onChange={(e) => this.onFieldChange(e)}
                value={this.state.form.travelersNotes} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="duration">
            <Form.Label column lg="2">Duration</Form.Label>
            <Col>
              <Form.Control
                type="text"
                autoComplete="off"
                name="duration"
                onChange={(e) => this.onFieldChange(e)}
                value={this.state.form.duration} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="transport">
            <Form.Label column lg="2">Transport</Form.Label>
            <Col>
              <Form.Control
                type="text"
                autoComplete="off"
                name="transport"
                onChange={(e) => this.onFieldChange(e)}
                value={this.state.form.transport} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="originalamount">
            <Form.Label column lg="2">Original Amount</Form.Label>
            <Col lg="2">
              <CurrencySelect
                handleSelect={this.onSelectChange}
                selectedCode={this.state.form.originalcurr}
                name="originalcurr"
              />
            </Col>
            <Col>
              <Form.Control
                type="text"
                autoComplete="off"
                name="originalamount"
                onChange={(e) => this.onFieldChange(e)}
                value={this.state.form.originalamount} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="termsAndConditions">
            <Form.Label column lg="2">Terms And Conditions</Form.Label>
            <Col>
              <MarkdownEditor
                name="termsAndConditions"
                handleSetValue={this.onMarkdownChange}
                value={this.state.form.termsAndConditions} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="Guidelines">
            <Form.Label column lg="2">Guidelines</Form.Label>
            <Col>
              {(['additional_information', 'itinerary']).map(guideline =>
                <div className="mt-2" key={guideline}>
                  {_startCase(guideline)}
                  <MarkdownEditor
                    name={`guidelinesObj-${guideline}`}
                    handleSetValue={this.onMarkdownChange}
                    value={_get(this.state.form, ['guidelinesObj', guideline])} />
                </div>
              )}

            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="Usage">
            <Form.Label column lg="2">Usage</Form.Label>
            <Col>
              {(['meet_up_location', 'location']).map(usage =>
                <div className="mt-2" key={usage}>
                  {_startCase(usage)}
                  <MarkdownEditor
                    name={`usageObj-${usage}`}
                    handleSetValue={this.onMarkdownChange}
                    value={_get(this.state.form, ['usageObj', usage])} />
                </div>
              )}
            </Col>
          </Form.Group>

          <Row className="mb-3">
            <Col lg="2">Images</Col>
            <Col className="text-muted">
              (After Creating Product, you can add images)
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg="2">Items</Col>
            <Col className="text-muted">
              (After Creating Product, you can add items and SKU)
            </Col>
          </Row>

          <div className="text-center pt-5">
            <Button variant="primary" type="submit" size="lg" onClick={() => this.onSubmitClick()}>
              Create Product
            </Button>
          </div>

        </div>
      </div>
    );
  }
}


CreateProductPage.propTypes = {
	newAddedActivityId: PropTypes.string,
};

function mapStateToProps(state) {
	return {
    newAddedActivityId: state.product.newAddedActivityId,
	}
}

const mapDispatchToProps = {
  createProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductPage);
