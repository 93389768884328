// index
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOG_OUT = 'LOG_OUT';

export const GET_DETAIL_BY_TOKEN_REQUEST = 'GET_DETAIL_BY_TOKEN_REQUEST';
export const GET_DETAIL_BY_TOKEN_SUCCESS = 'GET_DETAIL_BY_TOKEN_SUCCESS';
export const GET_DETAIL_BY_TOKEN_FAILURE = 'GET_DETAIL_BY_TOKEN_FAILURE';

// booking
export const GET_BOOKING_LIST_REQUEST = 'GET_BOOKING_LIST_REQUEST';
export const GET_BOOKING_LIST_SUCCESS = 'GET_BOOKING_LIST_SUCCESS';
export const GET_BOOKING_LIST_FAILURE = 'GET_BOOKING_LIST_FAILURE';

export const GET_BOOKING_DETAIL_REQUEST = 'GET_BOOKING_DETAIL_REQUEST';
export const GET_BOOKING_DETAIL_SUCCESS = 'GET_BOOKING_DETAIL_SUCCESS';
export const GET_BOOKING_DETAIL_FAILURE = 'GET_BOOKING_DETAIL_FAILURE';

export const UPDATE_BOOKING_DETAIL_REQUEST = 'UPDATE_BOOKING_DETAIL_REQUEST';
export const UPDATE_BOOKING_DETAIL_SUCCESS = 'UPDATE_BOOKING_DETAIL_SUCCESS';
export const UPDATE_BOOKING_DETAIL_FAILURE = 'UPDATE_BOOKING_DETAIL_FAILURE';

export const PROCESS_TO_MOE_REQUEST = 'PROCESS_TO_MOE_REQUEST';
export const PROCESS_TO_MOE_SUCCESS = 'PROCESS_TO_MOE_SUCCESS';
export const PROCESS_TO_MOE_FAILURE = 'PROCESS_TO_MOE_FAILURE';

export const MARK_FULFILL_REQUEST = 'MARK_FULFILL_REQUEST';
export const MARK_FULFILL_SUCCESS = 'MARK_FULFILL_SUCCESS';
export const MARK_FULFILL_FAILURE = 'MARK_FULFILL_FAILURE';

export const CLEAR_BOOKING_DETAIL = 'CLEAR_BOOKING_DETAIL';

// booking -- message
export const GET_BOOKING_MESSAGE_REQUEST = 'GET_BOOKING_MESSAGE_REQUEST';
export const GET_BOOKING_MESSAGE_SUCCESS = 'GET_BOOKING_MESSAGE_SUCCESS';
export const GET_BOOKING_MESSAGE_FAILURE = 'GET_BOOKING_MESSAGE_FAILURE';

export const DELETE_BOOKING_MESSAGE_REQUEST = 'DELETE_BOOKING_MESSAGE_REQUEST';
export const DELETE_BOOKING_MESSAGE_SUCCESS = 'DELETE_BOOKING_MESSAGE_SUCCESS';
export const DELETE_BOOKING_MESSAGE_FAILURE = 'DELETE_BOOKING_MESSAGE_FAILURE';

export const CREATE_BOOKING_MESSAGE_REQUEST = 'CREATE_BOOKING_MESSAGE_REQUEST';
export const CREATE_BOOKING_MESSAGE_SUCCESS = 'CREATE_BOOKING_MESSAGE_SUCCESS';
export const CREATE_BOOKING_MESSAGE_FAILURE = 'CREATE_BOOKING_MESSAGE_FAILURE';

export const READ_BOOKING_MESSAGE_REQUEST = 'READ_BOOKING_MESSAGE_REQUEST';
export const READ_BOOKING_MESSAGE_SUCCESS = 'READ_BOOKING_MESSAGE_SUCCESS';
export const READ_BOOKING_MESSAGE_FAILURE = 'READ_BOOKING_MESSAGE_FAILURE';

export const GET_UNREAD_BOOKING_MESSAGE_REQUEST = 'GET_UNREAD_BOOKING_MESSAGE_REQUEST';
export const GET_UNREAD_BOOKING_MESSAGE_SUCCESS = 'GET_UNREAD_BOOKING_MESSAGE_SUCCESS';
export const GET_UNREAD_BOOKING_MESSAGE_FAILURE = 'GET_UNREAD_BOOKING_MESSAGE_FAILURE';

// booking -- sku
export const GET_BOOKING_SKU_REQUEST = 'GET_BOOKING_SKU_REQUEST';
export const GET_BOOKING_SKU_SUCCESS = 'GET_BOOKING_SKU_SUCCESS';
export const GET_BOOKING_SKU_FAILURE = 'GET_BOOKING_SKU_FAILURE';

export const UPDATE_BOOKING_SKU_REQUEST = 'UPDATE_BOOKING_SKU_REQUEST';
export const UPDATE_BOOKING_SKU_SUCCESS = 'UPDATE_BOOKING_SKU_SUCCESS';
export const UPDATE_BOOKING_SKU_FAILURE = 'UPDATE_BOOKING_SKU_FAILURE';

export const ADD_BOOKING_SKU_REQUEST = 'ADD_BOOKING_SKU_REQUEST';
export const ADD_BOOKING_SKU_SUCCESS = 'ADD_BOOKING_SKU_SUCCESS';
export const ADD_BOOKING_SKU_FAILURE = 'ADD_BOOKING_SKU_FAILURE';

// booking -- extra info
export const GET_BOOKING_EXTRA_INFO_REQUEST = 'GET_BOOKING_EXTRA_INFO_REQUEST';
export const GET_BOOKING_EXTRA_INFO_SUCCESS = 'GET_BOOKING_EXTRA_INFO_SUCCESS';
export const GET_BOOKING_EXTRA_INFO_FAILURE = 'GET_BOOKING_EXTRA_INFO_FAILURE';

export const ADD_BOOKING_TRAVELER_INFO_REQUEST = 'ADD_BOOKING_TRAVELER_INFO_REQUEST';
export const ADD_BOOKING_TRAVELER_INFO_SUCCESS = 'ADD_BOOKING_TRAVELER_INFO_SUCCESS';
export const ADD_BOOKING_TRAVELER_INFO_FAILURE = 'ADD_BOOKING_TRAVELER_INFO_FAILURE';

export const DELETE_BOOKING_TRAVELER_INFO_REQUEST = 'DELETE_BOOKING_TRAVELER_INFO_REQUEST';
export const DELETE_BOOKING_TRAVELER_INFO_SUCCESS = 'DELETE_BOOKING_TRAVELER_INFO_SUCCESS';
export const DELETE_BOOKING_TRAVELER_INFO_FAILURE = 'DELETE_BOOKING_TRAVELER_INFO_FAILURE';

// booking - history
export const GET_BOOKING_HISTORY_REQUEST = 'GET_BOOKING_HISTORY_REQUEST';
export const GET_BOOKING_HISTORY_SUCCESS = 'GET_BOOKING_HISTORY_SUCCESS';
export const GET_BOOKING_HISTORY_FAILURE = 'GET_BOOKING_HISTORY_FAILURE';

export const ADD_BOOKING_HISTORY_REQUEST = 'ADD_BOOKING_HISTORY_REQUEST';
export const ADD_BOOKING_HISTORY_SUCCESS = 'ADD_BOOKING_HISTORY_SUCCESS';
export const ADD_BOOKING_HISTORY_FAILURE = 'ADD_BOOKING_HISTORY_FAILURE';

// product
export const GET_PRODUCT_LIST_REQUEST = 'GET_PRODUCT_LIST_REQUEST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAILURE = 'GET_PRODUCT_LIST_FAILURE';

export const GET_PRODUCT_DETAIL_REQUEST = 'GET_PRODUCT_DETAIL_REQUEST';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILURE = 'GET_PRODUCT_DETAIL_FAILURE';

export const UPDATE_PRODUCT_DETAIL_REQUEST = 'UPDATE_PRODUCT_DETAIL_REQUEST';
export const UPDATE_PRODUCT_DETAIL_SUCCESS = 'UPDATE_PRODUCT_DETAIL_SUCCESS';
export const UPDATE_PRODUCT_DETAIL_FAILURE = 'UPDATE_PRODUCT_DETAIL_FAILURE';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const GET_PRODUCT_IMAGES_REQUEST = 'GET_PRODUCT_IMAGES_REQUEST';
export const GET_PRODUCT_IMAGES_SUCCESS = 'GET_PRODUCT_IMAGES_SUCCESS';
export const GET_PRODUCT_IMAGES_FAILURE = 'GET_PRODUCT_IMAGES_FAILURE';

export const UPLOAD_PRODUCT_IMAGE_REQUEST = 'UPLOAD_PRODUCT_IMAGE_REQUEST';
export const UPLOAD_PRODUCT_IMAGE_SUCCESS = 'UPLOAD_PRODUCT_IMAGE_SUCCESS';
export const UPLOAD_PRODUCT_IMAGE_FAILURE = 'UPLOAD_PRODUCT_IMAGE_FAILURE';

export const DELETE_PRODUCT_IMAGE_REQUEST = 'DELETE_PRODUCT_IMAGE_REQUEST';
export const DELETE_PRODUCT_IMAGE_SUCCESS = 'DELETE_PRODUCT_IMAGE_SUCCESS';
export const DELETE_PRODUCT_IMAGE_FAILURE = 'DELETE_PRODUCT_IMAGE_FAILURE';

export const CLEAR_PRODUCT_DETAIL = 'CLEAR_PRODUCT_DETAIL';
export const CLEAR_SEARCH_PRODUCT_CRITERIA = 'CLEAR_SEARCH_PRODUCT_CRITERIA';

// product - item
export const GET_PRODUCT_ITEM_LIST_REQUEST = 'GET_PRODUCT_ITEM_LIST_REQUEST';
export const GET_PRODUCT_ITEM_LIST_SUCCESS = 'GET_PRODUCT_ITEM_LIST_SUCCESS';
export const GET_PRODUCT_ITEM_LIST_FAILURE = 'GET_PRODUCT_ITEM_LIST_FAILURE';

export const GET_PRODUCT_ITEM_DETAIL_REQUEST = 'GET_PRODUCT_ITEM_DETAIL_REQUEST';
export const GET_PRODUCT_ITEM_DETAIL_SUCCESS = 'GET_PRODUCT_ITEM_DETAIL_SUCCESS';
export const GET_PRODUCT_ITEM_DETAIL_FAILURE = 'GET_PRODUCT_ITEM_DETAIL_FAILURE';

export const UPDATE_ITEM_REQUEST = 'UPDATE_ITEM_REQUEST';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAILURE = 'UPDATE_ITEM_FAILURE';

export const DELETE_ITEM_REQUEST = 'DELETE_ITEM_REQUEST';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';

export const CREATE_ITEM_REQUEST = 'CREATE_ITEM_REQUEST';
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
export const CREATE_ITEM_FAILURE = 'CREATE_ITEM_FAILURE';

// product - sku
export const GET_PRODUCT_SKU_REQUEST = 'GET_PRODUCT_SKU_REQUEST';
export const GET_PRODUCT_SKU_SUCCESS = 'GET_PRODUCT_SKU_SUCCESS';
export const GET_PRODUCT_SKU_FAILURE = 'GET_PRODUCT_SKU_FAILURE';

export const UPDATE_SKU_REQUEST = 'UPDATE_SKU_REQUEST';
export const UPDATE_SKU_SUCCESS = 'UPDATE_SKU_SUCCESS';
export const UPDATE_SKU_FAILURE = 'UPDATE_SKU_FAILURE';

export const DELETE_SKU_REQUEST = 'DELETE_SKU_REQUEST';
export const DELETE_SKU_SUCCESS = 'DELETE_SKU_SUCCESS';
export const DELETE_SKU_FAILURE = 'UPDATE_SKU_FAILURE';

export const CREATE_SKU_REQUEST = 'CREATE_SKU_REQUEST';
export const CREATE_SKU_SUCCESS = 'CREATE_SKU_SUCCESS';
export const CREATE_SKU_FAILURE = 'UPDATE_SKU_FAILURE';

// product - extrainfo
export const UPDATE_EXTRA_INFO_REQUEST = 'UPDATE_EXTRA_INFO_REQUEST';
export const UPDATE_EXTRA_INFO_SUCCESS = 'UPDATE_EXTRA_INFO_SUCCESS';
export const UPDATE_EXTRA_INFO_FAILURE = 'UPDATE_EXTRA_INFO_FAILURE';

export const DELETE_EXTRA_INFO_REQUEST = 'DELETE_EXTRA_INFO_REQUEST';
export const DELETE_EXTRA_INFO_SUCCESS = 'DELETE_EXTRA_INFO_SUCCESS';
export const DELETE_EXTRA_INFO_FAILURE = 'UPDATE_EXTRA_INFO_FAILURE';

export const CREATE_EXTRA_INFO_REQUEST = 'CREATE_EXTRA_INFO_REQUEST';
export const CREATE_EXTRA_INFO_SUCCESS = 'CREATE_EXTRA_INFO_SUCCESS';
export const CREATE_EXTRA_INFO_FAILURE = 'UPDATE_EXTRA_INFO_FAILURE';

// product - daily rates
export const GET_DAILY_RATES_REQUEST = 'GET_DAILY_RATES_REQUEST';
export const GET_DAILY_RATES_SUCCESS = 'GET_DAILY_RATES_SUCCESS';
export const GET_DAILY_RATES_FAILURE = 'GET_DAILY_RATES_FAILURE';

export const UPDATE_DAILY_RATE_REQUEST = 'UPDATE_DAILY_RATE_REQUEST';
export const UPDATE_DAILY_RATE_SUCCESS = 'UPDATE_DAILY_RATE_SUCCESS';
export const UPDATE_DAILY_RATE_FAILURE = 'UPDATE_DAILY_RATE_FAILURE';


// product - markup
export const GET_PRODUCT_MARKUP_REQUEST = 'GET_PRODUCT_MARKUP_REQUEST';
export const GET_PRODUCT_MARKUP_SUCCESS = 'GET_PRODUCT_MARKUP_SUCCESS';
export const GET_PRODUCT_MARKUP_FAILURE = 'GET_PRODUCT_MARKUP_FAILURE';

export const VIEW_ALL_PRODUCT_MARKUP_REQUEST = 'VIEW_ALL_PRODUCT_MARKUP_REQUEST';
export const VIEW_ALL_PRODUCT_MARKUP_SUCCESS = 'VIEW_ALL_PRODUCT_MARKUP_SUCCESS';
export const VIEW_ALL_PRODUCT_MARKUP_FAILURE = 'VIEW_ALL_PRODUCT_MARKUP_FAILURE';

export const DELETE_PRODUCT_MARKUP_REQUEST = 'DELETE_PRODUCT_MARKUP_REQUEST';
export const DELETE_PRODUCT_MARKUP_SUCCESS = 'DELETE_PRODUCT_MARKUP_SUCCESS';
export const DELETE_PRODUCT_MARKUP_FAILURE = 'DELETE_PRODUCT_MARKUP_FAILURE';

export const UPDATE_PRODUCT_MARKUP_REQUEST = 'UPDATE_PRODUCT_MARKUP_REQUEST';
export const UPDATE_PRODUCT_MARKUP_SUCCESS = 'UPDATE_PRODUCT_MARKUP_SUCCESS';
export const UPDATE_PRODUCT_MARKUP_FAILURE = 'UPDATE_PRODUCT_MARKUP_FAILURE';

export const CREATE_PRODUCT_MARKUP_REQUEST = 'CREATE_PRODUCT_MARKUP_REQUEST';
export const CREATE_PRODUCT_MARKUP_SUCCESS = 'CREATE_PRODUCT_MARKUP_SUCCESS';
export const CREATE_PRODUCT_MARKUP_FAILURE = 'CREATE_PRODUCT_MARKUP_FAILURE';

// msg
