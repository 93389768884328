import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {prettyDateRange, slashDateFormat, slashDateFormatToday} from '../util/transformDate';

class CustomDateRangePicker extends Component {
	constructor(props) {
		super(props);
		this.onDateChange = this.onDateChange.bind(this)
		this.onClearDate = this.onClearDate.bind(this)
	}

	onDateChange(event, picker) {
		this.props.handleSelect(
			picker.startDate.format(),
			picker.endDate.format(),
			this.el.name,
			this.props.index,
		)
	}

	onClearDate() {
		this.props.handleSelect(
			this.el.name,
			this.props.index,
			'',
			''
		)
	}

	onChange() {

	}

  render() {
		return (
			<div className="relative">
				<DateRangePicker
					onApply={this.onDateChange}
					autoApply
					startDate={this.props.startDateValue ? slashDateFormat(this.props.startDateValue) : slashDateFormatToday()}
					endDate={this.props.endDateValue ? slashDateFormat(this.props.endDateValue) : slashDateFormatToday()}
				>
					<Form.Control
						type="text"
						value={this.props.startDateValue ? prettyDateRange(this.props.startDateValue, this.props.endDateValue) : ''}
						name={this.props.name}
						ref={ref => this.el = ref}
						autoComplete="off"
						onChange={() => this.onChange()}
						placeholder="Pick a Date range"
						className={this.props.isSmall ? 'form-control-sm' : ''}
						isInvalid={this.props.isInvalid}
					/>
				</DateRangePicker>
				{this.props.isClearAvailable && this.props.startDateValue && <button type="button" onClick={() => this.onClearDate()} className="btn date-range__cancel-icon"><FontAwesomeIcon icon={faTimes} size="sm" /></button>}
			</div>
    );
  }
}

CustomDateRangePicker.propTypes = {
	handleSelect: PropTypes.func,
	name: PropTypes.string,
	startDateValue: PropTypes.string,
	endDateValue: PropTypes.string,
	index: PropTypes.number,
	isClearAvailable: PropTypes.bool,
	isSmall: PropTypes.bool,
	isInvalid: PropTypes.bool,
};

export default CustomDateRangePicker;
