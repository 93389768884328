import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';
import _omit from 'lodash/omit';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function getProductList(title, countryId, cityId, productTypeCode, enginecode, activityId) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/GetProductList`;
    const body = {
      "title": title,
      "countryId": countryId,
      "cityId": cityId,
      "productTypeCode": productTypeCode,
      "enginecode": enginecode,
      "activityId": activityId
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_LIST_REQUEST,
      body: body,
      payload: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_LIST_SUCCESS,
            payload: result.ProductInfoList
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_LIST_FAILURE,
  					payload: [],
            msg: `Get product list failed.`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_LIST_FAILURE,
					error,
					payload: [],
          msg: `Get product list failed. API issue.`
				})
			});
	};
}

export function getProductDetail(activityId) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/getProductDetail`;
    const body = {
      'activityId': activityId
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_DETAIL_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess && result.ProductInfoList.length > 0) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_DETAIL_SUCCESS,
            payload: result.ProductInfoList[0]
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_DETAIL_FAILURE,
  					payload: null,
            msg: `Get product failed.`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_DETAIL_FAILURE,
					error,
					payload: null,
          msg: `Get product failed. API issue.`
				})
			});
	};
}

export function updateProductDetail(object) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProduct/Update`;
    const body = object

		dispatch({
			type: ActionTypes.UPDATE_PRODUCT_DETAIL_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.UPDATE_PRODUCT_DETAIL_SUCCESS,
            payload: true,
            msg: `Update product detail success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.UPDATE_PRODUCT_DETAIL_FAILURE,
  					payload: false,
            msg: `Update product detail failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.UPDATE_PRODUCT_DETAIL_FAILURE,
					payload: false,
          msg: `Update product detail failed. API issue.`,
          error,
				})
			});
	};
}

export function deleteProduct(activityId, pmid) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProduct/Delete`;
    const body = {
    	"activityId": activityId,
    	"pmid": pmid
    }

		dispatch({
			type: ActionTypes.DELETE_PRODUCT_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.DELETE_PRODUCT_SUCCESS,
            payload: true,
            msg: `Delete product success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.DELETE_PRODUCT_FAILURE,
  					payload: false,
            msg: `Delete product failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.DELETE_PRODUCT_FAILURE,
					payload: false,
          msg: `Delete product failed. API issue.`,
          error,
				})
			});
	};
}

export function createProduct(object) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProduct/Create`;
    const body = object

		dispatch({
			type: ActionTypes.CREATE_PRODUCT_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.CREATE_PRODUCT_SUCCESS,
            payload: result.ActivityId,
            msg: `Create product success. Activity Id: ${result.ActivityId}`,
          })
        } else {
          dispatch({
  					type: ActionTypes.CREATE_PRODUCT_FAILURE,
  					payload: null,
            msg: `Create product failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.CREATE_PRODUCT_FAILURE,
					payload: null,
          msg: `Create product failed. API issue.`,
          error,
				})
			});
	};
}

export function getProductItemDetail(activityId, productId) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/GetProductItemList`;
    const body = {
      'activityId': activityId,
      'productId': productId
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_ITEM_DETAIL_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess && result.ProductInfoList.length > 0) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_ITEM_DETAIL_SUCCESS,
            payload: result.ProductInfoList[0]
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_ITEM_DETAIL_FAILURE,
  					payload: null,
            msg: `Get product item detail failed.`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_ITEM_DETAIL_FAILURE,
					error,
					payload: null,
          msg: `Get product item detail failed. API issue.`
				})
			});
	};
}

export function updateItem(object) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProductItem/Update`;
    const body = _omit(object, ['skulist', 'extraInfoList'])

		dispatch({
			type: ActionTypes.UPDATE_ITEM_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.UPDATE_ITEM_SUCCESS,
            payload: true,
            msg: `Update item success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.UPDATE_ITEM_FAILURE,
  					payload: false,
            msg: `Update item failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.UPDATE_ITEM_FAILURE,
					payload: false,
          msg: `Update item failed. API issue.`,
          error,
				})
			});
	};
}

export function deleteItem(productId, activityId) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProductItem/Delete`;
    const body = {
      "productId": productId,
      "activityId": activityId,
    }

		dispatch({
			type: ActionTypes.DELETE_ITEM_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.DELETE_ITEM_SUCCESS,
            payload: true,
            msg: `Delete item success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.DELETE_ITEM_FAILURE,
  					payload: false,
            msg: `Delete item failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.DELETE_ITEM_FAILURE,
					payload: false,
          msg: `Delete item failed. API issue.`,
          error,
				})
			});
	};
}

export function createItem(object) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProductItem/Create`;
    const body = object

		dispatch({
			type: ActionTypes.CREATE_ITEM_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.CREATE_ITEM_SUCCESS,
            payload: result.productId,
            msg: `Create item success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.CREATE_ITEM_FAILURE,
  					payload: null,
            msg: `Create item failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.CREATE_ITEM_FAILURE,
					payload: null,
          msg: `Create item failed. API issue.`,
          error,
				})
			});
	};
}

export function getDailyRates(activityId, product_id, year, month) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/GetDailyRates `;
    const body = {
    	"activityId": activityId,
    	"product_id": product_id,
    	"sku_id": "",
    	"startdate": `${year}-${moment().month(month).format('MM')}-01`,
    	"enddate": `${year}-${moment().month(month).format('MM')}-${moment(`"${year}-${moment().month(month).format('MM')}"`, "YYYY-MM").daysInMonth()}`,
    }


		dispatch({
			type: ActionTypes.GET_DAILY_RATES_REQUEST,
      body: body
		})
    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_DAILY_RATES_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_DAILY_RATES_FAILURE,
  					payload: null,
            msg: `Get price rates failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_DAILY_RATES_FAILURE,
					error,
					payload: null,
          msg: `Get price rates failed. API issue.`
				})
			});
	};
}

export function updateDailyRates(array, amendmentNotes) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageDailyRates/Update`;
    const body = {
      "DailyList": array,
      "amendmentNotes": amendmentNotes
    }

		dispatch({
			type: ActionTypes.UPDATE_DAILY_RATE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.UPDATE_DAILY_RATE_SUCCESS,
            payload: true,
            msg: 'Update daily rate success.'
          })
        } else {
          dispatch({
  					type: ActionTypes.UPDATE_DAILY_RATE_FAILURE,
  					payload: false,
            msg: `Update daily rate failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.UPDATE_DAILY_RATE_FAILURE,
					payload: false,
          msg: `Update daily rate failed. API issue.`,
          error,
				})
			});
	};
}

export function getItemSKU(productId) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/GetSKU`;
    const body = {
      'product_id': productId
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_SKU_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_SKU_SUCCESS,
            payload: result.skulist
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_SKU_FAILURE,
  					payload: null,
            msg: `Get product sku failed.`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_SKU_FAILURE,
					error,
					payload: null,
          msg: `Get product sku failed. API issue.`
				})
			});
	};
}

export function updateSKU(array, amendmentNotes) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageSKU/Update`;
    const body = {
    	"skulist": array,
      "amendmentNotes": amendmentNotes
    }

		dispatch({
			type: ActionTypes.UPDATE_SKU_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.UPDATE_SKU_SUCCESS,
            payload: true,
            msg: 'Update SKU success.'
          })
        } else {
          dispatch({
  					type: ActionTypes.UPDATE_SKU_FAILURE,
  					payload: false,
            msg: `Update SKU failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.UPDATE_SKU_FAILURE,
					payload: false,
          msg: `Update SKU failed. API issue.`,
          error,
				})
			});
	};
}

export function deleteSKU(product_id, sku_id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageSKU/Delete`;
    const body = {
    	"skulist": [
        {
  		    "product_id": product_id,
  		    "sku_id": sku_id
    		}
    	]
    }

		dispatch({
			type: ActionTypes.DELETE_SKU_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.DELETE_SKU_SUCCESS,
            payload: true,
            msg: 'Delete SKU success.'
          })
        } else {
          dispatch({
  					type: ActionTypes.DELETE_SKU_FAILURE,
  					payload: false,
            msg: `Delete SKU failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.DELETE_SKU_FAILURE,
					payload: false,
          msg: `Delete SKU failed. API issue.`,
          error,
				})
			});
	};
}

export function createSKU(object, amendmentNotes) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageSKU/Create`;
    const body = {
    	"skulist": [
        object
    	],
      "amendmentNotes": amendmentNotes
    }

		dispatch({
			type: ActionTypes.CREATE_SKU_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.CREATE_SKU_SUCCESS,
            payload: true,
            msg: 'Create SKU success.'
          })
        } else {
          dispatch({
  					type: ActionTypes.CREATE_SKU_FAILURE,
  					payload: false,
            msg: `Create SKU failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.CREATE_SKU_FAILURE,
					payload: false,
          msg: `Create SKU failed. API issue.`,
          error,
				})
			});
	};
}

export function updateExtraInfo(array) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProductExtraInfoList/update`;
    const body = array

		dispatch({
			type: ActionTypes.UPDATE_EXTRA_INFO_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.UPDATE_EXTRA_INFO_SUCCESS,
            payload: true,
            msg: 'Update extra info success.'
          })
        } else {
          dispatch({
  					type: ActionTypes.UPDATE_EXTRA_INFO_FAILURE,
  					payload: false,
            msg: `Update extra info failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.UPDATE_EXTRA_INFO_FAILURE,
					payload: false,
          msg: `Update extra info failed. API issue.`,
          error,
				})
			});
	};
}

export function deleteExtraInfo(product_id, infoId) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProductExtraInfoList/delete`;
    const body = [{
      "pid": product_id,
      "infoId": infoId
    }]

		dispatch({
			type: ActionTypes.DELETE_EXTRA_INFO_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.DELETE_EXTRA_INFO_SUCCESS,
            payload: true,
            msg: `Delete extra info success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.DELETE_EXTRA_INFO_FAILURE,
  					payload: false,
            msg: `Delete extra info failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.DELETE_EXTRA_INFO_FAILURE,
					payload: false,
          msg: `Delete extra info failed. API issue.`,
          error,
				})
			});
	};
}

export function createExtraInfo(object) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProductExtraInfo/Create`;
    const body = object

		dispatch({
			type: ActionTypes.CREATE_EXTRA_INFO_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.CREATE_EXTRA_INFO_SUCCESS,
            payload: true,
            msg: `Create extra info success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.CREATE_EXTRA_INFO_FAILURE,
  					payload: false,
            msg: `Create extra info failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.CREATE_EXTRA_INFO_FAILURE,
					payload: false,
          msg: `Create extra info failed. API issue.`,
          error,
				})
			});
	};
}

export function getProductImages(activityId) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProduct/GetImages`;
    const body = {
      "activity_Id": activityId,
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_IMAGES_REQUEST,
      body: {activityId: activityId}
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_IMAGES_SUCCESS,
            payload: result.images,
            msg: `Get product images success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_IMAGES_FAILURE,
  					payload: false,
            msg: `Get product images failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_IMAGES_FAILURE,
					payload: false,
          msg: `Get product images failed. API issue.`,
          error,
				})
			});
	};
}

export function uploadImage(activityId, base64) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProduct/Images/Create`;
    const body = {
      "activity_Id": activityId,
      "url": base64,
  }

		dispatch({
			type: ActionTypes.UPLOAD_PRODUCT_IMAGE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.UPLOAD_PRODUCT_IMAGE_SUCCESS,
            payload: true,
            msg: `Upload image success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.UPLOAD_PRODUCT_IMAGE_FAILURE,
  					payload: false,
            msg: `Upload image failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.UPLOAD_PRODUCT_IMAGE_FAILURE,
					payload: false,
          msg: `Upload image failed. API issue.`,
          error,
				})
			});
	};
}

export function deleteImage(activityId, id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageProduct/Images/Delete`;
    const body = {
      "activity_Id": activityId,
      "id": id,
    }

		dispatch({
			type: ActionTypes.DELETE_PRODUCT_IMAGE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.DELETE_PRODUCT_IMAGE_SUCCESS,
            payload: id,
            msg: `Delete image success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.DELETE_PRODUCT_IMAGE_FAILURE,
  					payload: false,
            msg: `Delete image failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.DELETE_PRODUCT_IMAGE_FAILURE,
					payload: false,
          msg: `Delete image failed. API issue.`,
          error,
				})
			});
	};
}

export function clearProductDetail() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_PRODUCT_DETAIL,
		})
	};
}

export function getProductMarkup(activityId) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/MarkupBySku/Get`;
    const body = {
      "activityId": activityId
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_MARKUP_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess && result.markuplist) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_MARKUP_SUCCESS,
            payload: result.markuplist
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_MARKUP_FAILURE,
  					payload: null,
            msg: `Get product markup failed.`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_MARKUP_FAILURE,
					error,
					payload: null,
          msg: `Get product markup failed. API issue.`
				})
			});
	};
}

export function deleteProductMarkup(object) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/MarkupBySku/manage/delete`;
    const body = [object]

		dispatch({
			type: ActionTypes.DELETE_PRODUCT_MARKUP_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.DELETE_PRODUCT_MARKUP_SUCCESS,
            payload: result.responseSuccess,
            msg: `Delete product markup success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.DELETE_PRODUCT_MARKUP_FAILURE,
  					payload: result.responseSuccess,
            msg: `Delete product markup failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.DELETE_PRODUCT_MARKUP_FAILURE,
					error,
					payload: false,
          msg: `Delete product markup failed. API issue.`
				})
			});
	};
}

export function updateProductMarkup(array) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/MarkupBySku/manage/Update`;
    const body = array

		dispatch({
			type: ActionTypes.UPDATE_PRODUCT_MARKUP_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.UPDATE_PRODUCT_MARKUP_SUCCESS,
            payload: result.responseSuccess,
            msg: `Update product markup success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.UPDATE_PRODUCT_MARKUP_FAILURE,
  					payload: result.responseSuccess,
            msg: `Update product markup failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.UPDATE_PRODUCT_MARKUP_FAILURE,
					error,
					payload: false,
          msg: `Update product markup failed. API issue.`
				})
			});
	};
}

export function createProductMarkup(object) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/MarkupBySku/manage/create`;
    const body = [object]

		dispatch({
			type: ActionTypes.CREATE_PRODUCT_MARKUP_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.CREATE_PRODUCT_MARKUP_SUCCESS,
            payload: result.responseSuccess,
            msg: `Create product markup success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.CREATE_PRODUCT_MARKUP_FAILURE,
  					payload: result.responseSuccess,
            msg: `Create product markup failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.CREATE_PRODUCT_MARKUP_FAILURE,
					error,
					payload: false,
          msg: `Create product markup failed. API issue.`
				})
			});
	};
}

export function viewAllProductMarkup(activityId) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/MarkupBySku/View`;
    const body = {}

		dispatch({
			type: ActionTypes.VIEW_ALL_PRODUCT_MARKUP_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.VIEW_ALL_PRODUCT_MARKUP_SUCCESS,
            payload: result.viewlist
          })
        } else {
          dispatch({
  					type: ActionTypes.VIEW_ALL_PRODUCT_MARKUP_FAILURE,
  					payload: null,
            msg: `Get product markup failed.`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.VIEW_ALL_PRODUCT_MARKUP_FAILURE,
					error,
					payload: null,
          msg: `Get product markup failed. API issue.`
				})
			});
	};
}

export function clearSearchProductCriteria() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_SEARCH_PRODUCT_CRITERIA,
		})
	};
}
