import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';

const initialState = {
	msg: null,
	isError: false,
	timestamp: moment().valueOf()
};

const msg = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.UPDATE_PRODUCT_DETAIL_SUCCESS:
	case ActionTypes.CREATE_PRODUCT_SUCCESS:
	case ActionTypes.DELETE_PRODUCT_SUCCESS:
	case ActionTypes.UPDATE_DAILY_RATE_SUCCESS:
	case ActionTypes.UPDATE_SKU_SUCCESS:
	case ActionTypes.DELETE_SKU_SUCCESS:
	case ActionTypes.CREATE_SKU_SUCCESS:
	case ActionTypes.UPDATE_ITEM_SUCCESS:
	case ActionTypes.DELETE_ITEM_SUCCESS:
	case ActionTypes.CREATE_ITEM_SUCCESS:
	case ActionTypes.UPDATE_BOOKING_DETAIL_SUCCESS:
	case ActionTypes.UPDATE_EXTRA_INFO_SUCCESS:
	case ActionTypes.DELETE_EXTRA_INFO_SUCCESS:
	case ActionTypes.CREATE_EXTRA_INFO_SUCCESS:
	case ActionTypes.ADD_BOOKING_SKU_SUCCESS:
	case ActionTypes.UPDATE_BOOKING_SKU_SUCCESS:
	case ActionTypes.ADD_BOOKING_TRAVELER_INFO_SUCCESS:
	case ActionTypes.DELETE_BOOKING_TRAVELER_INFO_SUCCESS:
	case ActionTypes.ADD_BOOKING_HISTORY_SUCCESS:
	case ActionTypes.PROCESS_TO_MOE_SUCCESS:
	case ActionTypes.MARK_FULFILL_SUCCESS:
	case ActionTypes.DELETE_PRODUCT_IMAGE_SUCCESS:
	case ActionTypes.DELETE_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.CREATE_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.DELETE_PRODUCT_MARKUP_SUCCESS:
	case ActionTypes.UPDATE_PRODUCT_MARKUP_SUCCESS:
	case ActionTypes.CREATE_PRODUCT_MARKUP_SUCCESS:
		return {
			msg: action.msg,
			isError: false,
			timestamp: moment().valueOf()
		};
	case ActionTypes.UPDATE_PRODUCT_DETAIL_FAILURE:
	case ActionTypes.GET_PRODUCT_LIST_FAILURE:
	case ActionTypes.GET_PRODUCT_DETAIL_FAILURE:
	case ActionTypes.GET_PRODUCT_ITEM_LIST_FAILURE:
	case ActionTypes.GET_PRODUCT_ITEM_DETAIL_FAILURE:
	case ActionTypes.GET_PRODUCT_SKU_FAILURE:
	case ActionTypes.GET_DAILY_RATES_FAILURE:
	case ActionTypes.UPDATE_DAILY_RATE_FAILURE:
	case ActionTypes.UPDATE_SKU_FAILURE:
	case ActionTypes.DELETE_SKU_FAILURE:
	case ActionTypes.CREATE_SKU_FAILURE:
	case ActionTypes.DELETE_PRODUCT_FAILURE:
	case ActionTypes.UPDATE_ITEM_FAILURE:
	case ActionTypes.DELETE_ITEM_FAILURE:
	case ActionTypes.CREATE_ITEM_FAILURE:
	case ActionTypes.GET_BOOKING_LIST_FAILURE:
	case ActionTypes.UPDATE_BOOKING_DETAIL_FAILURE:
	case ActionTypes.CREATE_PRODUCT_FAILURE:
	case ActionTypes.UPDATE_EXTRA_INFO_FAILURE:
	case ActionTypes.ADD_BOOKING_SKU_FAILURE:
	case ActionTypes.UPDATE_BOOKING_SKU_FAILURE:
	case ActionTypes.GET_BOOKING_HISTORY_FAILURE:
	case ActionTypes.ADD_BOOKING_HISTORY_FAILURE:
	case ActionTypes.GET_BOOKING_DETAIL_FAILURE:
	case ActionTypes.GET_BOOKING_SKU_FAILURE:
	case ActionTypes.GET_BOOKING_EXTRA_INFO_FAILURE:
	case ActionTypes.DELETE_BOOKING_TRAVELER_INFO_FAILURE:
	case ActionTypes.ADD_BOOKING_TRAVELER_INFO_FAILURE:
	case ActionTypes.PROCESS_TO_MOE_FAILURE:
	case ActionTypes.MARK_FULFILL_FAILURE:
	case ActionTypes.DELETE_PRODUCT_IMAGE_FAILURE:
	case ActionTypes.GET_PRODUCT_IMAGES_FAILURE:
	case ActionTypes.GET_BOOKING_MESSAGE_FAILURE:
	case ActionTypes.DELETE_BOOKING_MESSAGE_FAILURE:
	case ActionTypes.CREATE_BOOKING_MESSAGE_FAILURE:
	case ActionTypes.GET_DETAIL_BY_TOKEN_FAILURE:
	case ActionTypes.GET_PRODUCT_MARKUP_FAILURE:
	case ActionTypes.DELETE_PRODUCT_MARKUP_FAILURE :
	case ActionTypes.UPDATE_PRODUCT_MARKUP_FAILURE :
	case ActionTypes.CREATE_PRODUCT_MARKUP_FAILURE :
	case ActionTypes.VIEW_ALL_PRODUCT_MARKUP_FAILURE :
		return {
			msg: action.msg,
			isError: true,
			timestamp: moment().valueOf()
		};

	default:
		return state;
	}
};

export default msg;
