import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

class MarkdownEditor extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this)
		this.setSelectedTab = this.setSelectedTab.bind(this)

		this.state = {
			selectedTab: 'write'
    };
	}

	componentDidMount() {
	}

  onChange(value) {
		this.props.handleSetValue(this.props.name, value)
  }

	setSelectedTab(tab) {
		this.setState({
			selectedTab: tab
		})
	}

  render() {
		return (
			<div className="relative">
				<ReactMde
					value={this.props.value}
					onChange={this.onChange}
	        selectedTab={this.state.selectedTab}
					onTabChange={this.setSelectedTab}
					generateMarkdownPreview={markdown =>
						Promise.resolve(converter.makeHtml(markdown))
					}
          readOnly={this.props.disabled}
          classes={{textArea: this.props.disabled ? 'disabled' : ''}}
				/>
			</div>
    );
  }
}

MarkdownEditor.propTypes = {
	handleSetValue: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.string,
	disabled: PropTypes.bool,
};

export default MarkdownEditor;
