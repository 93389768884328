import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button, Form, ListGroup, Tab} from 'react-bootstrap';
import MarkdownEditor from '../../../components/MarkdownEditor';
import SKU from '../../../components/productDetailPage/ItemTab/SKU';
import ExtraInfo from '../../../components/productDetailPage/ItemTab/ExtraInfo';
import DeleteModal from '../../../components/productDetailPage/DeleteModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import BtnWithAmendmentNotes from '../../../components/BtnWithAmendmentNotes';

import _extend from 'lodash/extend';

class ItemDetail extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onMarkdownChange = this.onMarkdownChange.bind(this);
		this.onSaveItemClick = this.onSaveItemClick.bind(this);
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);

		this.state = {
			form: null,
			isAddSkuModleOpen: false,
			isDeleteModalOpen: false,
		};
	}

	componentDidMount() {
		this.setForm()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.item !== this.props.item ) {
			this.setForm()
		}
		if (!prevProps.shouldeGetProductDetail && this.props.shouldeGetProductDetail) {
			this.toggleDeleteModal(false)
		}
	}

	setForm() {
		this.setState({
			form: this.props.item ? this.props.item.toJS() : null
		})
	}

	onFieldChange(e) {
		this.setState({
			form: _extend(this.state.form, {
				[e.target.name]: e.target.value
			})
		})
	}

  onMarkdownChange(name, value) {
    this.setState({
      form: _extend(this.state.form, {
        [name]: value
      })
    })
  }

  onSaveItemClick(amendmentNotes) {
		this.props.handleUpdateItem(Object.assign({'amendmentNotes': amendmentNotes}, this.state.form)) // include amendmentNotes
  }

	toggleDeleteModal(bool) {
		this.setState({
			isDeleteModalOpen: bool
		})
	}

  render() {
		const skuList = this.props.item.get('skulist').toJS()
		return (
			<div>
				{this.state.form &&
					<div>
						<Tab.Container defaultActiveKey="item-detail">
							<ListGroup className="list-group-horizontal text-center item-nav mb-3">
								<ListGroup.Item action eventKey="item-detail">Item Detail</ListGroup.Item>
							  <ListGroup.Item action eventKey="sku">SKU</ListGroup.Item>
							  <ListGroup.Item action eventKey="extra-info">Extra Info</ListGroup.Item>
							</ListGroup>

							<Tab.Content>
								<Tab.Pane eventKey="item-detail">
									<Form.Group as={Row}>
										<Form.Label column lg="3" className="text-right">Product Id</Form.Label>
										<Col className="pt-2 text-muted">{this.state.form.productId}</Col>
										<Col lg="auto"><Button variant="outline-danger" onClick={() => this.toggleDeleteModal(true)}><FontAwesomeIcon icon={faTrashAlt} className="mr-2" />Delete Item</Button></Col>
									</Form.Group>

									<Form.Group as={Row} controlId={`title-${this.state.form.productId}`}>
										<Form.Label column lg="3" className="text-right">Item Title *</Form.Label>
										<Col>
											<Form.Control
												type="text"
												autoComplete="off"
												name={`title`}
												onChange={(e) => this.onFieldChange(e)}
												value={this.state.form.title} />
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId={`detail-${this.state.form.productId}`}>
										<Form.Label column lg="3" className="text-right">Detail</Form.Label>
										<Col>
											<MarkdownEditor
												name="detail"
												handleSetValue={this.onMarkdownChange}
												value={this.state.form.detail || ''} />
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId={`voucherUsage-${this.state.form.productId}`}>
										<Form.Label column lg="3" className="text-right">Voucher Usage</Form.Label>
										<Col>
											<MarkdownEditor
												name="voucherUsage"
												handleSetValue={this.onMarkdownChange}
												value={this.state.form.voucherUsage || ''} />
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId={`voucherTypeDesc-${this.state.form.productId}`}>
										<Form.Label column lg="3" className="text-right">Voucher Type Description</Form.Label>
										<Col>
											<MarkdownEditor
												name="voucherTypeDesc"
												handleSetValue={this.onMarkdownChange}
												value={this.state.form.voucherTypeDesc || ''} />
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId={`confirmationType-${this.state.form.productId}`}>
										<Form.Label column lg="3" className="text-right">Confirmation Type</Form.Label>
										<Col>
											<Form.Control
												type="text"
												autoComplete="off"
												name="confirmationType"
												onChange={(e) => this.onFieldChange(e)}
												value={this.state.form.confirmationType || ''} />
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId={`confirmationDetails-${this.state.form.productId}`}>
										<Form.Label column lg="3" className="text-right">Confirmation Details</Form.Label>
										<Col>
											<MarkdownEditor
												name="confirmationDetails"
												handleSetValue={this.onMarkdownChange}
												value={this.state.form.confirmationDetails || ''} />
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId={`productMinPax-${this.state.form.productId}`}>
										<Form.Label column lg="3" className="text-right">Min Pax</Form.Label>
										<Col>
											<Form.Control
												type="text"
												autoComplete="off"
												name={`productMinPax`}
												onChange={(e) => this.onFieldChange(e)}
												value={this.state.form.productMinPax || 0} />
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId={`productMaxPax-${this.state.form.productId}`}>
										<Form.Label column lg="3" className="text-right">Max Pax</Form.Label>
										<Col>
											<Form.Control
												type="text"
												autoComplete="off"
												name={`productMaxPax`}
												onChange={(e) => this.onFieldChange(e)}
												value={this.state.form.productMaxPax || 0} />
										</Col>
									</Form.Group>

									<BtnWithAmendmentNotes
										text="Save Item Change"
										isSticky
										handleClcik={this.onSaveItemClick}
									/>

								</Tab.Pane>
								<Tab.Pane eventKey="sku">
									<SKU
										skuList={skuList}
										productId={this.props.item.get('productId')}
										activityId={this.props.item.get('activityId')}
										itemTitle={this.state.form.title}
										handleDelete={this.props.handleDeleteSKU}
										handleUpdate={this.props.handleUpdateSKU}
										handleCreate={this.props.handleCreateSKU}
										updateItemSuccessTimStamp={this.props.updateItemSuccessTimStamp}
										isComponentLoading={this.props.isComponentLoading}
										productMarkups={this.props.productMarkups}
										handleEditMarkup={this.props.handleEditMarkup}
									/>
								</Tab.Pane>
								<Tab.Pane eventKey="extra-info">
									<ExtraInfo
										extraInfoList={this.props.item.get('extraInfoList').toJS()}
										productId={this.props.item.get('productId')}
										itemTitle={this.state.form.title}
										handleDelete={this.props.handleDeleteExtraInfo}
										handleUpdate={this.props.handleUpdateExtraInfo}
										handleCreate={this.props.handleCreateExtraInfo}
										updateItemSuccessTimStamp={this.props.updateItemSuccessTimStamp}
									/>
								</Tab.Pane>
							</Tab.Content>

						</Tab.Container>
					</div>
				}
				<DeleteModal
					show={this.state.isDeleteModalOpen}
					handleConfirm={() => this.props.handleDeleteItem(this.props.item.get('productId'), this.props.item.get('activityId'))}
					handleClose={this.toggleDeleteModal}
					description={`Item: ${this.props.item.get('title')}`}
				/>
			</div>
    );
  }
}

ItemDetail.propTypes = {
	item: PropTypes.object,
	handleUpdateSKU: PropTypes.func,
	handleDeleteSKU: PropTypes.func,
	handleCreateSKU: PropTypes.func,
	isComponentLoading: PropTypes.bool,
	updateItemSuccessTimStamp: PropTypes.number,
	handleUpdateItem: PropTypes.func,
	handleDeleteItem: PropTypes.func,
	shouldeGetProductDetail: PropTypes.bool,
	handleUpdateExtraInfo: PropTypes.func,
	handleDeleteExtraInfo: PropTypes.func,
	handleCreateExtraInfo: PropTypes.func,
	productMarkups: PropTypes.array,
	handleEditMarkup: PropTypes.func,
}

export default ItemDetail;
