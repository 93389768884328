import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import Auth from '../Auth/Auth';
import history from '../history';

import '../style/style.scss';

import MainMenu from './MainMenu';
import Home from './Home';
import ProductListPage from './ProductListPage';
import CreateProductPage from './CreateProductPage';
import ProductDetailPage from './ProductDetailPage';
import PricingPage from './PricingPage';
import BookingListPage from './BookingListPage';
import BookingDetailPage from './BookingDetailPage';
import UnreadBookingMsgPage from './UnreadBookingMsgPage';
import MarkUpPage from './MarkUpPage';
import Loading from '../components/Loading';
import PopupMsg from '../components/PopupMsg';

import store from '../store'; // Import the store

const auth = new Auth();

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.goToProduct = this.goToProduct.bind(this);

    this.state = {
      isLogged: false,
    };
  }

  componentDidMount() {
    if (auth.isAuthenticated()) {
      auth.renewSession(this.callback);
    } else {
      auth.login(this.callback);
    }
  }

  callback = () => {
    this.setState({
      isLogged: true
    })
  }

  goToProduct(id) {
    history.push(`/activity/${id}`)
  }

  render() {
    const Callback = () => (
      <div className="text-center mt-5">
        Loading...
      </div>
    )

    const NoMatch = () => (
      <div className="text-center mt-5">
        NoMatch
      </div>
    )
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="app body-container">
            {this.state.isLogged ?
              <div>
                <main>
                  {!history.location.pathname.includes('pricing') &&
                    <MainMenu
                      handleProductClick={this.goToProduct}
                      currentPage={history.location.pathname}
                    />
                  }
                  <Switch>
                    <Route
                      path="/callback"
                      render={(props) => {
                        handleAuthentication(props);
                        return <Callback {...props} auth={auth}
                        />
                      }} />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      render={(props) => <Home {...props} />}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/product/:id`}
                      render={(props) => <ProductDetailPage {...props} />}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/create-product`}
                      render={(props) => <CreateProductPage {...props} />}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/product-list`}
                      render={(props) => <ProductListPage {...props} />}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/booking-list`}
                      render={(props) => <BookingListPage {...props} />}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/unread-message-list`}
                      render={(props) => <UnreadBookingMsgPage {...props} />}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/booking/:id`}
                      render={(props) => <BookingDetailPage {...props} />}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/pricing/:id`}
                      render={(props) => <PricingPage {...props} />}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/markups`}
                      render={(props) => <MarkUpPage {...props} />}
                    />
                    <Route component={NoMatch} />
                  </Switch>
                </main>
              </div> :
              <Loading />
            }
          </div>
          <PopupMsg />
        </Router>
      </Provider>
    );

  }
}

export default App;
