import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

const DeleteModal = (props) => {
	return (
		<Modal centered show={props.show} onHide={() => props.handleClose(false)} >
			<Modal.Header>
				<Modal.Title>Are you Sure?</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="text-center">
					Sure to delete <b>{props.description}</b> ?
				</div>
				<div className="text-center pt-3 px-3">
					<Alert variant="warning">
				    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />You cannot get it back after deleting.
				  </Alert>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="danger" onClick={props.handleConfirm} size="lg" className="btn-block" >
					Confirm Delete
				</Button>
			</Modal.Footer>
		</Modal>
  );
}

DeleteModal.propTypes = {
	handleClose: PropTypes.func,
	handleConfirm: PropTypes.func,
	show: PropTypes.bool,
	description: PropTypes.string,
}

export default DeleteModal;
