import * as ActionTypes from '../constants/ActionTypes';
import Immutable from 'immutable';
import moment from 'moment';

const initialState = {
	productList: null,
	searchProductCriteria: null,
	productDetail: null,
	itemDetail: null,
	dailyRates: null,
	updateDailyRateSuccessTimStamp: null,
	updateItemSuccessTimStamp: null,
	shouldeGetProductDetail: false,
	isDeleteProductSuccess: false,
	newAddedProductId: null,
	newAddedActivityId: null,
	deleteImageSuccessTimeStamp: null,
	deletedImageId: null,
	productImages: null,
	shouldRefreshImages: false,
	productMarkups: null,
	allProductMarkups: null,
	updateMarkupSuccessTimStamp: null,
	itemSKU: null,
};

const product = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_PRODUCT_LIST_REQUEST:
		return Object.assign({}, state, {
			searchProductCriteria: action.payload,
		});
	case ActionTypes.CLEAR_SEARCH_PRODUCT_CRITERIA:
		return Object.assign({}, state, {
			searchProductCriteria: null,
			productList: null,
		});
	case ActionTypes.GET_PRODUCT_LIST_SUCCESS:
	case ActionTypes.GET_PRODUCT_LIST_FAILURE:
		return Object.assign({}, state, {
			productList: action.payload.reverse(),
		});
	case ActionTypes.GET_PRODUCT_DETAIL_SUCCESS:
	case ActionTypes.GET_PRODUCT_DETAIL_FAILURE:
		return Object.assign({}, state, {
			productDetail: Immutable.fromJS(action.payload),
			shouldeGetProductDetail: false,
			isDeleteProductSuccess: false,
		});
	case ActionTypes.GET_PRODUCT_ITEM_DETAIL_SUCCESS:
	case ActionTypes.GET_PRODUCT_ITEM_DETAIL_FAILURE:
		return Object.assign({}, state, {
			itemDetail: Immutable.fromJS(action.payload),
		});
	case ActionTypes.GET_DAILY_RATES_SUCCESS:
	case ActionTypes.GET_DAILY_RATES_FAILURE:
		return Object.assign({}, state, {
			dailyRates: action.payload,
			updateDailyRateSuccessTimStamp: null,
		});
	case ActionTypes.UPDATE_DAILY_RATE_SUCCESS:
		return Object.assign({}, state, {
			updateDailyRateSuccessTimStamp: moment().valueOf(),
		});
	case ActionTypes.UPDATE_SKU_SUCCESS:
	case ActionTypes.DELETE_SKU_SUCCESS:
	case ActionTypes.CREATE_SKU_SUCCESS:
	case ActionTypes.UPDATE_EXTRA_INFO_SUCCESS:
	case ActionTypes.DELETE_EXTRA_INFO_SUCCESS:
	case ActionTypes.CREATE_EXTRA_INFO_SUCCESS:
		return Object.assign({}, state, {
			updateItemSuccessTimStamp: moment().valueOf(),
		});
	case ActionTypes.GET_PRODUCT_MARKUP_SUCCESS:
		return Object.assign({}, state, {
			productMarkups: action.payload,
		});
	case ActionTypes.VIEW_ALL_PRODUCT_MARKUP_SUCCESS:
		return Object.assign({}, state, {
			allProductMarkups: action.payload,
		});
	case ActionTypes.DELETE_PRODUCT_MARKUP_SUCCESS:
	case ActionTypes.UPDATE_PRODUCT_MARKUP_SUCCESS:
	case ActionTypes.CREATE_PRODUCT_MARKUP_SUCCESS:
		return Object.assign({}, state, {
			updateMarkupSuccessTimStamp: moment().valueOf(),
		});
	case ActionTypes.UPDATE_PRODUCT_DETAIL_SUCCESS:
	case ActionTypes.UPDATE_ITEM_SUCCESS:
	case ActionTypes.DELETE_ITEM_SUCCESS:
		return Object.assign({}, state, {
			shouldeGetProductDetail: true,
		});
	case ActionTypes.CREATE_ITEM_SUCCESS:
		return Object.assign({}, state, {
			shouldeGetProductDetail: true,
			newAddedProductId: action.payload,
		});
	case ActionTypes.CREATE_PRODUCT_SUCCESS:
		return Object.assign({}, state, {
			newAddedActivityId: action.payload,
		});
	case ActionTypes.DELETE_PRODUCT_SUCCESS:
		return Object.assign({}, state, {
			isDeleteProductSuccess: true,
		});
	case ActionTypes.GET_PRODUCT_IMAGES_SUCCESS:
	case ActionTypes.GET_PRODUCT_IMAGES_FAILURE:
		return Object.assign({}, state, {
			productImages: action.payload,
			shouldRefreshImages: false,
		});
	case ActionTypes.UPLOAD_PRODUCT_IMAGE_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshImages: true,
		});
	case ActionTypes.DELETE_PRODUCT_IMAGE_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshImages: true,
		});
	case ActionTypes.CLEAR_PRODUCT_DETAIL:
		return Object.assign({}, state, {
			productDetail: null,
			itemDetail: null,
			dailyRates: null,
			productImages: null,
			productMarkups: null,
			itemSKU: null,
		});
	case ActionTypes.GET_PRODUCT_SKU_SUCCESS:
		return Object.assign({}, state, {
			itemSKU: action.payload,
		});
	default:
		return state;
	}
};

export default product;
