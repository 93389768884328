import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function login(username, password) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Security/login`;
    const body = {
      "Email": username,
      "Password": password
    }

		dispatch({
			type: ActionTypes.LOGIN_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          auth.setSession(result.data);
          dispatch({
            type: ActionTypes.LOGIN_SUCCESS,
            payload: null
          })
        } else {
          dispatch({
  					type: ActionTypes.LOGIN_FAILURE,
  					payload: null,
            msg: `Login failed. Wrong email/password`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.LOGIN_FAILURE,
					error,
					payload: null,
          msg: `Login failed. API issue.`
				})
			});
	};
}

export function logout() {
  return (dispatch) => {
    auth.logout();

    dispatch({
      type: ActionTypes.LOG_OUT,
      payload: null
    })
  }
}

export function getDetails() {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/GetDetailsByToken`;
    const body = {}

		dispatch({
			type: ActionTypes.GET_DETAIL_BY_TOKEN_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result) {
          auth.setUserInfo(result);
          dispatch({
            type: ActionTypes.GET_DETAIL_BY_TOKEN_SUCCESS,
            payload: true
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_DETAIL_BY_TOKEN_FAILURE,
  					payload: null,
            msg: `Get details by token failed. ${result.message}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_DETAIL_BY_TOKEN_FAILURE,
					error,
					payload: null,
          msg: `Get details by token failed. ${error.message}`
				})
			});
	};
}
