import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Sticky from 'react-stickynode';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTrashAlt, faEllipsisV} from '@fortawesome/free-solid-svg-icons';
import {prettyDateTime} from '../util/transformDate';
import {Row, Col, Button, Form, Nav, Card, Tab, ListGroup, Dropdown} from 'react-bootstrap';
import {Breadcrumbs, BreadcrumbItem, IconMapPin, IconUsers, IconClock, IconTruck} from "sancho";
import ItemDetail from '../components/productDetailPage/ItemTab/ItemDetail';
import NewItem from '../components/productDetailPage/ItemTab/NewItem';
import DeleteModal from '../components/productDetailPage/DeleteModal';
import MarkdownEditor from '../components/MarkdownEditor';
import CountrySelect from '../components/CountrySelect';
import CitySelect from '../components/CitySelect';
import ProductTypeSelect from '../components/ProductTypeSelect';
import SupplierCodeSelect from '../components/SupplierCodeSelect';
import CurrencySelect from '../components/CurrencySelect';
import ImageControl from '../components/productDetailPage/ImageControl';
import Markups from '../components/productDetailPage/Markups';
import BtnWithAmendmentNotes from '../components/BtnWithAmendmentNotes';
import _extend from 'lodash/extend';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import _split from 'lodash/split';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';

import {
  getProductDetail,
  getItemSKU,
  updateSKU,
  deleteSKU,
  createSKU,
  getProductItemDetail,
  updateProductDetail,
  deleteProduct,
  updateItem,
  deleteItem,
  createItem,
  updateExtraInfo,
  deleteExtraInfo,
  createExtraInfo,
  getProductImages,
  uploadImage,
  deleteImage,
  clearProductDetail,
  getProductMarkup,
  deleteProductMarkup,
  updateProductMarkup,
  createProductMarkup,
} from '../actions/product';

class ProductDetailPage extends Component {
  constructor(props) {
    super(props);

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onMarkdownChange = this.onMarkdownChange.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.onDeleteImageClick = this.onDeleteImageClick.bind(this);
    this.onItemTabSelect = this.onItemTabSelect.bind(this);
    this.onTabSelect = this.onTabSelect.bind(this);
    this.onEditMarkup = this.onEditMarkup.bind(this);

    this.state = {
      form: null,
      currentProdId: null,
      isDeleteModalOpen: false,
      files: [],
      currentTab: 'detail',
      markupHighlightId: null,
    };
  }

  componentDidMount() {
    this.props.getProductDetail(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.clearProductDetail()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.productDetail && this.props.productDetail) {
      this.setForm()
      this.props.getProductImages(this.props.productDetail.get('activityId'))
      if (_get(this.props.location, ['state', 'product_id'])) {
        this.onTabSelect('items')
        this.onItemTabSelect(this.props.location.state.product_id)
      }
    }

    if (prevProps.itemDetail !== this.props.itemDetail) {
      if (!this.props.productMarkups) {
        this.props.getProductMarkup(this.props.match.params.id)
      }
    }

    if (!prevProps.shouldeGetProductDetail && this.props.shouldeGetProductDetail) {
      this.props.getProductDetail(this.props.match.params.id)
    }

    if (prevProps.updateMarkupSuccessTimStamp !== this.props.updateMarkupSuccessTimStamp) {
      this.props.getProductMarkup(this.props.productDetail.get('activityId'))
    }

    if (prevProps.updateItemSuccessTimStamp !== this.props.updateItemSuccessTimStamp) {
      this.props.getProductItemDetail(this.props.productDetail.get('activityId'), this.state.currentProdId)
    }

    if (prevProps.productDetail && this.props.productDetail) {
      if (prevProps.productDetail.get('itemList').size < this.props.productDetail.get('itemList').size) {
        // item added
        this.onItemTabSelect(this.props.newAddedProductId)
      }
      if (prevProps.productDetail.get('itemList').size > this.props.productDetail.get('itemList').size) {
        // item deleteed
        this.resetItemTab()
      }
    }

    if (!prevProps.isDeleteProductSuccess && this.props.isDeleteProductSuccess) {
      this.toggleDeleteModal(false)
      this.props.history.push(`/`)
    }

    if (!prevProps.shouldRefreshImages && this.props.shouldRefreshImages) {
      this.props.getProductImages(this.props.productDetail.get('activityId'))
    }

  }

  onFieldChange(e) {
    this.setState({
      form: _extend(this.state.form, {
        [e.target.name]: e.target.value
      })
    })
  }

  onSelectChange(name, value) {
    this.setState({
      form: _extend(this.state.form, {
        [name]: value
      })
    })
  }

  onMarkdownChange(name, value) {
    if (name.includes('-')) {
      const deep = _cloneDeep(this.state.form[_split(name, '-')[0]])
      _set(deep, [_split(name, '-')[1]], value)

      this.setState({
        form: _extend(this.state.form, {
          [_split(name, '-')[0]]: deep
        })
      })
    } else {
      this.setState({
        form: _extend(this.state.form, {
          [name]: value
        })
      })
    }
  }

  onSubmitClick(amendmentNotes) {
    this.props.updateProductDetail(Object.assign({'amendmentNotes': amendmentNotes}, this.state.form))  // include amendmentNotes
  }

  setForm() {
    this.setState({
      form: this.props.productDetail.toJS()
    })
  }

  resetItemTab() {
    let productId = this.props.productDetail.get('itemList').size > 0 ? this.props.productDetail.getIn(['itemList', 0, 'productId']) : 'add-new'

    this.onItemTabSelect(productId)
  }

  onItemTabSelect(productId) {
    this.setState({
      currentProdId: productId
    })

    if (productId !== 'add-new') {
      this.props.getProductItemDetail(this.props.productDetail.get('activityId'), productId)
    }
  }

  onTabSelect(selectedKey) {
    this.setState({
      currentTab: selectedKey
    })

    if (selectedKey === 'items' && !this.props.itemDetail) {
      this.resetItemTab()
    } else if (selectedKey === 'markups') {
      if (!this.props.productMarkups) {
        this.props.getProductMarkup(this.props.productDetail.get('activityId'))
      }
      this.setState({
        markupHighlightId: null
      })
    }
  }

  onEditMarkup(id) {
    this.onTabSelect('markups')
    this.setState({
      markupHighlightId: id
    })
  }

  toggleDeleteModal(bool) {
    this.setState({
      isDeleteModalOpen: bool
    })
  }

  setImage() {
    this.setState({
      files: this.props.productDetail.get('images').toJS()
    })
  }

  uploadFile(file){
    this.props.uploadImage(this.props.productDetail.get('activityId'), file.base64)
  }

  onDeleteImageClick(id) {
    this.props.deleteImage(this.props.productDetail.get('activityId'), id)
  }

  render () {
    const pDetail = this.props.productDetail
    return (
      <div>
        <title>Product Detail {pDetail && `#${pDetail.get('activityId')}`} | Product Management System</title>

          <div className="container">

            <Breadcrumbs className="mt-1 mb-3">
              <BreadcrumbItem>
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/product-list">Product List</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <div dangerouslySetInnerHTML={{
                  __html: pDetail ? `#${pDetail.get('activityId')} <b>${pDetail.get('title')}</b>` : 'N/A'
                }}></div>
              </BreadcrumbItem>
            </Breadcrumbs>

            <h1 className="text-center">Edit Product</h1>
            <h2 className="text-center">{pDetail && pDetail.get('title')}</h2>
            {pDetail && this.state.form &&
              <div>
                <Row className="my-4">
                  <Col lg="auto">Create on <b>{prettyDateTime(pDetail.get('createon'))}</b></Col>
                  <Col>Last update on <b>{prettyDateTime(pDetail.get('updateon'))}</b> by <b>{pDetail.get('updateby')}</b></Col>
                </Row>

                <Tab.Container activeKey={this.state.currentTab}>
                  <Card>
                    <Card.Header>
                      <Nav variant="tabs" onSelect={selectedKey => this.onTabSelect(selectedKey)}>
                        <Nav.Item>
                          <Nav.Link eventKey="detail">Detail</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="items">Item(s)</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="markups">Markups</Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Dropdown className="float-right-btn" alignRight>
                        <Dropdown.Toggle variant="icon">
                          <FontAwesomeIcon icon={faEllipsisV}/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Button variant="icon" onClick={() => this.toggleDeleteModal(true)} className="text-danger"><FontAwesomeIcon icon={faTrashAlt} className="mr-2" />Delete Product</Button>
                        </Dropdown.Menu>
                      </Dropdown>

                    </Card.Header>
                    <Card.Body>
                      <Tab.Content>
                        <Tab.Pane eventKey="detail">
                          <Form.Group as={Row} controlId="title">
                            <Form.Label column lg="2">Title *</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                name="title"
                                onChange={(e) => this.onFieldChange(e)}
                                value={this.state.form.title} />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="subtitle">
                            <Form.Label column lg="2">Subtitle</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                name="subtitle"
                                onChange={(e) => this.onFieldChange(e)}
                                value={this.state.form.subtitle} />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="producttypecode">
                            <Form.Label column lg="2">Product Type *</Form.Label>
                            <Col>
                              <ProductTypeSelect
                                handleSelect={this.onSelectChange}
                                selectedCode={this.state.form.producttypecode}
                                name="producttypecode"
                              />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="supplierCode">
                            <Form.Label column lg="2">Supplier Code *</Form.Label>
                            <Col>
                              <SupplierCodeSelect
                                handleSelect={this.onSelectChange}
                                selectedCode={this.state.form.enginecode}
                                name="enginecode"
                              />
                            </Col>
                          </Form.Group>


                          <Form.Group as={Row} controlId="country">
                            <Form.Label column lg="2">Country *</Form.Label>
                            <Col>
                              <CountrySelect
                                handleSelect={this.onSelectChange}
                                selectedCode={this.state.form.countryId}
                                name="countryId"
                              />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="cityId">
                            <Form.Label column lg="2"><IconMapPin /> City *</Form.Label>
                            <Col>
                              <CitySelect
                                handleSelect={this.onSelectChange}
                                countryId={this.state.form.countryId}
                                selectedCode={this.state.form.cityId}
                                name="cityId"
                              />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="travelersNotes">
                            <Form.Label column lg="2"><IconUsers /> Travelers Notes</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                name="travelersNotes"
                                onChange={(e) => this.onFieldChange(e)}
                                value={this.state.form.travelersNotes} />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="duration">
                            <Form.Label column lg="2"><IconClock /> Duration</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                name="duration"
                                onChange={(e) => this.onFieldChange(e)}
                                value={this.state.form.duration} />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="transport">
                            <Form.Label column lg="2"><IconTruck /> Transport</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                name="transport"
                                onChange={(e) => this.onFieldChange(e)}
                                value={this.state.form.transport} />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="originalamount">
                            <Form.Label column lg="2">Original Amount</Form.Label>
                            <Col lg="2">
                              <CurrencySelect
                                handleSelect={this.onSelectChange}
                                selectedCode={this.state.form.originalcurr}
                                name="originalcurr"
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                type="number"
                                autoComplete="off"
                                name="originalamount"
                                onChange={(e) => this.onFieldChange(e)}
                                value={this.state.form.originalamount} />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="termsAndConditions">
                            <Form.Label column lg="2">Terms And Conditions</Form.Label>
                            <Col>
                              <MarkdownEditor
                                name="termsAndConditions"
                                handleSetValue={this.onMarkdownChange}
                                value={this.state.form.termsAndConditions} />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="Guidelines">
                            <Form.Label column lg="2">Guidelines</Form.Label>
                            <Col>
                              {Object.keys(this.state.form.guidelinesObj).map(guideline =>
                                <div className="mt-2" key={guideline}>
                                  {_startCase(guideline)}
                                  <MarkdownEditor
                                    name={`guidelinesObj-${guideline}`}
                                    handleSetValue={this.onMarkdownChange}
                                    value={_get(this.state.form, ['guidelinesObj', guideline])} />
                                </div>
                              )}

                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="Usage">
                            <Form.Label column lg="2">Usage</Form.Label>
                            <Col>
                              {Object.keys(this.state.form.usageObj).map(usage =>
                                <div className="mt-2" key={usage}>
                                  {_startCase(usage)}
                                  <MarkdownEditor
                                    name={`usageObj-${usage}`}
                                    handleSetValue={this.onMarkdownChange}
                                    value={_get(this.state.form, ['usageObj', usage])} />
                                </div>
                              )}
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="Usage">
                            <Form.Label column lg="2">Images
                              <ul>
                                <li>File size <b>{`< 3MB`}</b></li>
                                <li>Suggested image size: <b>1230 x 400 px</b></li>
                              </ul>
                            </Form.Label>
                            <Col>
                              {this.props.productImages &&
                                <ImageControl
                                  productImages={this.props.productImages}
                                  handleUploadImage={this.props.uploadImage}
                                  handleDeleteImage={this.props.deleteImage}
                                  activityId={this.props.productDetail.get('activityId')}
                                  shouldRefreshImages={this.props.shouldRefreshImages}
                                  imageLoading={this.props.imageLoading}
                                />
                              }
                            </Col>
                          </Form.Group>

                          <BtnWithAmendmentNotes
                            text="Save Detail Change"
                            isSticky
                            handleClcik={this.onSubmitClick}
                          />

                        </Tab.Pane>
                        <Tab.Pane eventKey="items">
                          {this.state.currentProdId &&
                            <Row>
                              <Col lg="3" className="pr-0 border-right-grey">
                                <Sticky enabled top={-1}>
                                  <ListGroup activeKey={this.state.currentProdId} className="text-right vertical-tab-menu">
                                    {pDetail.get('itemList').map(item =>
                                      <ListGroup.Item  key={item.get('productId')} action eventKey={item.get('productId')} className="border-right-0" onClick={() => this.onItemTabSelect(item.get('productId'))} >
                                        {item.get('title')}
                                      </ListGroup.Item>
                                    )}
                                    <ListGroup.Item  action eventKey="add-new" className="border-right-0" onClick={() => this.onItemTabSelect('add-new')} >
                                      <FontAwesomeIcon icon={faPlus} className="mr-1" />Add New
                                    </ListGroup.Item>
                                  </ListGroup>
                                </Sticky>
                              </Col>
                              <Col className="pl-0">
                                <div className="px-3 py-2 items-container border-left-0">
                                  {this.state.currentProdId === 'add-new' &&
                                    <NewItem
                                      activityId={pDetail.get('activityId')}
                                      isComponentLoading={this.props.componentLoading}
                                      handleCreateItem={this.props.createItem}
                                    />
                                  }
                                  {this.state.currentProdId !== 'add-new' && this.props.itemDetail &&
                                    <ItemDetail
                                      item={this.props.itemDetail}
                                      handleUpdateSKU={this.props.updateSKU}
                                      handleDeleteSKU={this.props.deleteSKU}
                                      handleCreateSKU={this.props.createSKU}
                                      isComponentLoading={this.props.componentLoading}
                                      handleUpdateItem={this.props.updateItem}
                                      handleDeleteItem={this.props.deleteItem}
                                      shouldeGetProductDetail={this.props.shouldeGetProductDetail}
                                      handleUpdateExtraInfo={this.props.updateExtraInfo}
                                      handleDeleteExtraInfo={this.props.deleteExtraInfo}
                                      handleCreateExtraInfo={this.props.createExtraInfo}
                                      updateItemSuccessTimStamp={this.props.updateItemSuccessTimStamp}
                                      productMarkups={this.props.productMarkups}
                                      handleEditMarkup={this.onEditMarkup}
                                    />
                                  }
                                </div>
                              </Col>
                            </Row>
                          }
                        </Tab.Pane>
                        <Tab.Pane eventKey="markups">
                          {this.props.productMarkups &&
                            <Markups
                              productMarkups={this.props.productMarkups}
                              handleDelete={this.props.deleteProductMarkup}
                              handleUpdate={this.props.updateProductMarkup}
                              updateMarkupSuccessTimStamp={this.props.updateMarkupSuccessTimStamp}
                              itemList={this.props.productDetail.get('itemList')}
                              handleGetItemSKU={this.props.getItemSKU}
                              itemSKU={this.props.itemSKU}
                              handleCreateMarkup={this.props.createProductMarkup}
                              highlightId={this.state.markupHighlightId}
                            />
                          }
                        </Tab.Pane>
                      </Tab.Content>
                    </Card.Body>
                  </Card>
                </Tab.Container>

                <DeleteModal
                  show={this.state.isDeleteModalOpen}
                  handleConfirm={() => this.props.deleteProduct(pDetail.get('activityId'), pDetail.get('pmid'))}
                  handleClose={this.toggleDeleteModal}
                  description={`Product: ${pDetail.get('title')}`}
                />
              </div>
            }
          </div>
        {!pDetail && <div className="pt-30vh text-center">No Detail.</div>}
      </div>
    );
  }
}


ProductDetailPage.propTypes = {
	productDetail: PropTypes.object,
	componentLoading: PropTypes.bool,
	shouldeGetProductDetail: PropTypes.bool,
	isDeleteProductSuccess: PropTypes.bool,
	newAddedProductId: PropTypes.string,
	itemDetail: PropTypes.object,
	productImages: PropTypes.array,
	deletedImageId: PropTypes.string,
	shouldRefreshImages: PropTypes.bool,
	imageLoading: PropTypes.bool,
	productMarkups: PropTypes.array,
};

function mapStateToProps(state) {
	return {
    productDetail: state.product.productDetail,
    componentLoading: state.loading.componentLoading,
    shouldeGetProductDetail: state.product.shouldeGetProductDetail,
    isDeleteProductSuccess: state.product.isDeleteProductSuccess,
    newAddedProductId: state.product.newAddedProductId,
    itemDetail: state.product.itemDetail,
    updateItemSuccessTimStamp: state.product.updateItemSuccessTimStamp,
    updateMarkupSuccessTimStamp: state.product.updateMarkupSuccessTimStamp,
    productImages: state.product.productImages,
    deletedImageId: state.product.deletedImageId,
    shouldRefreshImages: state.product.shouldRefreshImages,
    imageLoading: state.loading.imageLoading,
    productMarkups: state.product.productMarkups,
    itemSKU: state.product.itemSKU,
	}
}

const mapDispatchToProps = {
  getProductDetail,
  getItemSKU,
  updateSKU,
  deleteSKU,
  createSKU,
  updateProductDetail,
  deleteProduct,
  getProductItemDetail,
  updateItem,
  deleteItem,
  createItem,
  updateExtraInfo,
  deleteExtraInfo,
  createExtraInfo,
  getProductImages,
  uploadImage,
  deleteImage,
  clearProductDetail,
  getProductMarkup,
  deleteProductMarkup,
  updateProductMarkup,
  createProductMarkup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);
