import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Row, Col, Form} from 'react-bootstrap';
import CurrencySelect from '../../components/CurrencySelect';
import SupplierCodeSelect from '../../components/SupplierCodeSelect';
import BtnWithAmendmentNotes from '../../components/BtnWithAmendmentNotes';
import cogoToast from 'cogo-toast';

class AddSkuModal extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);

		this.state = {
		    "engine": "",
		    "product_id": this.props.productID,
		    "title": "",
		    "detail": "",
		    "sku_min_pax": "0",
		    "sku_max_pax": "99",
		    "isrequired": false,
		    "lang": "en-US",
		    "retailCurrency": "HKD",
		    "retailAmount": '0',
		    "cutoffdate": 0
		};
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.show && this.props.show) {
			this.resetForm()
		}
	}

	resetForm() {
		this.setState({
			"engine": "",
			"product_id": this.props.productID ? this.props.productID : "",
			"title": "",
			"detail": "",
			"sku_min_pax": "0",
			"sku_max_pax": "99",
			"isrequired": false,
			"lang": "en-US",
			"retailCurrency": "HKD",
			"retailAmount": '0',
			"cutoffdate": 0
		})
	}

	onFieldChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onAddClick(amendmentNotes) {
		if (this.state.engine && this.state.title) {
			this.props.handleConfirm(this.state, amendmentNotes) // include amendmentNotes
		} else {
			cogoToast.error(<div><b>Title</b> and <b>Supplier Code</b> are needed.</div>, {hideAfter: 5})
		}
	}

	onSelectChange(name, value) {
		this.setState({
			[name]: value
		})
	}

	onKeyDown(event) {
    if (event.key === "Enter") {
			this.onAddClick()
    }
  };

  render() {
		return (
			<Modal centered show={this.props.show} onHide={() => this.props.handleClose(false)} onKeyDown={this.onKeyDown}>
				<Modal.Header>
					<Modal.Title>Add SKU</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Row className="mb-3">
							<Col lg="5">Item</Col>
							<Col>{this.props.itemTitle}</Col>
						</Row>
						<Form.Group as={Row} >
							<Form.Label column lg="5">Title*</Form.Label>
							<Col><Form.Control
							type="text"
							autoComplete="off"
							name="title"
							onChange={(e) => this.onFieldChange(e)}
							value={this.state.title} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Supplier Code*</Form.Label>
							<Col>
								<SupplierCodeSelect
									handleSelect={this.onSelectChange}
									selectedCode={this.state.engine}
									name="engine"
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Min Pax</Form.Label>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="sku_min_pax"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.sku_min_pax} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Max Pax</Form.Label>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="sku_max_pax"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.sku_max_pax} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Retail Currency</Form.Label>
							<Col>
								<CurrencySelect
									handleSelect={this.onSelectChange}
									selectedCode={this.state.retailCurrency}
									name="retailCurrency"
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Retail Amount</Form.Label>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="retailAmount"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.retailAmount} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Cut off date</Form.Label>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="cutoffdate"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.cutoffdate} /></Col>
						</Form.Group>

					</div>
				</Modal.Body>
				<Modal.Footer>
					<BtnWithAmendmentNotes
						text="Add Sku"
						handleClcik={this.onAddClick}
						isAddIcon
						isDisabled={!this.state.title}
					/>
				</Modal.Footer>
			</Modal>
	  );
	}
}

AddSkuModal.propTypes = {
	handleClose: PropTypes.func,
	handleConfirm: PropTypes.func,
	show: PropTypes.bool,
	productID: PropTypes.string,
	itemTitle: PropTypes.string,
}

export default AddSkuModal;
