import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Image, Modal, Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FileBase64 from 'react-file-base64';
import {faTimes, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import Loading from '../../components/Loading';
import _find from 'lodash/find';

class ImageControl extends Component {
	constructor(props) {
		super(props);
    this.uploadFile = this.uploadFile.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

		this.state = {
			isDeleteModalOpen: false,
			idToBeDelete: null,
			errorMsg: '',
		};
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {
		if (prevProps.shouldRefreshImages !== this.props.shouldRefreshImages) {
			this.toggleModal(false)
		}
	}

	toggleModal(bool) {
		if (!bool) {
			this.setState({
				isDeleteModalOpen: false,
				idToBeDelete: null,
			})
		} else {
			this.setState({
				isDeleteModalOpen: true
			})
		}
	}

	onDeleteClick(id) {
		this.setState({
			idToBeDelete: id
		})
		this.toggleModal(true)
	}

  uploadFile(file){
		if (file.file.size < 3000000) {
			this.props.handleUploadImage(this.props.activityId, file.base64)
			this.setState({
				errorMsg: ''
			})
		} else {
			this.setState({
				errorMsg: 'File size is larger than 3MB.'
			})
		}
  }


  render() {
		return (
			<div>
				{this.props.productImages.length && this.props.productImages[0].id !== '0' ?
					this.props.productImages.map((img, i) =>
						<div className="inline mr-3 mb-2 relative" key={i}>
							<Image src={img.url} rounded className="edit-product-image"/>
							<Button size="sm"  variant="light" onClick={() => this.onDeleteClick(img.id)} className="delete-image-btn rounded-circle"><FontAwesomeIcon icon={faTimes} /></Button>
						</div>
					) :
					<p className="mt-2">No Images.</p>
				}

				<div className="red">{this.state.errorMsg}</div>

				<div className="mt-2">
					<FileBase64
						onDone={this.uploadFile}
					/>
				</div>

				{this.props.imageLoading && <Loading isAbsolute />}

				<Modal centered show={this.state.isDeleteModalOpen} onHide={() => this.toggleModal(false)} >
					<Modal.Header>
						<Modal.Title>Are you Sure?</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="text-center">
							Sure to delete this image ?
							<div>
								{this.state.idToBeDelete && <Image src={_find(this.props.productImages, {id: this.state.idToBeDelete}).url} rounded className="edit-product-image"/>}
							</div>
						</div>
						<div className="text-center pt-3 px-3">
							<Alert variant="warning">
								<FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />You cannot get it back after deleting.
							</Alert>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={() => this.props.handleDeleteImage(this.props.activityId, this.state.idToBeDelete)} size="lg" className="btn-block" >
							Confirm Delete
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
    );
  }
}

ImageControl.propTypes = {
	productImages: PropTypes.array,
	handleUploadImage: PropTypes.func,
	handleDeleteImage: PropTypes.func,
	imageLoading: PropTypes.bool,
	activityId: PropTypes.string,
	shouldRefreshImages: PropTypes.bool,
}

export default ImageControl;
