import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';
import _omit from 'lodash/omit';
import _assign from 'lodash/assign';
import _map from 'lodash/map';

// import messageList from '../mock/messageList.json'
const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function getBookingList(bookingStatus, activityId, product_id, keyword, productSySID, page) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/RetrieveBkgList`;
    const body = {
      "bookingStatus": bookingStatus ? bookingStatus : '',
      "activityId": activityId ? activityId : '',
      "product_id": product_id ? product_id : '',
      "keywords": keyword ? keyword : '',
      "productSySID": productSySID,
      "page": page + 1,
    }

		dispatch({
			type: ActionTypes.GET_BOOKING_LIST_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_BOOKING_LIST_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_BOOKING_LIST_FAILURE,
  					payload: null,
            msg: `Get booking list failed.`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_BOOKING_LIST_FAILURE,
					error,
					payload: null,
          msg: `Get booking list failed. API issue.`
				})
			});
	};
}

//
export function getBookingDetail(id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/RetrieveBkg`;
    const body = {
      "ProductSysID": id,
    }

		dispatch({
			type: ActionTypes.GET_BOOKING_DETAIL_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_BOOKING_DETAIL_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_BOOKING_DETAIL_FAILURE,
  					payload: null,
            msg: `Get booking detail failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_BOOKING_DETAIL_FAILURE,
					error,
					payload: null,
          msg: `Get booking detail failed. API issue.`
				})
			});
	};
}

export function updateBooking(object, extraInfo) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageBkg/updateBkg`;
    const body = _assign(_omit(object, ['extraInfo']), {extraInfo: extraInfo})

		dispatch({
			type: ActionTypes.UPDATE_BOOKING_DETAIL_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.UPDATE_BOOKING_DETAIL_SUCCESS,
            msg: 'Update booking detail success.',
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.UPDATE_BOOKING_DETAIL_FAILURE,
  					payload: null,
            msg: `Update booking detail failed.`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.UPDATE_BOOKING_DETAIL_FAILURE,
					error,
					payload: null,
          msg: `Update booking detail failed. API issue.`
				})
			});
	};
}

//
export function getBookingSKU(ProductSysID) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/RetrieveBkgSKU`;
    const body = {
    	"ProductSysID": ProductSysID
    }

		dispatch({
			type: ActionTypes.GET_BOOKING_SKU_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_BOOKING_SKU_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_BOOKING_SKU_FAILURE,
  					payload: null,
            msg: `Get booking SKU failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_BOOKING_SKU_FAILURE,
					error,
					payload: null,
          msg: `Get booking SKU. API issue.`
				})
			});
	};
}

export function updateBookingSku(productSySID, array) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageBkg/manageBkgItem/update`;
    const body = {
      "productSySID": productSySID,
      "bookings": array
    }

		dispatch({
			type: ActionTypes.UPDATE_BOOKING_SKU_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.UPDATE_BOOKING_SKU_SUCCESS,
            msg: 'Update booking SKU success.',
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.UPDATE_BOOKING_SKU_FAILURE,
  					payload: null,
            msg: `Update booking SKU failed.`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.UPDATE_BOOKING_SKU_FAILURE,
					error,
					payload: null,
          msg: `Update booking SKU failed. API issue.`
				})
			});
	};
}

export function addBookingSku(object) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageBkg/AddBkgItem`;
    const body = object

		dispatch({
			type: ActionTypes.ADD_BOOKING_SKU_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.ADD_BOOKING_SKU_SUCCESS,
            msg: 'Add booking item success.',
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.ADD_BOOKING_SKU_FAILURE,
  					payload: null,
            msg: `Add booking item failed.`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.ADD_BOOKING_SKU_FAILURE,
					error,
					payload: null,
          msg: `Add booking item failed. API issue.`
				})
			});
	};
}

//
export function getBookingExtraInfo(ProductSysID) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/RetrieveBkgExtraInfo`;
    const body = {
    	"ProductSysID": ProductSysID
    }

		dispatch({
			type: ActionTypes.GET_BOOKING_EXTRA_INFO_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_BOOKING_EXTRA_INFO_SUCCESS,
            payload: result.extraInfo
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_BOOKING_EXTRA_INFO_FAILURE,
  					payload: null,
            msg: `Get booking Extra Info failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_BOOKING_EXTRA_INFO_FAILURE,
					error,
					payload: null,
          msg: `Get booking Extra Info. API issue.`
				})
			});
	};
}

export function addBookingExtraInfoTravelers(object) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageBkg/BkgExtraInfo/CREATE`;
    const body = object

		dispatch({
			type: ActionTypes.ADD_BOOKING_TRAVELER_INFO_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.ADD_BOOKING_TRAVELER_INFO_SUCCESS,
            msg: 'Add booking extra info (traveler) success.',
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.ADD_BOOKING_TRAVELER_INFO_FAILURE,
  					payload: null,
            msg: `Add booking extra info (traveler) failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.ADD_BOOKING_TRAVELER_INFO_FAILURE,
					error,
					payload: null,
          msg: `Add booking extra info (traveler) failed. API issue.`
				})
			});
	};
}

export function deleteBookingExtraInfoTravelers(orderid, extraInfoIDArray) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageBkg/BkgExtraInfo/DELETE`;
    const body = _map(extraInfoIDArray, id => ({
      "orderid" : orderid,
	    "extraInfoID": id
    }))

		dispatch({
			type: ActionTypes.DELETE_BOOKING_TRAVELER_INFO_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.DELETE_BOOKING_TRAVELER_INFO_SUCCESS,
            msg: 'Delete traveler info success.',
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.DELETE_BOOKING_TRAVELER_INFO_FAILURE,
  					payload: null,
            msg: `Delete traveler info failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.DELETE_BOOKING_TRAVELER_INFO_FAILURE,
					error,
					payload: null,
          msg: `Delete traveler info failed. API issue.`
				})
			});
	};
}

//
export function retrieveBkgHistory(id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/RetrieveBkgHistory`;
    const body = {
      "ProductSysID": id
    }

		dispatch({
			type: ActionTypes.GET_BOOKING_HISTORY_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_BOOKING_HISTORY_SUCCESS,
            payload: result.historylist
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_BOOKING_HISTORY_FAILURE,
  					payload: null,
            msg: `Get booking history failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_BOOKING_HISTORY_FAILURE,
					error,
					payload: null,
          msg: `Get booking history failed. API issue.`
				})
			});
	};
}

export function addBookingHistory(id, note) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageBkg/AddBookingHistory`;
    const body = {
      "ProductSysID": id,
      "remarks": note
    }

		dispatch({
			type: ActionTypes.ADD_BOOKING_HISTORY_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.ADD_BOOKING_HISTORY_SUCCESS,
            payload: true,
            msg: `Add booking history succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.ADD_BOOKING_HISTORY_FAILURE,
  					payload: null,
            msg: `Add booking history failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.ADD_BOOKING_HISTORY_FAILURE,
					error,
					payload: null,
          msg: `Add booking history failed. API issue.`
				})
			});
	};
}

//
export function processToMOE(id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/ProcessToMOE`;
    const body = {
      "ProductSysID": id
    }

		dispatch({
			type: ActionTypes.PROCESS_TO_MOE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.PROCESS_TO_MOE_SUCCESS,
            payload: true,
            msg: `Process to MOE succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.PROCESS_TO_MOE_FAILURE,
  					payload: null,
            msg: `Process to MOE failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.PROCESS_TO_MOE_FAILURE,
					error,
					payload: null,
          msg: `Process to MOE failed. API issue.`
				})
			});
	};
}

// msg
export function getBookingMessage(id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/GetBkgMessage/order`;
    const body = {
      "ProductSysID": id
    }

		dispatch({
			type: ActionTypes.GET_BOOKING_MESSAGE_REQUEST,
      body: body
		})

    // dispatch({
    //   type: ActionTypes.GET_BOOKING_MESSAGE_SUCCESS,
    //   payload: messageList.messageslist,
    //   msg: `Get booking message succeed.`,
    // })

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_BOOKING_MESSAGE_SUCCESS,
            payload: result.messageslist.reverse(),
            msg: `Get booking message succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_BOOKING_MESSAGE_FAILURE,
  					payload: null,
            msg: `Get booking message failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_BOOKING_MESSAGE_FAILURE,
					error,
					payload: null,
          msg: `Get booking message failed.`
				})
			});
	};
}

export function deleteBookingMessage(autonum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/ManageMessage/DELETE`;
    const body = {
    	"Autonum": autonum
    }

		dispatch({
			type: ActionTypes.DELETE_BOOKING_MESSAGE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.DELETE_BOOKING_MESSAGE_SUCCESS,
            payload: true,
            msg: `Delete booking message succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.DELETE_BOOKING_MESSAGE_FAILURE,
  					payload: false,
            msg: `Delete booking message failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.DELETE_BOOKING_MESSAGE_FAILURE,
					error,
					payload: null,
          msg: `Delete booking message failed.`
				})
			});
	};
}

export function createBookingMessage(orderId, form) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/ManageMessage/NEW`;
    const body = {
    	"ProductSysID": orderId,
    	"Subject": form.subject,
    	"Detail": form.detail,
    	"SendTo": form.sendTo
    }

		dispatch({
			type: ActionTypes.CREATE_BOOKING_MESSAGE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.CREATE_BOOKING_MESSAGE_SUCCESS,
            payload: true,
            msg: `Create booking message succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.CREATE_BOOKING_MESSAGE_FAILURE,
  					payload: false,
            msg: `Create booking message failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.CREATE_BOOKING_MESSAGE_FAILURE,
					error,
					payload: null,
          msg: `Create booking message failed.`
				})
			});
	};
}

export function readBookingMessage(autonum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/ManageMessage/READ`;
    const body = {
    	"Autonum": autonum
    }

		dispatch({
			type: ActionTypes.READ_BOOKING_MESSAGE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.READ_BOOKING_MESSAGE_SUCCESS,
            payload: true,
            msg: `Read booking message succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.READ_BOOKING_MESSAGE_FAILURE,
  					payload: false,
            msg: `Read booking message failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.READ_BOOKING_MESSAGE_FAILURE,
					error,
					payload: null,
          msg: `Read booking message failed.`
				})
			});
	};
}

export function getUnreadBookingMessage() {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/GetBkgMessage/unread`;
    const body = {}

		dispatch({
			type: ActionTypes.GET_UNREAD_BOOKING_MESSAGE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_UNREAD_BOOKING_MESSAGE_SUCCESS,
            payload: result.messageslist,
            msg: `Get unread booking message succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_UNREAD_BOOKING_MESSAGE_FAILURE,
  					payload: false,
            msg: `Get unread message failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_UNREAD_BOOKING_MESSAGE_FAILURE,
					error,
					payload: null,
          msg: `Get unread message failed. API issue.`
				})
			});
	};
}

export function markFulfill(id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/manageBkg/markisFulfill`;
    const body = {
      "ProductSysID": id
    }

		dispatch({
			type: ActionTypes.MARK_FULFILL_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.MARK_FULFILL_SUCCESS,
            payload: true,
            msg: `Mark fulfilled succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.MARK_FULFILL_FAILURE,
  					payload: false,
            msg: `Mark fulfilled failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.MARK_FULFILL_FAILURE,
					error,
					payload: null,
          msg: `Mark fulfilled failed. API issue.`
				})
			});
	};
}

export function clearBookingDetail() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_BOOKING_DETAIL,
		})
	};
}
