import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Dropdown, Badge, Media} from 'react-bootstrap';
import {Avatar, IconLogOut, IconArchive, IconMessageSquare, IconShoppingCart, IconTrendingUp} from "sancho";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';

import Loading from '../components/Loading';

import {getUnreadBookingMessage} from '../actions/booking';
import {getDetails} from '../actions/index';
import Auth from '../Auth/Auth';
const auth = new Auth();

class MainMenu extends Component {
	constructor(props) {
		super(props);
		this.logout = this.logout.bind(this);
	}

	componentDidMount() {
		this.props.getUnreadBookingMessage()

		if (!auth.getCompanyCode()) {
			this.props.getDetails()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.shouldRefreshBookingMessage && this.props.shouldRefreshBookingMessage) {
			this.props.getUnreadBookingMessage()
		}
	}

	logout() {
		auth.logout();
	}

	render() {
		const hasUserInfo = this.props.hasUserInfo || !!auth.getCompanyCode()
		return (
			<div>
				<div className="top_nav py-2">
			    <div className="row">
		        <div className="col-lg-auto">
	            <p className="company-font mb-0 mt-1">
								<Link className="name" to={`${process.env.PUBLIC_URL}/`}>Product Management System</Link>
							</p>
		        </div>

						<Dropdown alignRight className="col-lg-auto ml-lg-auto">
							<Dropdown.Toggle variant="none" className="p-0">
								<div className="inline"><Avatar size="sm" name={auth.getName()} /></div>
								{this.props.myUnreadBookingMessage &&this.props.myUnreadBookingMessage.length > 0 && <div className="notification-dot"><FontAwesomeIcon icon={faCircle} className="red" size="xs" /></div>}
							</Dropdown.Toggle>
							{hasUserInfo &&
								<Dropdown.Menu>

									{auth.getPageAccess().productPage &&
										<Link className="dropdown-item" to="/product-list">
											<IconShoppingCart className="mr-2" /> Product List
										</Link>
									}

									{auth.getPageAccess().bookingPage &&
										<Link className="dropdown-item" to="/booking-list">
											<IconArchive className="mr-2" /> Booking List
										</Link>
									}

									{auth.getPageAccess().messagePage &&
										<Link className="dropdown-item" to="/unread-message-list">
											<IconMessageSquare className="mr-2" /> Booking Message <Badge pill variant="danger">{this.props.myUnreadBookingMessage ? this.props.myUnreadBookingMessage.length : '0'}</Badge>
										</Link>
									}

									{auth.getPageAccess().markupPage &&
										<Link className="dropdown-item" to="/markups">
											<IconTrendingUp className="mr-2" /> Markups
										</Link>
									}

									<Dropdown.Divider />
									<button className="dropdown-item" onClick={this.logout}>
										<Media>
											<IconLogOut className="mr-2 align-self-center" />
										  <Media.Body>
												Logout
												<div className="small text-muted">{auth.getName()}</div>
										  </Media.Body>
										</Media>
									</button>
								</Dropdown.Menu>
							}
						</Dropdown>
			    </div>
				</div>

				{this.props.isLoading && <Loading />}
			</div>
		);
	}
}


MainMenu.propTypes = {
  isLoading: PropTypes.bool,
	handleProductClick: PropTypes.func,
	currentPage: PropTypes.string,
	hasUserInfo: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
    isLoading: state.loading.pageLoading,
    myUnreadBookingMessage: state.booking.myUnreadBookingMessage,
    shouldRefreshBookingMessage: state.booking.shouldRefreshBookingMessage,
    hasUserInfo: state.index.hasUserInfo,
	}
}

const mapDispatchToProps = {
	getUnreadBookingMessage,
	getDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
