import {combineReducers} from 'redux';
import * as ActionTypes from '../constants/ActionTypes';

// Import the actions types constant we defined in our actions
import loading from './loading';
import msg from './msg';
import product from './product';
import booking from './booking';

const initialState = {
	hasUserInfo: null,
};

const index = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_DETAIL_BY_TOKEN_SUCCESS:
		return Object.assign({}, state, {
			hasUserInfo: action.payload,
		});
	default:
		return state;
	}
};

// Combine all the reducers
const rootReducer = combineReducers({
  index,
  loading,
	msg,
	product,
	booking,
})

export default rootReducer;
