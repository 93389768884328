import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Row, Col, Form} from 'react-bootstrap';
import BtnWithAmendmentNotes from '../../components/BtnWithAmendmentNotes';
import cogoToast from 'cogo-toast';

class AddExtraInfoModal extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);

		this.state = {
			"pid": this.props.productID,
			"infoType": "general",
			"infoSeq": "1",
			"infovalue": "text",
			"infoRemark1": "",
			"infoRemark2": "",
			"isrequired": true,
		};
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.show && this.props.show) {
			this.resetForm()
		}
	}

	resetForm() {
		this.setState({
			"pid": this.props.productID ? this.props.productID : "",
      "infoType": "general",
      "infoSeq": "1",
      "infovalue": "text",
      "infoRemark1": "",
      "infoRemark2": "",
      "isrequired": true,
		})
	}

	onFieldChange(e) {
		if (e.target.name === 'isrequired') {
			this.setState({
				[e.target.name]: e.target.checked
			})
		} else {
			this.setState({
				[e.target.name]: e.target.value
			})
		}
	}

	onAddClick(amendmentNotes) {
		if (this.state.infoRemark1 && this.state.infoRemark2) {
			this.props.handleConfirm(Object.assign({'amendmentNotes': amendmentNotes}, this.state)) // include amendmentNotes
		} else {
			cogoToast.error(<div><b>Name</b> and <b>Hints</b> are needed.</div>, {hideAfter: 5})
		}
	}

	onSelectChange(name, value) {
		this.setState({
			[name]: value
		})
	}

	onKeyDown(event) {
    if (event.key === "Enter") {
			this.onAddClick()
    }
  };

  render() {
		return (
			<Modal centered show={this.props.show} onHide={() => this.props.handleClose(false)} onKeyDown={this.onKeyDown}>
				<Modal.Header>
					<Modal.Title>Add Extra Info</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Row className="mb-3">
							<Col lg="5">Item</Col>
							<Col>{this.props.itemTitle}</Col>
						</Row>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Name of Extra Info*</Form.Label>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="infoRemark1"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.infoRemark1} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Hints*</Form.Label>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="infoRemark2"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.infoRemark2} /></Col>
						</Form.Group>
						<Form.Group as={Row} >
							<Form.Label column lg="5">Type</Form.Label>
							<Col><Form.Control
								as="select"
								name="infoType"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.infoType}
							>
								<option value="general">general</option>
								<option value="travelers">travelers</option>
							</Form.Control></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Value form</Form.Label>
							<Col><Form.Control
								as="select"
								name="infovalue"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.infovalue}
							>
								<option value="text">text</option>
								<option value="list">list</option>
							</Form.Control></Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column lg="5">Required</Form.Label>
							<Col>
								<Form.Check
									type="checkbox"
									name="isrequired"
									onChange={(e) => this.onFieldChange(e)}
									checked={this.state.isrequired} />
							</Col>
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<BtnWithAmendmentNotes
						text="Add Extra Info"
						handleClcik={this.onAddClick}
						isDisabled={!this.state.infoRemark1 || !this.state.infoRemark2}
						isAddIcon
					/>
				</Modal.Footer>
			</Modal>
	  );
	}
}

AddExtraInfoModal.propTypes = {
	handleClose: PropTypes.func,
	handleConfirm: PropTypes.func,
	show: PropTypes.bool,
	productID: PropTypes.string,
	itemTitle: PropTypes.string,
}

export default AddExtraInfoModal;
