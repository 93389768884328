import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {Row, Col, ListGroup} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Breadcrumbs, BreadcrumbItem} from "sancho";
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';

import {prettyDateTime} from '../util/transformDate';

import _chunk from 'lodash/chunk';
import _get from 'lodash/get';
import Auth from '../Auth/Auth';
const auth = new Auth();
const chunkSize = 20;

class UnreadBookingMsgPage extends Component {
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.onBookingClick = this.onBookingClick.bind(this);
    this.onToogleAllMsg = this.onToogleAllMsg.bind(this);

    this.state = {
      currentPage: 0,
      isAllMsg: true,
    };
  }

  onPageChange(page) {
		this.setState({
			currentPage: page
		})
	}

  onBookingClick(id) {
    this.props.history.push(`booking/${id}`);
  }

  onToogleAllMsg(bool) {
    this.setState({
      isAllMsg: bool,
      currentPage: 0
    })
  }

  render () {
    const unreadMsg = this.state.isAllMsg ? this.props.allUnreadBookingMessage : this.props.myUnreadBookingMessage
    const hasUnreadMsg = unreadMsg && unreadMsg.length > 0

    return (
      <div>
        <title>Unread Message List | Product Booking System</title>

        <div className="container">

          <Breadcrumbs className="mt-1 mb-3">
            <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
            <BreadcrumbItem>Booking Message</BreadcrumbItem>
          </Breadcrumbs>

          <h1 className="text-center mb-5">Booking Message</h1>

          <Row className="justify-content-center mb-3">
            <Col lg="9">
              {/*<ButtonGroup aria-label="Basic example" >
                <Button variant="primary" active={!!this.state.isAllMsg} onClick={() => this.onToogleAllMsg(true)}>All Unread Message</Button>
                <Button variant="primary" active={!this.state.isAllMsg} onClick={() => this.onToogleAllMsg(false)}>Only my unread Message</Button>
              </ButtonGroup>*/}

              <span className="float-right"><FontAwesomeIcon icon={faCircle} className="red" size="xs" /> = Only my unread Message</span>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg="9">
              <ListGroup variant="flush">
                <ListGroup.Item >
                  <Row className="small font-weight-bold">
                    <Col xs="1" className="text-center">TypeCode<div>Login</div></Col>
                    <Col xs="2" className="text-center">Order no.</Col>
                    <Col>Subject & Detail</Col>
                    <Col lg="auto" className="text-right">Send date</Col>
                  </Row>
                </ListGroup.Item>
                {hasUnreadMsg ?
                  _chunk(unreadMsg, chunkSize)[this.state.currentPage].map((msg, i) =>
                    {
                      const isMe = auth.getCompanyCode() === _get(msg, 'TypeCode') && auth.getUserCode() === _get(msg, 'Login')
                      return(
                        <ListGroup.Item action onClick={() => this.onBookingClick(msg.ProductSysID)} key={msg.Autonum} >
                          <Row>
                            <Col xs="1" className="text-center">
                              <div className="bg-grey p-2 small inline text-center">
                                <div>{_get(msg, 'TypeCode')}</div>
                                <div>{_get(msg, 'Login')}</div>
                              </div>
                            </Col>
                            <Col xs="2" className="text-center">
                              {_get(msg, 'ProductSysID')}
                            </Col>
                            <Col>
                              <div>{!isMe && <FontAwesomeIcon icon={faCircle} className="red" size="xs" />} <u>{_get(msg, 'Subject')}</u></div>
                              <div className="pre-line">{_get(msg, 'Detail')}</div>
                            </Col>
                            <Col lg="auto" className="text-right">{prettyDateTime(_get(msg, 'Senddate'))}</Col>
                          </Row>
                        </ListGroup.Item>
                      )}
                    ): <div className="relative text-center p-5 card">No messages.</div>
                }
                {this.props.loading &&
                  <Loading isAbsolute />
                }
              </ListGroup>
            </Col>
          </Row>


  				{_chunk(unreadMsg, chunkSize).length > 1 &&
  					<Pagination
  						handlePageChange={this.onPageChange}
  						noOfPages={_chunk(unreadMsg, chunkSize).length}
  						currentPage={this.state.currentPage}
  					/>
  				}

        </div>

      </div>
    );
  }
}


UnreadBookingMsgPage.propTypes = {
	myUnreadBookingMessage: PropTypes.array,
	allUnreadBookingMessage: PropTypes.array,
};

function mapStateToProps(state) {
	return {
    myUnreadBookingMessage: state.booking.myUnreadBookingMessage,
    allUnreadBookingMessage: state.booking.allUnreadBookingMessage,
	}
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(UnreadBookingMsgPage);
