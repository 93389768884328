import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Row, Col, Form, Table} from 'react-bootstrap';
import CurrencySelect from '../../components/CurrencySelect';
import {status} from '../../util/constances';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';

class EditSkuModal extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);

		this.state = {
				skuList: null
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			this.resetForm()
		}
	}

	resetForm() {
		this.setState({
			skuList: this.props.skuList
		})
	}

	onFieldChange(e, i) {
		const deep = _cloneDeep(this.state.skuList)
		_set(deep, [i, e.target.name], e.target.value)

		this.setState({
			skuList: deep
		})
	}

	onSelectChange(name, value, index) {
		const deep = _cloneDeep(this.state.skuList)
		_set(deep, [index, name], value)

		this.setState({
			skuList: deep
		})
	}

	onSubmitClick() {
		this.props.handleConfirm(this.props.orderid, this.state.skuList)
	}

	onKeyDown(event) {
    if (event.key === "Enter") {
			this.onSubmitClick()
    }
  };

  render() {
		return (
			<Modal size="lg" centered show={this.props.show} onHide={() => this.props.handleClose(false)} onKeyDown={this.onKeyDown}>
				<Modal.Header>
					<Modal.Title>Edit SKU</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Table bordered>
						<thead>
							<tr>
								<th>Title</th>
								<th width="96">Qty</th>
								<th>Status</th>
								<th width="128">Item Ref</th>
								<th>Sell</th>
								<th>Cost</th>
								<th>Net net</th>
							</tr>
						</thead>
						<tbody>
						{this.state.skuList && this.state.skuList.map((bk, i) =>
							<tr key={i}>
								<td>
									<Form.Control
										type="text"
										autoComplete="off"
										placeholder="SKU Title"
										name="skuName"
										onChange={(e) => this.onFieldChange(e, i)}
										value={bk.skuName}
										className="mb-2" />
								</td>
								<td>
									<Form.Control
										type="number"
										autoComplete="off"
										placeholder="Quantity"
										name="quantity"
										min="1"
										onChange={(e) => this.onFieldChange(e, i)}
										value={bk.quantity} />
								</td>
								<td>
									<Form.Control
										as="select"
										name="itemStatus"
										onChange={(e) => this.onFieldChange(e, i)}
										value={bk.itemStatus} >
										{status.map(sta =>
											<option value={sta.code} key={sta.code}>{sta.name}</option>
										)}
									</Form.Control>
								</td>
								<td>
									<Form.Control
										type="text"
										autoComplete="off"
										placeholder="Item ref"
										name="itemRef"
										onChange={(e) => this.onFieldChange(e, i)}
										value={bk.itemRef} />
								</td>
								<td>
									<Row>
										<Col>
											<CurrencySelect
												handleSelect={this.onSelectChange}
												selectedCode={bk.sellCurrency}
												name="sellCurrency"
												index={i}
											/>
										</Col>
										<Col className="pl-0">
											<Form.Control
												type="number"
												name="sellTtlAmt"
												onChange={(e) => this.onFieldChange(e, i)}
												value={bk.sellTtlAmt} />
										</Col>
									</Row>
								</td>
								<td>
									<Row>
										<Col>
											<CurrencySelect
												handleSelect={this.onSelectChange}
												selectedCode={bk.costCurrency}
												name="costCurrency"
												index={i}
											/>
										</Col>
										<Col className="pl-0">
											<Form.Control
											type="number"
											name="costTtlamt"
											onChange={(e) => this.onFieldChange(e, i)}
											value={bk.costTtlamt} />
										</Col>
									</Row>
								</td>
								<td>
									<Row>
										<Col>
											<CurrencySelect
												handleSelect={this.onSelectChange}
												selectedCode={bk.netnetCurrency}
												name="netnetCurrency"
												index={i}
											/>
										</Col>
										<Col className="pl-0">
											<Form.Control
												type="number"
												name="netnetTtlamt"
												onChange={(e) => this.onFieldChange(e, i)}
												value={bk.netnetTtlamt} />
										</Col>
									</Row>
								</td>

							</tr>
						)}
						</tbody>
					</Table>
				</Modal.Body>
				<Modal.Footer className="text-center">
					<Button variant="primary" onClick={this.onSubmitClick}>
						Submit Edit
					</Button>
				</Modal.Footer>
			</Modal>
	  );
	}
}

EditSkuModal.propTypes = {
	skuList: PropTypes.array,
	handleClose: PropTypes.func,
	handleConfirm: PropTypes.func,
	show: PropTypes.bool,
	orderid: PropTypes.string,
}

export default EditSkuModal;
