import * as ActionTypes from '../constants/ActionTypes';
import Immutable from 'immutable';
import moment from 'moment';
import _filter from 'lodash/filter';
import Auth from '../Auth/Auth';
const auth = new Auth();

const initialState = {
	bookingDetail: null,
	bookingList: null,
	shouldRefreshBooking: false,
	shouldRefreshBookingSKU: false,
	updatedBookingExtraInfoTimeStamp: moment().valueOf(),
	shouldRefreshBookingExtraInfo: false,
	bookingHistory: null,
	shouldGetHistory: null,
	bookingSku: null,
	bookingExtraInfo: null,
	bookingMessage: null,
	shouldRefreshBookingMessage: false,
	allUnreadBookingMessage: null,
	myUnreadBookingMessage: null,
};

const product = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_BOOKING_LIST_SUCCESS:
		return Object.assign({}, state, {
			bookingList: action.payload,
		});
	case ActionTypes.GET_BOOKING_DETAIL_SUCCESS:
		return Object.assign({}, state, {
			bookingDetail: Immutable.fromJS(action.payload),
			shouldRefreshBooking: false,
		});
	case ActionTypes.UPDATE_BOOKING_DETAIL_SUCCESS:
	case ActionTypes.MARK_FULFILL_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshBooking: true,
		});
	case ActionTypes.GET_BOOKING_HISTORY_SUCCESS:
		return Object.assign({}, state, {
			bookingHistory: action.payload,
		});
	case ActionTypes.ADD_BOOKING_HISTORY_SUCCESS:
		return Object.assign({}, state, {
			shouldGetHistory: true,
		});
	case ActionTypes.GET_BOOKING_SKU_SUCCESS:
		return Object.assign({}, state, {
			bookingSku: action.payload,
			shouldRefreshBookingSKU: false,
		});
	case ActionTypes.UPDATE_BOOKING_SKU_SUCCESS:
	case ActionTypes.ADD_BOOKING_SKU_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshBookingSKU: true,
		});
	case ActionTypes.GET_BOOKING_EXTRA_INFO_SUCCESS:
		return Object.assign({}, state, {
			bookingExtraInfo: action.payload,
			updatedBookingExtraInfoTimeStamp: moment().valueOf(),
			shouldRefreshBookingExtraInfo: false,
		});
	case ActionTypes.ADD_BOOKING_TRAVELER_INFO_SUCCESS:
	case ActionTypes.DELETE_BOOKING_TRAVELER_INFO_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshBookingExtraInfo: true,
		});
	case ActionTypes.GET_BOOKING_MESSAGE_SUCCESS:
		return Object.assign({}, state, {
			bookingMessage: action.payload,
			shouldRefreshBookingMessage: false,
		});
	case ActionTypes.CREATE_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.READ_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.DELETE_BOOKING_MESSAGE_SUCCESS:
	return Object.assign({}, state, {
			shouldRefreshBookingMessage: true,
		});
	case ActionTypes.GET_UNREAD_BOOKING_MESSAGE_SUCCESS:
	const myMsg = _filter(action.payload, r => (r.TypeCode === auth.getCompanyCode() && r.Login === auth.getUserCode()))
	return Object.assign({}, state, {
			allUnreadBookingMessage: action.payload,
			myUnreadBookingMessage: action.payload.filter( ( el ) => !myMsg.includes( el ) ),
		});
	case ActionTypes.CLEAR_BOOKING_DETAIL:
	return Object.assign({}, state, {
			bookingDetail: null,
			bookingHistory: null,
			bookingSku: null,
			bookingExtraInfo: null,
			bookingMessage: null,
		});
	default:
		return state;
	}
};

export default product;
