import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button, Form, Table, Accordion, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTrashAlt, faPlus, faTimes, faChevronDown, faEdit, faExternalLinkAlt, faDollarSign} from '@fortawesome/free-solid-svg-icons';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import AddSkuModal from '../../../components/productDetailPage/AddSkuModal';
import SupplierCodeSelect from '../../../components/SupplierCodeSelect';
import CurrencySelect from '../../../components/CurrencySelect';
import DeleteModal from '../../../components/productDetailPage/DeleteModal';
import BtnWithAmendmentNotes from '../../../components/BtnWithAmendmentNotes';

class SKU extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.onToggleAddModal = this.onToggleAddModal.bind(this);
		this.onToggleDeleteModal = this.onToggleDeleteModal.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
		this.onSubmitChangeClick = this.onSubmitChangeClick.bind(this);
		this.onEditMarkupCLick = this.onEditMarkupCLick.bind(this);

		this.state = {
			isAddModleOpen: false,
			skuList: [],
			isDeleteModalOpen: false,
			SkuTobeDelete: null,
		};
	}

	componentDidMount() {
		this.setList()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.updateItemSuccessTimStamp !== this.props.updateItemSuccessTimStamp) {
      this.onToggleAddModal(false)
      this.onToggleDeleteModal(false)
    }

		if (prevProps.skuList !== this.props.skuList) {
			this.setList()
		}
	}

	setList() {
		this.setState({
			skuList: this.props.skuList
		})
	}

  onSubmitChangeClick(amendmentNotes) {
		this.props.handleUpdate(this.state.skuList, amendmentNotes)  // include amendmentNotes
  }

  onFieldChange(e, i) {
		const deep = _cloneDeep(this.state.skuList)
		_set(deep, [i, e.target.name], e.target.value)

		this.setState({
			skuList: deep
		})
  }

	onSelectChange(name, value, index) {
		const deep = _cloneDeep(this.state.skuList)
		_set(deep, [index, name], value)

		this.setState({
			skuList: deep
		})
  }

	onAddClick() {
		this.onToggleAddModal(true)
	}

	onDeleteClick() {
		this.props.handleDelete(this.props.productId, this.state.SkuTobeDelete)
	}

	onToggleAddModal(bool) {
		this.setState({
			isAddModleOpen: bool
		})
	}

	onToggleDeleteModal(bool, id) {
		this.setState({
			isDeleteModalOpen: bool,
			SkuTobeDelete: bool ? id : null,
		})
	}

	onEditMarkupCLick(id) {
		this.props.handleEditMarkup(id)
	}

	getPrice() {
		window.open (`/pricing/${this.props.activityId}-${this.props.productId}`,
		"mywindow","menubar=1,resizable=1,width=1200,height=750");
	}

  render() {
		return (
			<div>
				<div className="text-right pb-3">
					<Button onClick={() => this.onAddClick()} variant="outline-primary" className="mr-3"><FontAwesomeIcon icon={faPlus} className="mr-1" />Add SKU</Button>
					{this.state.skuList && this.state.skuList.length > 0 &&
						<Button variant="outline-primary"  onClick={() => this.getPrice()}><FontAwesomeIcon icon={faDollarSign} className="mr-2" />Price by Date <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" className="ml-1" /></Button>
					}
				</div>

				{this.state.skuList.length > 0 ?
					<div>
						<Table size="sm" >
							<thead>
								<tr>
									<th width="56">SKU ID</th>
									<th>Title</th>
									<th width="140">Supplier Code</th>
									<th width="75">Min Pax</th>
									<th width="75">Max Pax</th>
									<th width="200">Retail Currency & Amount</th>
									<th width="85">Cut off date</th>
									<th className="text-center pt-4" width="52"></th>
								</tr>
							</thead>
							<tbody>
								{this.state.skuList.map((sku, i) =>
									<tr key={i}>
										<td><div className="text-muted pt-2">{sku.sku_id}</div></td>
										<td colSpan="7" className="p-0">

											<Table size="sm" borderless striped={false} className="mb-0">
												<tbody>
													<tr>
														<td>
															<Form.Control
																type="text"
																autoComplete="off"
																name="title"
																onChange={(e) => this.onFieldChange(e, i)}
																value={sku.title}
																className=""
																placeholder="SKU title" />
														</td>
														<td width="140">
															<SupplierCodeSelect
																handleSelect={this.onSelectChange}
																selectedCode={sku.engine}
																name="engine"
																index={i}
															/>
														</td>
														<td width="75">
															<Form.Control
															type="text"
															autoComplete="off"
															name="sku_min_pax"
															onChange={(e) => this.onFieldChange(e, i)}
															value={sku.sku_min_pax} />
														</td>
														<td width="75">
															<Form.Control
															type="text"
															autoComplete="off"
															name="sku_max_pax"
															onChange={(e) => this.onFieldChange(e, i)}
															value={sku.sku_max_pax} /></td>
														<td width="200">
															<Row>
																<Col className="pr-0" xs="6"><CurrencySelect
																	handleSelect={this.onSelectChange}
																	selectedCode={sku.retailCurrency}
																	name="retailCurrency"
																	index={i}
																/></Col>
																<Col className="pl-1"><Form.Control
																type="text"
																autoComplete="off"
																name="retailAmount"
																onChange={(e) => this.onFieldChange(e, i)}
																value={sku.retailAmount} /></Col>
															</Row>
														</td>
														<td width="85">
															<Form.Control
															type="text"
															autoComplete="off"
															name="cutoffdate"
															onChange={(e) => this.onFieldChange(e, i)}
															value={sku.cutoffdate} /></td>
														<td className="text-center" width="52">
															<Button variant="outline-danger" onClick={() => this.onToggleDeleteModal(true, sku.sku_id)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
														</td>
													</tr>

												</tbody>
											</Table>

											{this.props.productMarkups && _filter(this.props.productMarkups, {sku_id: sku.sku_id}).length > 0 &&
												<Accordion defaultActiveKey={null} className="mb-1 mx-1">
													<Accordion.Toggle as={Card.Header} eventKey="0" className="border-bottom-0">
														<div className="small">Markup <FontAwesomeIcon icon={faChevronDown} className="ml-2" /></div>
													</Accordion.Toggle>
													<Accordion.Collapse eventKey="0">
														<Card.Body className="p-2 border">
															<div className="px-2 py-1">
																<Table className="mb-0" borderless>
																	<tbody>
																		<tr className="small border-bottom">
																			<td>Company Code</td>
																			<td>Currency</td>
																			<td>Price Above</td>
																			<td>Roundup</td>
																			<td>Markup (%)</td>
																			<td>Markup (amount)</td>
																			<td>Enable</td>
																			<td>Edit</td>
																		</tr>
																		{_filter(this.props.productMarkups, {sku_id: sku.sku_id}).map((markup, i) =>
																			<tr key={i}>
																				<td>{markup.typecode} - {markup.typecodename}</td>
																				<td>{markup.curr}</td>
																				<td>{markup.priceabove}</td>
																				<td>{markup.roundup}</td>
																				<td>{markup.r_markpercent}</td>
																				<td>{markup.r_markamt}</td>
																				<td><FontAwesomeIcon icon={markup.isenable ? faCheck : faTimes} className={markup.isenable ? 'text-success' : 'text-danger'} /></td>
																				<td><Button variant="outline-secondary" size="sm" onClick={() => this.onEditMarkupCLick(markup.id)}><FontAwesomeIcon icon={faEdit} /></Button></td>
																			</tr>
																		)}
																	</tbody>
																</Table>
															</div>
														</Card.Body>
													</Accordion.Collapse>
												</Accordion>
											}

										</td>
									</tr>
								)}
							</tbody>
						</Table>
						<BtnWithAmendmentNotes
							text="Save SKU Change"
							isSticky
							handleClcik={this.onSubmitChangeClick}
						/>
					</div>:
					<div className="text-center card text-muted p-5">No SKU.</div>
				}

				<AddSkuModal
					handleClose={this.onToggleAddModal}
					handleConfirm={this.props.handleCreate}
					show={this.state.isAddModleOpen}
					productID={this.props.productId}
					itemTitle={this.props.itemTitle}
				/>

				<DeleteModal
					show={this.state.isDeleteModalOpen}
					handleConfirm={this.onDeleteClick}
					handleClose={() => this.onToggleDeleteModal(false)}
					description={this.state.isDeleteModalOpen ? `SKU: ${_find(this.props.skuList, {sku_id: this.state.SkuTobeDelete}).title}` : ''}
				/>

			</div>
    );
  }
}

SKU.propTypes = {
	skuList: PropTypes.array,
	handleUpdate: PropTypes.func,
	handleDelete: PropTypes.func,
	handleCreate: PropTypes.func,
	isComponentLoading: PropTypes.bool,
	updateItemSuccessTimStamp: PropTypes.number,
	productId: PropTypes.string,
	itemTitle: PropTypes.string,
	activityId: PropTypes.string,
	markup: PropTypes.string,
	productMarkups: PropTypes.array,
	handleEditMarkup: PropTypes.func,
}

export default SKU;
