import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Auth from '../Auth/Auth';
import Select from 'react-select';

import _find from 'lodash/find';
import _map from 'lodash/map';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

const endpoint = `${currentServerURL}/GetCurrency`;
const method = 'POST'

class CurrencySelect extends Component {
	constructor(props) {
		super(props);
		this.onSelectClick = this.onSelectClick.bind(this);
		this.getList = this.getList.bind(this);

		this.state = {
			options: [],
			isSearching: false,
			currentObj: '',
		};
	}

	componentDidMount() {
		this.getLabel(this.props.selectedCode)
	}

	componentDidUpdate(prevProps) {
		if(prevProps.selectedCode !== this.props.selectedCode) {
			this.getLabel(this.props.selectedCode)
		}
	}

	getList() {
    const body = {}
		if(this.state.options.length === 0) {
			this.setState({
				isSearching: true
			})

			fetch(endpoint, {
				method: method,
				body: JSON.stringify(body),
				headers: {
					Authorization: 'Bearer ' + auth.getAccessToken(),
					'Content-Type': 'application/json'
				},
			})
				.then(response => response.json())
				.then((result) => {
					if (result.responseSuccess && result.currencyList.length > 0) {
						let options = _map(result.currencyList, (r) => ({
							'value': r,
							'label': r
						}))

						this.setState({
							options: options,
							isSearching: false
						})
					} else {
						this.setState({
							isSearching: false
						})
					}
				})
				.catch((error) => {
					console.log('error: ', error)
					this.setState({
						isSearching: false
					})
				});
		}
	}


	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.props.handleSelect(this.props.name, selectedOption.value, this.props.index)
			this.setState({
				currentObj: selectedOption
			})
		} else { // clear
			this.clearLoginOptions()
		}
	}

	getLabel(string) {
		if (string) {
			const body = {"keywords": string}

			fetch(endpoint, {
				method: method,
				body: JSON.stringify(body),
				headers: {
					Authorization: 'Bearer ' + auth.getAccessToken(),
					'Content-Type': 'application/json'
				}
			})
				.then(response => response.json())
				.then((result) => {
					if (result.responseSuccess && result.currencyList.length > 0) {
						let options = _map(result.currencyList, (r) => ({
							'value': r,
							'label': r
						}))
						this.setState({
							currentObj: _find(options, {'value': string})
						})
					}
				})
				.catch((error) => {
					console.log('error: ', error)
				});
		}
	}

	clearLoginOptions() {
		this.setState({
			currentObj: ''
		});
		this.props.handleSelect(this.props.name, '', this.props.index)
	}

  render() {
		return (
			<Select
				name={this.props.name}
				value={this.state.currentObj}
				onChange={this.onSelectClick}
				options={this.state.options ? this.state.options : []}
				placeholder="Select Currency"
				isLoading={this.state.isSearching}
				onMenuOpen={this.getList}
				isDisabled ={this.props.disabled}
				className={`select-control ${this.props.isInvalid ? 'invalid' : ''}`}
			/>
    );
  }
}

CurrencySelect.propTypes = {
	handleSelect: PropTypes.func,
	selectedCode: PropTypes.string,
	name: PropTypes.string,
	disabled: PropTypes.bool,
	index: PropTypes.number,
}

export default CurrencySelect;
