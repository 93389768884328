import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Row, Col, Badge} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShoppingCart, faArchive, faCommentAlt, faLevelUpAlt} from '@fortawesome/free-solid-svg-icons';
import {clearSearchProductCriteria} from '../actions/product';
import Auth from '../Auth/Auth';
const auth = new Auth();

class Home extends Component {
	constructor(props) {
		super(props);

    this.state = {
    }
	}

  componentDidMount() {
		// this.props.clearSearchProductCriteria()
  }

	componentDidUpdate(prevProps, prevState) {
	}


  render() {
		const hasUserInfo = this.props.hasUserInfo || !!auth.getCompanyCode()

    return (
      <div>
				<title>Product Management System</title>

				<div>
					<h1 className="mb-4 text-center home-title-bar">Product Management System</h1>

							{hasUserInfo &&
								<div className="container mt-5">
									<Row className="justify-content-center">
										<Col lg={Object.values(auth.getPageAccess()).findIndex(ele => !ele) < 0 ? 10 : 12}>
											<Row className="text-center justify-content-center">
												{auth.getPageAccess().productPage &&
													<Col lg={Object.values(auth.getPageAccess()).findIndex(ele => !ele) < 0 ? 6 : 4}>
														<Link to="/product-list" className="btn btn-block home-card">
															<FontAwesomeIcon icon={faShoppingCart} style={{height: "100px", width: "100px", color: "#17a2b8"}} className="my-3" />
															<div>
																<div className="h1 font-weight-light">Product List</div>
															</div>
														</Link>
													</Col>
												}

												{auth.getPageAccess().bookingPage &&
													<Col lg={Object.values(auth.getPageAccess()).findIndex(ele => !ele) < 0 ? 6 : 4}>
														<Link to="/booking-list" className="btn btn-block home-card">
															<FontAwesomeIcon icon={faArchive} style={{height: "100px", width: "100px", color: "#17a2b8"}} className="my-3" />
															<div>
																<div className="h1 font-weight-light">Booking List</div>
															</div>
														</Link>
													</Col>
												}

												{auth.getPageAccess().messagePage &&
													<Col lg={Object.values(auth.getPageAccess()).findIndex(ele => !ele) < 0 ? 6 : 4}>
														<Link to="/unread-message-list" className="btn btn-block home-card">
															<div className="badge-inside-talk">
																<FontAwesomeIcon icon={faCommentAlt} style={{height: "100px", width: "100px", color: "#17a2b8"}} className="my-3" />
																<Badge pill variant="danger">{this.props.myUnreadBookingMessage ? this.props.myUnreadBookingMessage.length : '0'}</Badge>
															</div>
															<div>
																<div className="h1 font-weight-light inline">Booking Message</div>
															</div>
														</Link>
													</Col>
												}

												{auth.getPageAccess().markupPage &&
													<Col lg={Object.values(auth.getPageAccess()).findIndex(ele => !ele) < 0 ? 6 : 4}>
														<Link to="/markups" className="btn btn-block home-card">
															<div className="badge-inside-talk">
																<FontAwesomeIcon icon={faLevelUpAlt} style={{height: "100px", width: "100px", color: "#17a2b8"}} className="my-3" />
															</div>
															<div>
																<div className="h1 font-weight-light inline">Markups</div>
															</div>
														</Link>
													</Col>
												}

											</Row>
										</Col>
									</Row>

								</div>
							}


				</div>
      </div>
    );
  }
}


Home.propTypes = {
	hasUserInfo: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
    myUnreadBookingMessage: state.booking.myUnreadBookingMessage,
    hasUserInfo: state.index.hasUserInfo,
	}
}

const mapDispatchToProps = {
	clearSearchProductCriteria
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
