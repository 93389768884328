import auth0 from 'auth0-js';
import history from '../history';
import cookie from "universal-cookie";

const cookies = new cookie();

export default class Auth {
  accessToken;
  idToken;
  expiresAt;
  name;

  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirectUri: process.env.REACT_APP_AUTH0_CALLBACK,
    responseType: 'token id_token',
    scope: 'openid email profile '
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getName = this.getName.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.getCompanyCode = this.getCompanyCode.bind(this);
    this.getUserCode = this.getUserCode.bind(this);
    this.getPageAccess = this.getPageAccess.bind(this);
  }


  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;

    if (authResult.idTokenPayload != null) {
      this.name = authResult.idTokenPayload.name;
    }

    cookies.set('authObj', { accessToken: this.accessToken, idToken: this.idToken, expiresAt: this.expiresAt, name: this.name }, { path: '/' });

    // navigate to the home route
    if (history.location.hash.indexOf("access_token") > -1)
      history.replace('/');

  }

  login(callback) {
    //this.auth0.authorize();
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        callback()
      } else if (err) {
        this.auth0.authorize({ prompt: 'login' });
      }
    });
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        history.replace('/');
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  getAccessToken() {
    var c = cookies.get('authObj');
    if (c != null && c.accessToken != null) return c.accessToken; //this.accessToken;
    return "";
  }

  getIdToken() {
    var c = cookies.get('authObj');
    if (c != null && c.idToken != null) return c.idToken; //this.idToken;
    return "";
  }

  getName() {
    var c = cookies.get('authObj');
    if (c != null && c.name != null) return c.name; //this.idToken;
    return "";
  }

  setUserInfo(data) {
    cookies.set('userInfo', {
      companyCode: data.companycode,
      userCode: data.usercode,
      pageAccess: {
        bookingPage: data.viewBookingPage,
        markupPage: data.viewMarkupPage,
        messagePage: data.viewMessagePage,
        productPage: data.viewProductPage,
      },
    }, { path: '/' });
  }

  getCompanyCode() { // TO DO
    var c = cookies.get('userInfo');
    if (c != null && c.companyCode != null) return c.companyCode; //this.idToken;
    return "";
  }

  getUserCode() { // TO DO
    var c = cookies.get('userInfo');
    if (c != null && c.userCode != null) return c.userCode; //this.idToken;
    return "";
  }

  getPageAccess() { // TO DO
    var c = cookies.get('userInfo');
    if (c != null && c.pageAccess != null) return c.pageAccess; //this.idToken;
    return "";
  }

  renewSession(callback) {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        callback()
      } else if (err) {
        this.logout();
        console.log(err);
        alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
      }
    });
  }

  logout() {
    cookies.set('authObj', { accessToken: null, idToken: null, expiresAt: 0, name: "" }, { path: '/' });
    cookies.set('userInfo', { companyCode: null, userCode: null, pageAccess: null}, { path: '/' });

    this.auth0.logout({
      returnTo: window.location.origin
    });

    // navigate to the home route
    history.replace('/');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time

    var c = cookies.get('authObj');
    if (c != null && c.expiresAt != null) return new Date().getTime() < c.expiresAt;
    return false;
  }
}
