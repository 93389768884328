import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Row, Col, Badge, ListGroup, Form, Button, Card} from 'react-bootstrap';
import {Breadcrumbs, BreadcrumbItem,} from "sancho";
import moment from 'moment';
import cogoToast from 'cogo-toast';
import Pagination from '../components/Pagination';
import FileSaver from 'file-saver';
import Auth from '../Auth/Auth';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faCheck, faTimes, faDownload} from '@fortawesome/free-solid-svg-icons';

import _find from 'lodash/find';

import {prettyDateTime} from '../util/transformDate';
import {status} from '../util/constances';
import {getBookingList} from '../actions/booking';
import {clearSearchProductCriteria} from '../actions/product';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

class BookingListPage extends Component {
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onBookingClick = this.onBookingClick.bind(this);
    // this.onExportClcik = this.onExportClcik.bind(this);

    this.state = {
      searchStatus: '',
      searchActivityId: '',
      searchProductId: '',
      searchProductName: '',
      searchProductSySID: '',
      searchPage: 0,
    };
  }

  componentDidMount() {
    // this.props.clearSearchProductCriteria()
    if (!this.props.bookingList) {
      this.props.getBookingList(
        this.state.searchStatus,
        this.state.searchActivityId,
        this.state.searchProductId,
        this.state.searchProductName,
        this.state.searchProductSySID,
        0,
      )
    }
  }

  onPageChange(page) {
    this.setState({
      searchPage: page
    })
    this.props.getBookingList(
      this.state.searchStatus,
      this.state.searchActivityId,
      this.state.searchProductId,
      this.state.searchProductName,
      this.state.searchProductSySID,
      page
    )
  }

  onSearchClick() {
    this.onPageChange(0)
  }

  onExportClcik() {

    const endpoint = `${currentServerURL}/Report/RetrieveBkgList`;
    const body = {
      "bookingStatus": this.state.searchStatus ? this.state.searchStatus : '',
      "activityId": this.state.searchActivityId ? this.state.searchActivityId : '',
      "product_id": this.state.searchProductId ? this.state.searchProductId : '',
      "keywords": this.state.searchProductName ? this.state.searchProductName : '',
      "productSySID": this.state.searchProductSySID
    }

    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
        'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
      }
    }).then(res => {
        if (res.status === 401) {
          // cogoToast.info(`${res.status} + 'Error'`)
          cogoToast.error(<div>Error</div>, {hideAfter: 5})
        }
        return res.blob();
    }).then(
        rs => {
            var blob = new Blob([rs], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            if (
              this.state.currentTab === 'pendingBooking'
              || (this.state.currentTab !== 'pendingBooking' && blob.size !== 0)
            ) {
              FileSaver.saveAs(blob, `BookingList_${moment().format("YYYYMMDD")}.xls`);
            } else {
              cogoToast.info('No contents for this criteria.')
            }
        },
        er => {
            cogoToast.error(`Error:  + ${er}`)
        }
    )
    .catch((error) => {
      cogoToast.error(`search Report error: , ${error}`)
    });
  }

  onSearchInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
			this.onSearchClick()
    }
  }

  onBookingClick(id) {
    this.props.history.push(`booking/${id}`);
  }

  render () {
    return (
      <div>
        <title>Booking List | Product Booking System</title>
        <div className="container">
          <Breadcrumbs className="mt-1 mb-3">
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Booking List</BreadcrumbItem>
          </Breadcrumbs>

          <h1 className="text-center mb-4">Booking List</h1>
        </div>
        <div className="bg-grey p-4 mb-4" onKeyDown={this.onKeyDown}>
					<Card>
						<Card.Body>
              <Row>
                <Col lg="2">
                  Order ID
                  <Form.Control
                    type="text"
                    placeholder="Search Order ID"
                    onChange={(e) => this.onSearchInputChange(e)}
                    name="searchProductSySID"
                    value={this.state.searchProductSySID} />
                </Col>
                <Col lg="2">
                  Search Product
                  <Form.Control
                    type="text"
                    placeholder="Search Product"
                    onChange={(e) => this.onSearchInputChange(e)}
                    name="searchProductName"
                    value={this.state.searchProductName} />
                </Col>
                <Col lg="2">
                  Activity ID
                  <Form.Control
                    type="text"
                    placeholder="Search Activity ID"
                    onChange={(e) => this.onSearchInputChange(e)}
                    name="searchActivityId"
                    value={this.state.searchActivityId} />
                </Col>
                <Col lg="2">
                  Product ID
                  <Form.Control
                    type="text"
                    placeholder="Search Product ID"
                    onChange={(e) => this.onSearchInputChange(e)}
                    name="searchProductId"
                    value={this.state.searchProductId} />
                </Col>
                <Col lg="2">
                  Search Status
                  <Form.Control
                    as="select"
      							name="searchStatus"
                    onChange={(e) => this.onSearchInputChange(e)}
                    className="mb-3"
                    >
                      <option value="">ALL Status</option>
                      <option value="KK">Confirmed</option>
                      <option value="UC">Cancelled</option>
                      <option value="XX">Un-confirmed</option>
                      <option value="RE">Refund</option>
                      <option value="RQ">Request Send</option>
                  </Form.Control>
                </Col>
                <Col lg="2">
                  <Button onClick={() => this.onSearchClick()} className="btn-block mt-4"><FontAwesomeIcon icon={faSearch} className="mr-1" />Search</Button>
                </Col>
              </Row>
            </Card.Body>
					</Card>
        </div>
        <div className="container">
          {this.props.bookingList ?
            <div>
              <div className="small p-2">
                Total bookings: {this.props.bookingList.totalBkg}
                <Button onClick={() => this.onExportClcik()} className="ml-2" size="sm" disabled={this.props.bookingList.totalBkg === 0}><FontAwesomeIcon icon={faDownload} className="mr-1" />Export List</Button>
              </div>

              {this.props.bookingList.totalBkg > 0 ?
                <ListGroup variant="flush">
                  <Row className="mt-2 small px-3 py-1 font-weight-bold">
                    <Col xs="1">Order ID</Col>
                    <Col xs="4">Product</Col>
                    <Col >Booking by</Col>
                    <Col xs="2">Create date</Col>
                    <Col xs="1" className="text-lg-center">Status</Col>
                    <Col xs="1" className="text-lg-center">Noted by Fulfillment</Col>
                    <Col>Deal Price</Col>
                  </Row>

                  {this.props.bookingList.bkgList.map(record =>
                    <ListGroup.Item action key={record.productSySID} onClick={() => this.onBookingClick(record.productSySID)}>
                      <Row >
                        <Col xs="1">{record.productSySID}</Col>
                        <Col xs="4">
                          <div className="h5 mb-0">{record.activityName}</div>
                          <div>{record.productName}</div>
                        </Col>
                        <Col >{record.companycode} {record.usercode}</Col>
                        <Col xs="2">{prettyDateTime(record.createOn)}</Col>
                        <Col xs="1" className="text-lg-center">{_find(status, sta => sta.code === record.bookingStatus) ? <Badge variant={_find(status, sta => sta.code === record.bookingStatus).variant}>{_find(status, sta => sta.code === record.bookingStatus).name}</Badge> : record.bookingStatus}</Col>
                        <Col xs="1" className="text-lg-center"><FontAwesomeIcon icon={record.isFulfill ? faCheck : faTimes} className={record.isFulfill ? 'text-success' : 'text-danger'} /></Col>
                        <Col >{record.sellCurrency} ${record.sellTtlAmt}</Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                </ListGroup> :
                <div className="text-center no-result">No results.</div>
              }

              {this.props.bookingList.totalPage > 1 &&
                <Pagination
                  noOfPages={this.props.bookingList.totalPage}
                  currentPage={this.state.searchPage}
                  handlePageChange={this.onPageChange}
                />
              }
            </div>:
            <div className="p-5 text-center card">No Bookings.</div>
          }
        </div>
      </div>
    );
  }
}


BookingListPage.propTypes = {
	bookingList: PropTypes.object,
};

function mapStateToProps(state) {
	return {
    bookingList: state.booking.bookingList,
	}
}

const mapDispatchToProps = {
  getBookingList,
  clearSearchProductCriteria,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingListPage);
