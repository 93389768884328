import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Button, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faPlus} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap-daterangepicker/daterangepicker.css';

class BtnWithAmendmentNotes extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this)

		this.state = {
			value: '',
			isInvalid: false
		};
	}

	onFieldChange(e) {
		this.setState({
			value: e.target.value,
			isInvalid: false
		})
	}

	onBtnClick() {
		if (this.state.value === '') {
			this.setState({
				isInvalid: true
			})
		} else {
			this.props.handleClcik(this.state.value)
		}
	}

  render() {
		return (
			<div className={this.props.isSticky ? "sticky-bottom" : 'w-100'}>
				<Row className="justify-content-center">
					<Col lg={this.props.isSticky ? 3 : 6}>
						<Form.Control
							type="text"
							autoComplete="off"
							name="amendmentNotes"
							onChange={(e) => this.onFieldChange(e)}
							placeholder="Amendment Notes*"
							value={this.state.value}
							isInvalid={this.state.isInvalid}
							size={this.props.isSmall ? "sm" : ''} />
					</Col>
					<Col lg={`${this.props.isSticky ? "auto" : 6} ${this.props.isSmall ? "pl-0" : ''}`}>
						<Button
							variant="primary"
							onClick={() => this.onBtnClick()}
							className={this.props.isSticky ? '' : 'btn-block'}
							// disabled={this.props.isDisabled || !this.state.value}
							size={this.props.isSmall ? "sm" : ''}
						>
							{this.props.isAddIcon ? <FontAwesomeIcon icon={faPlus} className="mr-2" /> : <FontAwesomeIcon icon={faCheck} className="mr-2" />}{this.props.text}
						</Button>
					</Col>
				</Row>
			</div>
    );
  }
}

BtnWithAmendmentNotes.propTypes = {
	handleClcik: PropTypes.func,
	text: PropTypes.string,
	isSticky: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isAddIcon: PropTypes.bool,
	isSmall: PropTypes.bool,
};

export default BtnWithAmendmentNotes;
