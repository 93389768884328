import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Row, Col, ListGroup} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Breadcrumbs, BreadcrumbItem} from "sancho";
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';

import {viewAllProductMarkup} from '../actions/product';

import _chunk from 'lodash/chunk';
import _get from 'lodash/get';
const chunkSize = 20;

class MarkUpPage extends Component {
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.onMarkupClick = this.onMarkupClick.bind(this);
    this.onToogleAllMsg = this.onToogleAllMsg.bind(this);

    this.state = {
      currentPage: 0,
      isAllMsg: false,
    };
  }

  componentDidMount() {
    this.props.viewAllProductMarkup();
  }

  onPageChange(page) {
		this.setState({
			currentPage: page
		})
	}

  onMarkupClick(id, product_id) {
    this.props.history.push({
      pathname: `/product/${id}`,
      state: { product_id: product_id }
    });
  }

  onToogleAllMsg(bool) {
    this.setState({
      isAllMsg: bool,
      currentPage: 0
    })
  }

  render () {

    return (
      <div>
        <title>Markups | Product Booking System</title>

        <div className="container">

          <Breadcrumbs className="mt-1 mb-3">
            <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
            <BreadcrumbItem>Markups</BreadcrumbItem>
          </Breadcrumbs>

          <h1 className="text-center mb-5">Markups</h1>


          <Row className="justify-content-center">
            <Col>
              <ListGroup variant="flush">
                <ListGroup.Item >
                  <Row className="small font-weight-bold">
                    <Col className="text-center">ActivityId</Col>
                    <Col xs="2" >Product</Col>
                    <Col xs="2" >Item</Col>
                    <Col>SKU</Col>
                    <Col xs="2">Company Code</Col>
                    <Col>Currency</Col>
                    <Col>Price Above</Col>
                    <Col>Roundup</Col>
                    <Col>Markup % (percent)</Col>
                    <Col>Markup (amount)</Col>
                    <Col className="text-center">Enable</Col>
                  </Row>
                </ListGroup.Item>
                {this.props.allProductMarkups ?
                  _chunk(this.props.allProductMarkups, chunkSize)[this.state.currentPage].map((markup, i) =>
                    <ListGroup.Item action onClick={() => this.onMarkupClick(markup.ActivityId, markup.product_id)} key={markup.id} >
                      <Row>
                        <Col className="text-center">
                          {_get(markup, 'ActivityId')}
                        </Col>
                        <Col xs="2" >
                          {_get(markup, 'activityName')}
                        </Col>
                        <Col xs="2" >
                          {_get(markup, 'productname')}
                        </Col>
                        <Col>
                          {_get(markup, 'skuname' )}
                        </Col>
                        <Col xs="2">
                          {_get(markup, 'typecode')} - {_get(markup, 'typecodename')}
                        </Col>
                        <Col>
                          {_get(markup, 'CURR')}
                        </Col>
                        <Col>
                          {_get(markup, 'PRICEABOVE')}
                        </Col>
                        <Col>
                          {_get(markup, 'ROUNDUP')}
                        </Col>
                        <Col>
                          {_get(markup, 'R_MARKPERCENT')}
                        </Col>
                        <Col>
                          {_get(markup, 'R_MARKAMT')}
                        </Col>
                        <Col className="text-center">
                          <FontAwesomeIcon icon={_get(markup, 'isenable') ? faCheck : faTimes} className={_get(markup, 'isenable') ? 'text-success' : 'text-danger'} />
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    ): <div className="relative text-center p-5 card">No messages.</div>
                }
                {this.props.loading &&
                  <Loading isAbsolute />
                }
              </ListGroup>
            </Col>
          </Row>


  				{_chunk(this.props.allProductMarkups, chunkSize).length > 1 &&
  					<Pagination
  						handlePageChange={this.onPageChange}
  						noOfPages={_chunk(this.props.allProductMarkups, chunkSize).length}
  						currentPage={this.state.currentPage}
  					/>
  				}

        </div>

      </div>
    );
  }
}


MarkUpPage.propTypes = {
	myUnreadBookingMessage: PropTypes.array,
	allUnreadBookingMessage: PropTypes.array,
	allProductMarkups: PropTypes.array,
};

function mapStateToProps(state) {
	return {
    myUnreadBookingMessage: state.booking.myUnreadBookingMessage,
    allUnreadBookingMessage: state.booking.allUnreadBookingMessage,
    allProductMarkups: state.product.allProductMarkups,
	}
}

const mapDispatchToProps = {
  viewAllProductMarkup
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkUpPage);
