import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Auth from '../Auth/Auth';
import Select from 'react-select';

import _find from 'lodash/find';
import _map from 'lodash/map';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

const endpoint = `${currentServerURL}/Getcity`;
const method = 'POST'

class CitySelect extends Component {
	constructor(props) {
		super(props);
		this.onSelectClick = this.onSelectClick.bind(this);
		this.getCityList = this.getCityList.bind(this);

		this.state = {
			options: [],
			isSearching: false,
			currentObj: '',
		};
	}

	componentDidMount() {
		this.getLabel(this.props.selectedCode)
	}

	componentDidUpdate(prevProps) {
		if(this.props.countryId && (!prevProps.selectedCode && this.props.selectedCode)) {
			this.getLabel(this.props.selectedCode)
		}
		if(prevProps.countryId && (prevProps.countryId !== this.props.countryId)) {
			this.clearLoginOptions()
		}
	}

	getCityList() {
    const body = {
		  "countryID": this.props.countryId,
			"keywords": "",
		}

		this.setState({
			isSearching: true
		})

		fetch(endpoint, {
			method: method,
			body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			},
		})
			.then(response => response.json())
			.then((result) => {
				if (result.responseSuccess && result.cityList.length > 0) {
					let options = _map(result.cityList, (r) => ({
						'value': r.CITYCODE,
						'label': r.CITYNAME
					}))

					this.setState({
						options: options,
						isSearching: false,
					})

				} else {
					this.setState({
						isSearching: false
					})
				}
			})
			.catch((error) => {
				console.log('error: ', error)
				this.setState({
					isSearching: false
				})
			});
	}


	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.props.handleSelect(this.props.name, selectedOption.value, this.props.index)
			this.setState({
				currentObj: selectedOption
			})
		} else { // clear
			this.clearLoginOptions()
		}
	}

	getLabel(string) {
		if (string) {
			const body = {
				"countryID": this.props.countryId,
				"keywords": string,
			}

			fetch(endpoint, {
				method: method,
				body: JSON.stringify(body),
				headers: {
					Authorization: 'Bearer ' + auth.getAccessToken(),
					'Content-Type': 'application/json'
				}
			})
				.then(response => response.json())
				.then((result) => {
					if (result.responseSuccess && result.cityList.length > 0) {
						let options = _map(result.cityList, (r) => ({
							'value': r.CITYCODE,
							'label': r.CITYNAME
						}))
						this.setState({
							currentObj: _find(options, {'value': string})
						})
					}
				})
				.catch((error) => {
					console.log('error: ', error)
				});
		}

	}

	clearLoginOptions() {
		this.setState({
			currentObj: ''
		});
		this.props.handleSelect(this.props.name, '', this.props.index)
	}

  render() {
		return (
			<Select
				name={this.props.name}
				value={this.state.currentObj}
				onChange={this.onSelectClick}
				options={this.state.options ? this.state.options : []}
				placeholder="Select City"
				isLoading={this.state.isSearching}
				onMenuOpen={this.getCityList}
				className={`select-control ${this.props.isInvalid ? 'invalid' : ''}`}
				isClearable={this.props.isClearable}
				isDisabled={this.props.isDisabled}
			/>
    );
  }
}

CitySelect.propTypes = {
	handleSelect: PropTypes.func,
	selectedCode: PropTypes.string,
	countryId: PropTypes.string,
	name: PropTypes.string,
	isInvalid: PropTypes.bool,
	index: PropTypes.number,
	isClearable: PropTypes.bool,
	isDisabled: PropTypes.bool,
}

export default CitySelect;
