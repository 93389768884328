import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Table} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt, faPlus} from '@fortawesome/free-solid-svg-icons';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _find from 'lodash/find';
import _map from 'lodash/map';
import BtnWithAmendmentNotes from '../../../components/BtnWithAmendmentNotes';
import AddExtraInfoModal from '../../../components/productDetailPage/AddExtraInfoModal';
import DeleteModal from '../../../components/productDetailPage/DeleteModal';

class ExtraInfo extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.onToggleAddModal = this.onToggleAddModal.bind(this);
		this.onToggleDeleteModal = this.onToggleDeleteModal.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
		this.onSubmitChangeClick = this.onSubmitChangeClick.bind(this);

		this.state = {
			extraInfoList: [],
			editExtraInfo: null,
			isAddModleOpen: false,
			isDeleteModalOpen: false,
			extraInfoTobeDelete: null,
		};
	}

	componentDidMount() {
		this.setList()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.updateItemSuccessTimStamp !== this.props.updateItemSuccessTimStamp) {
      this.onToggleAddModal(false)
      this.onToggleDeleteModal(false)
    }

		if (prevProps.extraInfoList !== this.props.extraInfoList) {
			this.setList()
		}
	}

	setList() {
		this.setState({
			extraInfoList: this.props.extraInfoList
		})
	}

	onFieldChange(e, i) {
		const deep = _cloneDeep(this.state.extraInfoList)
		if (e.target.name === 'isrequired') {
			_set(deep, [i, e.target.name], e.target.checked)
		} else {
			_set(deep, [i, e.target.name], e.target.value)
		}

		this.setState({
			extraInfoList: deep
		})
	}

	onAddClick() {
		this.onToggleAddModal(true)
	}

	onToggleAddModal(bool) {
		this.setState({
			isAddModleOpen: bool
		})
	}

	onToggleDeleteModal(bool, id) {
		this.setState({
			isDeleteModalOpen: bool,
			extraInfoTobeDelete: bool ? id : null,
		})
	}

	onDeleteClick() {
		this.props.handleDelete(this.props.productId, this.state.extraInfoTobeDelete)
	}

	onSubmitChangeClick(amendmentNotes) {
		this.props.handleUpdate(_map(this.state.extraInfoList, extraInfo => Object.assign({'amendmentNotes': amendmentNotes}, extraInfo)))  // include amendmentNotes
  }

  render() {
		return (
			<div>

				<div className="text-right pb-3">
					<Button onClick={() => this.onAddClick()} variant="outline-primary"><FontAwesomeIcon icon={faPlus} className="mr-2" />Add Extra Info</Button>
				</div>

				{this.state.extraInfoList.length > 0 ?
					<div>
						<Table striped bordered>
							<thead>
								<tr>
									<th>Name*</th>
									<th>Hints*</th>
									<th>Type</th>
									<th>Value form</th>
									<th className="text-center">Required</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{this.state.extraInfoList.map((etif, i) =>
									<tr key={i}>
										<td><Form.Control
											type="text"
											autoComplete="off"
											name="infoRemark1"
											onChange={(e) => this.onFieldChange(e, i)}
											value={etif.infoRemark1} /></td>
										<td><Form.Control
											type="text"
											autoComplete="off"
											name="infoRemark2"
											onChange={(e) => this.onFieldChange(e, i)}
											value={etif.infoRemark2} /></td>
										<td><Form.Control
											as="select"
											name="infoType"
											onChange={(e) => this.onFieldChange(e, i)}
											value={etif.infoType}
										>
											<option value="general">general</option>
											<option value="travelers">travelers</option>
										</Form.Control></td>
										<td><Form.Control
												as="select"
												name="infovalue"
												onChange={(e) => this.onFieldChange(e, i)}
												value={etif.infovalue}
											>
												<option value="text">text</option>
												<option value="list">list</option>
											</Form.Control></td>
										<td className="text-center"><Form.Check
											type="checkbox"
											name="isrequired"
											className="mr-0"
											onChange={(e) => this.onFieldChange(e, i)}
											checked={etif.isrequired} /></td>
										<td className="text-center"><Button variant="outline-danger" onClick={() => this.onToggleDeleteModal(true, etif.infoId)}><FontAwesomeIcon icon={faTrashAlt} /></Button></td>
									</tr>
								)}

							</tbody>
						</Table>
						<BtnWithAmendmentNotes
							text="Save Extra Info Change"
							isSticky
							handleClcik={this.onSubmitChangeClick}
						/>
					</div>:
					<div className="text-center card text-muted p-5 pm">No Extra Infos.</div>
				}



				<AddExtraInfoModal
					handleClose={this.onToggleAddModal}
					handleConfirm={this.props.handleCreate}
					show={this.state.isAddModleOpen}
					productID={this.props.productId}
					itemTitle={this.props.itemTitle}
				/>

				<DeleteModal
					show={this.state.isDeleteModalOpen}
					handleConfirm={this.onDeleteClick}
					handleClose={() => this.onToggleDeleteModal(false)}
					description={this.state.isDeleteModalOpen ? `Extra Info: ${_find(this.props.extraInfoList, {infoId: this.state.extraInfoTobeDelete}).infoRemark1}` : ''}
				/>
			</div>
    );
  }
}

ExtraInfo.propTypes = {
	extraInfoList: PropTypes.array,
	handleUpdate: PropTypes.func,
	handleDelete: PropTypes.func,
	handleCreate: PropTypes.func,
	isComponentLoading: PropTypes.bool,
	updateItemSuccessTimStamp: PropTypes.number,
	productId: PropTypes.string,
	itemTitle: PropTypes.string,
}

export default ExtraInfo;
