import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Col, Form} from 'react-bootstrap';

import _clone from 'lodash/clone';
import _set from 'lodash/set';
import _findIndex from 'lodash/findIndex';

class AddTravelerInfoModal extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

		this.state = {
			form: null
		};
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.show && this.props.show) {
			this.resetForm()
		}
	}

	resetForm() {
		const form = []
		for (let i = 0; i < this.props.travelerSample.length; i++ ) {
			form.push({
				"orderid": this.props.orderid,
				"InfoType": "travelers",
				"typeId": this.props.travelerSample[i].typeId,
				"value": "",
				"remark1": this.props.travelerSample[i].remark1
			})
		}

		this.setState({
			form: form
		})
	}

	onFieldChange(e) {
		const typeId = e.target.name
		const deep = _clone(this.state.form)
		_set(deep, [_findIndex(this.state.form, {typeId: typeId}), 'value'], e.target.value)

		this.setState({
			form: deep
		})
	}

	onAddClick() {
		this.props.handleConfirm(this.state.form)
	}

	onKeyDown(event) {
    if (event.key === "Enter") {
			this.onAddClick()
    }
  };

  render() {
		return (
			<Modal centered show={this.props.show} onHide={() => this.props.handleClose(false)} onKeyDown={this.onKeyDown}>
				<Modal.Header>
					<Modal.Title>Add Traveler</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.state.form &&
					<div>
						{this.state.form.map(info =>
							<Form.Group as={Col} controlId={info.typeId} key={info.typeId}>
								<Form.Label >{info.remark1}</Form.Label>
								<Form.Control
									type="text"
									autoComplete="off"
									name={info.typeId}
									onChange={(e) => this.onFieldChange(e)}
									value={info.value} />
							</Form.Group>
						)}
					</div>}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={this.onAddClick}>
						Add Traveler
					</Button>
				</Modal.Footer>
			</Modal>
	  );
	}
}

AddTravelerInfoModal.propTypes = {
	handleClose: PropTypes.func,
	handleConfirm: PropTypes.func,
	show: PropTypes.bool,
	orderid: PropTypes.string,
	travelerSample: PropTypes.array,
}

export default AddTravelerInfoModal;
