import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Row, Col, Form} from 'react-bootstrap';
import CurrencySelect from '../../components/CurrencySelect';

class AddSkuModal extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);

		this.state = {
				"orderid": this.props.orderid,
        "skuName": "",
        "itemStatus": "RQ",
        "quantity": 1,
        "itemRef":"",
        "sellTtlAmt": 0,
        "sellCurrency": "HKD",
        "costTtlamt": 0,
        "costCurrency": "HKD",
        "netnetTtlamt": 0,
        "netnetCurrency": "HKD"
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.show && !this.props.show) {
			this.resetForm()
		}
	}

	resetForm() {
		this.setState({
			"orderid": this.props.orderid ? this.props.orderid : "",
			"skuName": "",
			"itemStatus": "RQ",
			"quantity": 1,
			"itemRef":"",
			"sellTtlAmt": 0,
			"sellCurrency": "HKD",
			"costTtlamt": 0,
			"costCurrency": "HKD",
			"netnetTtlamt": 0,
			"netnetCurrency": "HKD"
		})
	}

	onFieldChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onAddClick() {
		this.props.handleConfirm(this.state)
	}

	onSelectChange(name, value) {
		this.setState({
			[name]: value
		})
	}

	onKeyDown(event) {
    if (event.key === "Enter") {
			this.onAddClick()
    }
  };

  render() {
		return (
			<Modal centered show={this.props.show} onHide={() => this.props.handleClose(false)} onKeyDown={this.onKeyDown}>
				<Modal.Header>
					<Modal.Title>Add SKU</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Row className="mb-3">
							<Col lg="5">Name</Col>
							<Col><Form.Control
							type="text"
							autoComplete="off"
							name="skuName"
							onChange={(e) => this.onFieldChange(e)}
							value={this.state.skuName} /></Col>
						</Row>
						<Form.Group as={Row} >
							<Form.Label column lg="5">Quantity</Form.Label>
							<Col><Form.Control
							type="text"
							autoComplete="off"
							name="quantity"
							onChange={(e) => this.onFieldChange(e)}
							value={this.state.quantity} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Item Ref</Form.Label>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="itemRef"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.itemRef} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Sell</Form.Label>
							<Col>
								<CurrencySelect
									handleSelect={this.onSelectChange}
									selectedCode={this.state.sellCurrency}
									name="sellCurrency"
								/>
							</Col>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="sellTtlAmt"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.sellTtlAmt} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Cost</Form.Label>
							<Col>
								<CurrencySelect
									handleSelect={this.onSelectChange}
									selectedCode={this.state.costCurrency}
									name="costCurrency"
								/>
							</Col>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="costTtlamt"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.costTtlamt} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Net Net</Form.Label>
							<Col>
								<CurrencySelect
									handleSelect={this.onSelectChange}
									selectedCode={this.state.netnetCurrency}
									name="netnetCurrency"
								/>
							</Col>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="netnetTtlamt"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.netnetTtlamt} /></Col>
						</Form.Group>


					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={this.onAddClick} size="lg" className="btn-block" disabled={!this.state.skuName}>
						Add SKU
					</Button>
				</Modal.Footer>
			</Modal>
	  );
	}
}

AddSkuModal.propTypes = {
	handleClose: PropTypes.func,
	handleConfirm: PropTypes.func,
	show: PropTypes.bool,
	orderid: PropTypes.string,
}

export default AddSkuModal;
