import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {Form, Col, Row, Card} from 'react-bootstrap';
import ReactModal from 'react-modal-resizable-draggable';
import Sticky from 'react-stickynode';
import CustomDateRangePicker from '../components/CustomDateRangePicker';
import Loading from '../components/Loading';
import BtnWithAmendmentNotes from '../components/BtnWithAmendmentNotes';

import {getDailyRates, updateDailyRates} from '../actions/product';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _split from 'lodash/split';
import _findIndex from 'lodash/findIndex';
import _concat from 'lodash/concat';
import _without from 'lodash/without';
import _toNumber from 'lodash/toNumber';
import _includes from 'lodash/includes';

let activityId
let productId

class PricingPage extends Component {
  constructor(props) {
    super(props);

    this.onEventClick = this.onEventClick.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onBulkSelectChange = this.onBulkSelectChange.bind(this);
    this.onBulkEditDateChange = this.onBulkEditDateChange.bind(this);
    this.onBulkEditValueChange = this.onBulkEditValueChange.bind(this);
    this.onBulkEditTitleChange = this.onBulkEditTitleChange.bind(this);
    this.onBulkChangeClick = this.onBulkChangeClick.bind(this);
    this.nextMonthClick = this.nextMonthClick.bind(this);
    this.prevMonthClick = this.prevMonthClick.bind(this);
    this.nextYearClick = this.nextYearClick.bind(this);
    this.prevYearClick = this.prevYearClick.bind(this);
    this.todayClick = this.todayClick.bind(this);
    this.onDayOfWeekCelect = this.onDayOfWeekCelect.bind(this);

    this.state = {
      events: null,
      selectediDate: null,
      selectedObject: null,
      clientX: 0,
      clientY: 0,
      bulkEditStartDate: '',
      bulkEditEndDate: '',
      bulkEditSkuId: '-',
      bulkEditKey: 'amount',
      bulkEditValue: '',
      currentMonth: moment().get('month'),
      currentYear: moment().get('year'),
      dayOfWeek: [0,1,2,3,4,5,6]
    };
  }

  componentDidMount() {
    activityId = _split(this.props.match.params.id, '-')[0]
    productId = _split(this.props.match.params.id, '-')[1]

    this.props.getDailyRates(activityId, productId, this.state.currentYear, this.state.currentMonth)
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.dailyRates !== this.props.dailyRates) && this.props.dailyRates.DailyList ) {
      this.setRates()
    }

    if (!prevProps.updateDailyRateSuccessTimStamp && this.props.updateDailyRateSuccessTimStamp) {

      if (this.state.selectedObject) {
        this.onCloseModal()
      }

      activityId = _split(this.props.match.params.id, '-')[0]
      productId = _split(this.props.match.params.id, '-')[1]

      this.props.getDailyRates(activityId, productId, this.state.currentYear, this.state.currentMonth)
    }

    if (prevProps.updateDailyRateSuccessTimStamp && !this.props.updateDailyRateSuccessTimStamp) {
      this.setRates()
    }

    if (prevState.currentMonth !== this.state.currentMonth || prevState.currentYear !== this.state.currentYear) {
      this.props.getDailyRates(activityId, productId, this.state.currentYear, this.state.currentMonth)
    }
  }

  onEventClick(info) {
    const deep = _cloneDeep(this.state.events)
    _set(deep, [_findIndex(this.state.events, {id: info.event.id}), 'backgroundColor'], '#c9e9ef')

    this.setState({
      selectediDate: moment(info.event.start).format('YYYYMMDD'),
      selectedObject: info.event.extendedProps,
      clientX: info.jsEvent.clientX,
      clientY: info.jsEvent.clientY,
      events: deep
    })
  }

  onFieldChange(e) {
    const deep = _cloneDeep(this.state.selectedObject)
    _set(deep, ['Details', 0, e.target.name], e.target.value)

    this.setState({
      selectedObject: deep
    })
  }

  onCheckChange(e) {
    const deep = _cloneDeep(this.state.selectedObject)
    _set(deep, ['Details', 0, e.target.name], e.target.checked)

    this.setState({
      selectedObject: deep
    })
  }

  onCloseModal() {
    const deep = _cloneDeep(this.state.events)
    _set(deep, [_findIndex(this.state.events, {id: `${this.state.selectedObject.idate}-${this.state.selectedObject.Details[0].sku_id}`}), 'backgroundColor'], 'transparent')

    this.setState({
      selectediDate: null,
      selectedObject: null,
      events: deep,
    })
  }

  onSubmitClick(amendmentNotes) {
    this.props.updateDailyRates([this.state.selectedObject], amendmentNotes) // include amendmentNotes
  }

  onBulkSelectChange(e) {
    this.setState({
      bulkEditKey: e.target.value,
      bulkEditValue: e.target.value === 'isAllot' ||  e.target.value === 'isBlackout' ? false : '',
    })
  }

  onBulkEditTitleChange(e) {
    this.setState({
      bulkEditSkuId: e.target.value
    })
  }

  onBulkEditValueChange(e) {
    this.setState({
      bulkEditValue: this.state.bulkEditKey === 'isAllot' || this.state.bulkEditKey === 'isBlackout' ? e.target.checked : e.target.value,
    })
  }

  onBulkEditDateChange(startDate, endDate, name) {
    this.setState({
      bulkEditStartDate: startDate,
      bulkEditEndDate: endDate,
    })
  }

  onBulkChangeClick(amendmentNotes) {
    var noOfDays = moment(this.state.bulkEditEndDate).diff(moment(this.state.bulkEditStartDate), 'days') + 1

    let updateDailyRate = [];
    for (let i = 0; i < noOfDays; i++ ) {
      var day = moment(this.state.bulkEditStartDate).add(i, 'days').day()
      if (_includes(this.state.dayOfWeek, day)) {
        updateDailyRate.push({
          idate: moment(this.state.bulkEditStartDate).add(i, 'days').format('YYYYMMDD'),
          Details: [{
            [this.state.bulkEditKey]: this.state.bulkEditValue,
            "sku_id": this.state.bulkEditSkuId
          }]
        })
      }
    }

    this.props.updateDailyRates(updateDailyRate, amendmentNotes)  // include amendmentNotes
  }

  nextMonthClick() {
    if (this.state.currentMonth !== 11) {
      this.setState({
        currentMonth: this.state.currentMonth + 1
      })
    } else {
      this.setState({
        currentMonth: 0,
        currentYear: this.state.currentYear + 1
      })
    }

    let calendarApi = this.calendarRef
    calendarApi.calendar.next()
  }

  prevMonthClick() {
    if (this.state.currentMonth !== 0) {
      this.setState({
        currentMonth: this.state.currentMonth - 1
      })
    } else {
      this.setState({
        currentMonth: 11,
        currentYear: this.state.currentYear - 1
      })
    }

    let calendarApi = this.calendarRef
    calendarApi.calendar.prev()
  }

  nextYearClick() {
    this.setState({
      currentYear: this.state.currentYear + 1
    })

    let calendarApi = this.calendarRef
    calendarApi.calendar.nextYear()
  }

  prevYearClick() {
    this.setState({
      currentYear: this.state.currentYear - 1
    })

    let calendarApi = this.calendarRef
    calendarApi.calendar.prevYear()
  }

  todayClick() {
    this.setState({
      currentMonth: moment().get('month'),
      currentYear: moment().get('year'),
    })

    let calendarApi = this.calendarRef
    calendarApi.calendar.today()
  }

  setRates() {
    let events = []
    this.props.dailyRates.DailyList.map((day, i) =>
      day.Details.map((sku) =>
        events.push({
          title: `${sku.title} <span class="font-weight-bold">$${sku.amount}</span> <span class="badge badge-primary allot">${sku.allotqty ? sku.allotqty : '-'}</span> <span class="badge badge-warning allotused">${sku.allotused ? sku.allotused : '-'}</span>${sku.isAllot ? ' <span class="badge badge-danger allot">A</span>' : ''}`,
          start: `${day.idate.slice(0,4)}-${day.idate.slice(4,6)}-${day.idate.slice(6)}`,
          backgroundColor: sku.isBlackout ? '#c3c3c3' : 'transparent',
          id: `${day.idate}-${sku.sku_id}`,
          extendedProps: {
            idate: day.idate,
            Details: [sku]
          },
        })
      )
    )

    this.setState({
      events: events
    })
  }

  onDayOfWeekCelect(e) {
    var value = e.target.value

    if (this.state.dayOfWeek.length === 1 && !e.target.checked) {
      this.setState({
        dayOfWeek: [0,1,2,3,4,5,6]
      })
    } else {
      this.setState({
        dayOfWeek: e.target.checked ? _concat(this.state.dayOfWeek, _toNumber(value)) : _without(this.state.dayOfWeek, _toNumber(value))
      })
    }
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
			this.onSubmitClick()
    }
  };

  handleEvent(info) {
    info.el.firstChild.innerHTML = `<span className="fc-title">${info.event.title}</span>`;
  }

  render () {
    return (
      <div>
        <title>Pricing Table | Product Management System</title>
        <div>
          <h1 className="text-center">Pricing Table</h1>
          {this.props.dailyRates &&
            <h2 className="text-center">{this.props.dailyRates.productTitle} | {this.props.dailyRates.itemTitle}</h2>
          }

          {this.props.dailyRates && this.props.dailyRates.DailyList &&
            <div className="mt-3 mb-5">
              <Sticky enabled>
                <div className="bg-grey p-4">
                  <h3>Bulk Edit</h3>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          Date period*
                          <CustomDateRangePicker
                            name="bulkEditDate"
                            handleSelect={this.onBulkEditDateChange}
                            startDateValue={this.state.bulkEditStartDate}
                            endDateValue={this.state.bulkEditEndDate}
                          />
                        </Col>
                        <Col lg="auto">
                          <div>
                            Day of week
                          </div>

                          <div className="inline">
                            <Form.Group controlId="mon" className="mb-0" >
                              <Form.Check
                                type="checkbox"
                                name="mon"
                                label="Mon"
                                value={1}
                                onChange={(e) => this.onDayOfWeekCelect(e)}
                                checked={this.state.dayOfWeek.includes(1)} />
                            </Form.Group>
                          </div>

                          <div className="inline">
                            <Form.Group controlId="tue" className="mb-0" >
                              <Form.Check
                                type="checkbox"
                                name="tue"
                                label="Tue"
                                value={2}
                                onChange={(e) => this.onDayOfWeekCelect(e)}
                                checked={this.state.dayOfWeek.includes(2)} />
                            </Form.Group>
                          </div>

                          <div className="inline">
                            <Form.Group controlId="wed" className="mb-0" >
                              <Form.Check
                                type="checkbox"
                                name="wed"
                                label="Wed"
                                value="3"
                                onChange={(e) => this.onDayOfWeekCelect(e)}
                                checked={this.state.dayOfWeek.includes(3)} />
                            </Form.Group>
                          </div>

                          <div className="inline">
                            <Form.Group controlId="thu" className="mb-0" >
                              <Form.Check
                                type="checkbox"
                                name="thu"
                                label="Thu"
                                value="4"
                                onChange={(e) => this.onDayOfWeekCelect(e)}
                                checked={this.state.dayOfWeek.includes(4)} />
                            </Form.Group>
                          </div>

                          <div className="inline">
                            <Form.Group controlId="fri" className="mb-0" >
                              <Form.Check
                                type="checkbox"
                                name="fri"
                                label="Fri"
                                value="5"
                                onChange={(e) => this.onDayOfWeekCelect(e)}
                                checked={this.state.dayOfWeek.includes(5)} />
                            </Form.Group>
                          </div>

                          <div className="inline">
                            <Form.Group controlId="sat" className="mb-0" >
                              <Form.Check
                                type="checkbox"
                                name="sat"
                                label="Sat"
                                value="6"
                                onChange={(e) => this.onDayOfWeekCelect(e)}
                                checked={this.state.dayOfWeek.includes(6)} />
                            </Form.Group>
                          </div>

                          <div className="inline">
                            <Form.Group controlId="sun" className="mb-0" >
                              <Form.Check
                                type="checkbox"
                                name="sun"
                                label="Sun"
                                value="0"
                                onChange={(e) => this.onDayOfWeekCelect(e)}
                                checked={this.state.dayOfWeek.includes(0)} />
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          Title*
                          <Form.Control
                            as="select"
                            name="bulkEditSkuId"
                            value={this.state.bulkEditSkuId}
                            onChange={(e) => this.onBulkEditTitleChange(e)}
                          >
                            <option value="-">-</option>
                            {this.props.dailyRates.DailyList.length > 0 && this.props.dailyRates.DailyList[0].Details.map(sku =>
                              <option key={sku.sku_id} value={sku.sku_id}>{sku.title}</option>
                            )}
                          </Form.Control>
                        </Col>
                        <Col>
                          Change Value
                          <Form.Control
                            as="select"
                            onChange={(e) => this.onBulkSelectChange(e)}
                            value={this.state.bulkEditKey}
                          >
                            <option value="amount">Amount</option>
                            <option value="allotqty">Allot QTY</option>
                            <option value="isAllot">is Allot</option>
                            <option value="isBlackout">is Blackout</option>
                          </Form.Control>
                        </Col>
                        <Col>
                          Change To
                          {this.state.bulkEditKey === 'isAllot' || this.state.bulkEditKey === 'isBlackout' ?
                            <div><Form.Check
                              type="checkbox"
                              name={this.state.bulkEditKey}
                              onChange={(e) => this.onBulkEditValueChange(e)}
                              checked={this.state.bulkEditValue} /></div>:
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              name={this.state.bulkEditKey}
                              onChange={(e) => this.onBulkEditValueChange(e)}
                              value={this.state.bulkEditValue} />
                          }
                        </Col>
                        <Col lg="5" className="mt-4 border-left">
                          <BtnWithAmendmentNotes
                            text="Bulk Change"
                            handleClcik={this.onBulkChangeClick}
                            isDisabled={this.state.bulkEditSkuId === '-' || this.state.bulkEditStartDate === ''}
                          />
                        </Col>
                      </Row>

                    </Card.Body>
                  </Card>
                </div>
              </Sticky>
            </div>
          }
        </div>
        <div className="container-fluid">

          <div className="text-right h5 mb-4">
            <span className="badge badge-primary">&nbsp;&nbsp;</span> = allot
            <span className="badge badge-warning ml-3">&nbsp;&nbsp;</span> = allot used
            <span className="badge badge-danger ml-3">&nbsp;A&nbsp;</span> = is Allot
            <span className="badge ml-3" style={{backgroundColor: 'rgb(195, 195, 195)'}}>&nbsp;Title&nbsp;</span> = Blackout
          </div>

          <FullCalendar
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin]}
            eventClick={(info) => {
              if (!info.event.extendedProps.Details[0].isBlackout) {
                this.onEventClick(info)
              }
            }}
            eventMouseEnter={(mouseEnterInfo) => {
              if (!mouseEnterInfo.event.extendedProps.Details[0].isBlackout) {
                mouseEnterInfo.el.style.backgroundColor = '#c9e9ef';
                mouseEnterInfo.el.style.cursor = 'pointer';
              } else {
                mouseEnterInfo.el.style.cursor = 'not-allowed';
              }
            }}
            eventMouseLeave={(mouseEnterInfo) => {
              if (!mouseEnterInfo.event.extendedProps.Details[0].isBlackout) {
                mouseEnterInfo.el.style.backgroundColor = 'transparent ';
              }
            }}
            events={this.state.events}
            ref={ref => this.calendarRef = ref}
            customButtons={{
              myCustomButtonPrevYear: {
                text: '<< Prev Year',
                click: () => this.prevYearClick()
              },
              myCustomButtonNextYear: {
                text: 'Next Year >>',
                click: () => this.nextYearClick()
              },
              myCustomButtonPrev: {
                text: '< Prev Month',
                click: () => this.prevMonthClick()
              },
              myCustomButtonNext: {
                text: 'Next Month >',
                click: () => this.nextMonthClick()
              },
              myCustomButtonToday: {
                text: 'Today',
                click: () => this.todayClick()
              },
            }}
            header={{
              left: 'title',
              right: 'myCustomButtonToday myCustomButtonPrevYear,myCustomButtonNextYear myCustomButtonPrev,myCustomButtonNext'
            }}
            noEventsMessage={"No Pricing to display"}
            eventRender={(info) => {
              this.handleEvent(info)
            }}
          />

          {this.state.selectediDate &&
            <ReactModal
              initWidth={230}
              initHeight={250}
              top={this.state.clientY}
              left={this.state.clientX}
              disableResize
              className={"change-price-popover"}
              onRequestClose={this.onCloseModal}
              isOpen={this.state.selectedObject}
              >
                <div onKeyDown={this.onKeyDown}>
                  <div className="change-price-popover--header">{moment(this.state.selectediDate).format('YYYY-MM-DD')} {this.state.selectedObject.Details[0].title}</div>
                  <Card.Body className="p-2 small">
                    <Row className="mb-1">
                      <Form.Label column xs="5" className="text-right">Amount</Form.Label>
                      <Col className="pl-0">
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          name="amount"
                          onChange={(e) => this.onFieldChange(e)}
                          value={this.state.selectedObject.Details[0].amount}
                          size="sm" />
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <Form.Label column xs="5" className="text-right">Allot QTY</Form.Label>
                      <Col className="pl-0">
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          name="allotqty"
                          onChange={(e) => this.onFieldChange(e)}
                          value={this.state.selectedObject.Details[0].allotqty}
                          size="sm" />
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <Col xs="5" className="text-right">Allot Used</Col>
                      <Col className="pl-0">
                        {this.state.selectedObject.Details[0].allotused ? this.state.selectedObject.Details[0].allotused : '-'}
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <Form.Label column xs="5" className="text-right">is Allot</Form.Label>
                      <Col className="pl-0">
                        <Form.Check
                          type="checkbox"
                          name="isAllot"
                          onChange={(e) => this.onCheckChange(e)}
                          checked={this.state.selectedObject.Details[0].isAllot}
                          size="sm" />
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <Form.Label column xs="5" className="text-right">is Blackout</Form.Label>
                      <Col className="pl-0">
                        <Form.Check
                          type="checkbox"
                          name="isBlackout"
                          onChange={(e) => this.onCheckChange(e)}
                          checked={this.state.selectedObject.Details[0].isBlackout}
                          size="sm" />
                      </Col>
                    </Row>

                    <BtnWithAmendmentNotes
                      text="Change!"
                      handleClcik={this.onSubmitClick}
                      isSmall
                    />
                  </Card.Body>
                </div>
            </ReactModal>
          }
        </div>
        {this.props.loading &&
          <Loading isAbsolute />
        }
      </div>
    );
  }
}


PricingPage.propTypes = {
	dailyRates: PropTypes.object,
	updateDailyRateSuccessTimStamp: PropTypes.number,
	loading: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
    dailyRates: state.product.dailyRates,
    updateDailyRateSuccessTimStamp: state.product.updateDailyRateSuccessTimStamp,
    loading: state.loading.pageLoading,
	}
}

const mapDispatchToProps = {
  getDailyRates,
  updateDailyRates,
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingPage);
