import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Form} from 'react-bootstrap';
import MarkdownEditor from '../../../components/MarkdownEditor';
import BtnWithAmendmentNotes from '../../../components/BtnWithAmendmentNotes';
import _extend from 'lodash/extend';

class NewItem extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onMarkdownChange = this.onMarkdownChange.bind(this);
		this.onSaveItemClick = this.onSaveItemClick.bind(this);
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);

		this.state = {
			form: {
				activityId: this.props.activityId,
				title: '',
				detail: '',
				confirmationType: '',
				confirmationDetails: '',
				voucherUsage: '',
				voucherTypeDesc: '',
		    productMinPax: "0",
		    productMaxPax: "9999",
			},
			isAddSkuModleOpen: false,
			isDeleteModalOpen: false,
		};
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {
	}

	onFieldChange(e) {
		this.setState({
			form: _extend(this.state.form, {
				[e.target.name]: e.target.value
			})
		})
	}

  onMarkdownChange(name, value) {
    this.setState({
      form: _extend(this.state.form, {
        [name]: value
      })
    })
  }

  onSaveItemClick(amendmentNotes) {
		this.props.handleCreateItem(Object.assign({'amendmentNotes': amendmentNotes}, this.state.form)) // include amendmentNotes
  }

	toggleDeleteModal(bool) {
		this.setState({
			isDeleteModalOpen: bool
		})
	}

  render() {
		return (
			<Form>
				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-right">Item Title *</Form.Label>
					<Col>
						<Form.Control
							type="text"
							autoComplete="off"
							name={`title`}
							onChange={(e) => this.onFieldChange(e)}
							value={this.state.form.title} />
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-right">Detail</Form.Label>
					<Col>
						<MarkdownEditor
							name="detail"
							handleSetValue={this.onMarkdownChange}
							value={this.state.form.detail} />
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-right">SKU</Form.Label>
					<Form.Label column className="text-muted">(Please create Item first)</Form.Label>
				</Form.Group>

				<Form.Group as={Row} controlId={`extraInfo-${this.state.form.productId}`}>
					<Form.Label column lg="3" className="text-right">Extra Info</Form.Label>
					<Form.Label column className="text-muted">(Please create Item first)</Form.Label>
				</Form.Group>

				<Form.Group as={Row} controlId={`voucherUsage-${this.state.form.productId}`}>
					<Form.Label column lg="3" className="text-right">Voucher Usage</Form.Label>
					<Col>
						<MarkdownEditor
							name="voucherUsage"
							handleSetValue={this.onMarkdownChange}
							value={this.state.form.voucherUsage} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId={`voucherTypeDesc-${this.state.form.productId}`}>
					<Form.Label column lg="3" className="text-right">Voucher Type Description</Form.Label>
					<Col>
						<MarkdownEditor
							name="voucherTypeDesc"
							handleSetValue={this.onMarkdownChange}
							value={this.state.form.voucherTypeDesc} />
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-right">Confirmation Type</Form.Label>
					<Col>
						<Form.Control
							type="text"
							autoComplete="off"
							name="confirmationType"
							onChange={(e) => this.onFieldChange(e)}
							value={this.state.form.confirmationType} />
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column lg="3" className="text-right">Confirmation Details</Form.Label>
					<Col>
						<MarkdownEditor
							name="confirmationDetails"
							handleSetValue={this.onMarkdownChange}
							value={this.state.form.confirmationDetails} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId={`productMinPax-${this.state.form.productId}`}>
					<Form.Label column lg="3" className="text-right">Min Pax</Form.Label>
					<Col>
						<Form.Control
							type="text"
							autoComplete="off"
							name={`productMinPax`}
							onChange={(e) => this.onFieldChange(e)}
							value={this.state.form.productMinPax} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId={`productMaxPax-${this.state.form.productId}`}>
					<Form.Label column lg="3" className="text-right">Max Pax</Form.Label>
					<Col>
						<Form.Control
							type="text"
							autoComplete="off"
							name={`productMaxPax`}
							onChange={(e) => this.onFieldChange(e)}
							value={this.state.form.productMaxPax} />
					</Col>
				</Form.Group>

				<BtnWithAmendmentNotes
					text="Create New Item"
					isSticky
					handleClcik={this.onSaveItemClick}
				/>

			</Form>
    );
  }
}

NewItem.propTypes = {
	activityId: PropTypes.string,
	item: PropTypes.object,
	skuList: PropTypes.array,
	isComponentLoading: PropTypes.bool,
	handleCreateItem: PropTypes.func,
}

export default NewItem;
