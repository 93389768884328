import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Link} from 'react-router-dom';
import {Row, Col, Form, Button, ListGroup, Card} from 'react-bootstrap';
import {Text, IconMapPin, Breadcrumbs, BreadcrumbItem} from "sancho";
import Pagination from '../components/Pagination';
import CountrySelect from '../components/CountrySelect';
import ProductTypeSelect from '../components/ProductTypeSelect';
import CitySelect from '../components/CitySelect';
import SupplierCodeSelect from '../components/SupplierCodeSelect';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {getProductList} from '../actions/product';
import {prettyDateTime, prettySingleDate, isExpired} from '../util/transformDate';
import _ceil from 'lodash/ceil';
// import _filter from 'lodash/filter';
// import _lowerCase from 'lodash/lowerCase';
import _chunk from 'lodash/chunk';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import _values from 'lodash/values';
const chunkLength = 20;

class ProductListPage extends Component {
	constructor(props) {
		super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSelectCkick = this.onSelectCkick.bind(this);
    this.onProductClick = this.onProductClick.bind(this);
    this.onResetClick = this.onResetClick.bind(this);

    this.state = {
      currentPage: 0,
			title: '',
      countryId: '',
      cityId: '',
      productTypeCode: '',
      enginecode: '',
      activityId: '',
    }
	}

  componentDidMount() {
		if (this.props.searchProductCriteria) {
			_map(this.props.searchProductCriteria, (value, key) => this.onSelectCkick(key, value))
		}

		if (!this.props.productList) {
			this.props.getProductList(
				this.state.title,
	      this.state.countryId,
	      this.state.cityId,
	      this.state.productTypeCode,
	      this.state.enginecode,
	      this.state.activityId,
			)
		}
  }

	componentDidUpdate(prevProps, prevState) {
	}

	onPageChange(page) {
		this.setState({
			currentPage: page
		})
	}

	onSearchInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

	onSelectCkick(name, value) {
		this.setState({
      [name]: value
    })
	}

  onSearchClick() {
		this.props.getProductList(
			this.state.title,
			this.state.countryId,
			this.state.cityId,
			this.state.productTypeCode,
			this.state.enginecode,
			this.state.activityId,
		)
  }

	onKeyDown(event) {
    if (event.key === "Enter") {
			this.onSearchClick()
    }
  }

	onProductClick(id) {
    this.props.history.push(`product/${id}`);
  }

	onResetClick() {
    this.setState({
			title: '',
			countryId: '',
			cityId: '',
			productTypeCode: '',
			enginecode: '',
			activityId: '',
		})
		this.props.getProductList(
			'','','','','',''
		)
  }

  render() {
		// const filteredProductList = _filter(this.props.productList, prod => _lowerCase(prod.title).includes(_lowerCase(this.state.title))) // instan corting
		let haveCriteria = _findIndex(_values(this.props.searchProductCriteria), value => value.length > 0) >= 0
		const filteredProductList = this.props.productList
    return (
      <div>
				<title>Product Management System</title>

				<div className="container">
					<Breadcrumbs className="mt-1 mb-3">
						<BreadcrumbItem>
							<Link to="/">Home</Link>
						</BreadcrumbItem>
						<BreadcrumbItem>Product List</BreadcrumbItem>
					</Breadcrumbs>

					<h1 className="mb-4 text-center">Product List</h1>
				</div>

				<div className="bg-grey p-4 mb-4" onKeyDown={this.onKeyDown}>
					<Card>
						<Card.Body>
	            <Row>
	              <Col lg="4">
	                Title
	                <Form.Control
	                  type="text"
	                  placeholder="Search Title"
	                  onChange={(e) => this.onSearchInputChange(e)}
	                  name="title"
	                  value={this.state.title} />
	              </Col>
	              <Col lg="4">
	                Country
	                <CountrySelect
										name="countryId"
										selectedCode={this.state.countryId}
										handleSelect={this.onSelectCkick}
										isClearable
									/>
	              </Col>
	              <Col lg="4">
	                City Id
	                <CitySelect
										name="cityId"
										selectedCode={this.state.cityId}
										handleSelect={this.onSelectCkick}
										countryId={this.state.countryId}
										isClearable
										isDisabled={!this.state.countryId}
									/>
	              </Col>
							</Row>
							<Row className="mt-2">
	              <Col lg="4">
	                Product Type
	                <ProductTypeSelect
										name="productTypeCode"
										selectedCode={this.state.productTypeCode}
										handleSelect={this.onSelectCkick}
										isClearable
									/>
	              </Col>
	              <Col lg="4">
	                Engine Code
	                <SupplierCodeSelect
										name="enginecode"
										selectedCode={this.state.enginecode}
										handleSelect={this.onSelectCkick}
										isClearable
									/>
	              </Col>
	              <Col lg="4">
	                Activity ID
	                <Form.Control
	                  type="text"
	                  placeholder="Search Activity ID"
	                  onChange={(e) => this.onSearchInputChange(e)}
	                  name="activityId"
	                  value={this.state.activityId} />
	              </Col>
							</Row>
							<Row className="justify-content-center mt-4">
								<Col lg="auto">
									<Button variant="outline-primary" onClick={() => this.onResetClick()} className="btn-block px-5"><FontAwesomeIcon icon={faTimes} className="mr-1" />Reset</Button>
								</Col>

								<Col lg="auto">
									<Button variant="outline-primary" onClick={() => this.props.history.push(`/create-product`)} className="btn-block"><FontAwesomeIcon icon={faPlus} className="mr-1" />Create New Product</Button>
								</Col>

								<Col lg="auto">
	                <Button onClick={() => this.onSearchClick()} className="btn-block px-5"><FontAwesomeIcon icon={faSearch} className="mr-1" />Search</Button>
	              </Col>
	            </Row>
						</Card.Body>
					</Card>
        </div>
				<div className="container pb-5">
					{this.props.productList &&
						<div>
							<div className="small p-2">Total product: {this.props.productList.length}
								{haveCriteria && <div className="inline ml-2"><Button variant="link" onClick={() => this.onResetClick()} className="btn-block inline" size="sm"><FontAwesomeIcon icon={faTimes} className="mr-1" />Reset Search Criteria</Button></div>}
							</div>

							{filteredProductList.length > 0 ?
								<ListGroup variant="flush">
									<Row className="mt-2 small px-3 py-1 font-weight-bold">
										<Col lg="1">Activity ID</Col>
										<Col >Title</Col>
										<Col lg="1">Product<br />Type</Col>
										<Col lg="1">Spplier<br />Code</Col>
										<Col lg="2">Last Priced date</Col>
										<Col lg="2">Updated by</Col>
										<Col lg="2">Last Update</Col>
									</Row>
									{_chunk(filteredProductList, chunkLength)[this.state.currentPage].map(prod =>
										<ListGroup.Item action key={prod.pmid} onClick={() => this.onProductClick(prod.activityId)}>
											<Row>
												<Col lg="1">{prod.activityId}</Col>
												<Col >
													<Text variant="lead">{prod.title}</Text>
													<Text variant="h6" className="mb-0">{prod.productName}</Text>
													<Text className="mb-0"><IconMapPin /> {prod.city}</Text>
												</Col>
												<Col lg="1">{prod.producttypename}</Col>
												<Col lg="1">{prod.enginecode}</Col>
												<Col lg="2" className={isExpired(prod.lastPricedDate) ? 'red' : ''}>{prettySingleDate(prod.lastPricedDate)}</Col>
												<Col lg="2">{prod.updateby}</Col>
												<Col lg="2">{prettyDateTime(prod.updateon)}</Col>
											</Row>
										</ListGroup.Item>
									)}
								</ListGroup>:
						 		<div className="text-center no-result card">No products.</div>
							}

							{this.props.productList.length > chunkLength &&
								<Pagination
									noOfPages={_ceil(filteredProductList.length / chunkLength)}
									currentPage={this.state.currentPage}
									handlePageChange={this.onPageChange}
								/>
							}
						</div>
					}
				</div>

      </div>
    );
  }
}

ProductListPage.propTypes = {
	productList: PropTypes.array,
	searchProductCriteria: PropTypes.object,
};

function mapStateToProps(state) {
	return {
    productList: state.product.productList,
    searchProductCriteria: state.product.searchProductCriteria,
	}
}

const mapDispatchToProps = {
  getProductList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListPage);
