import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Table} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt, faPlus} from '@fortawesome/free-solid-svg-icons';

import CurrencySelect from '../../components/CurrencySelect';
import AddMarkupModal from '../../components/productDetailPage/AddMarkupModal';
import DeleteModal from '../../components/productDetailPage/DeleteModal';
import TypeCodeSelect from '../../components/TypeCodeSelect';
import BtnWithAmendmentNotes from '../../components/BtnWithAmendmentNotes';

import _find from 'lodash/find';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _map from 'lodash/map';

class Markups extends Component {
	constructor(props) {
		super(props);

		this.onFieldChange = this.onFieldChange.bind(this)
		this.onSwitchChange = this.onSwitchChange.bind(this)
		this.onToggleDeleteModal = this.onToggleDeleteModal.bind(this)
		this.onDeleteClick = this.onDeleteClick.bind(this)
		this.onSubmitChangeClick = this.onSubmitChangeClick.bind(this);
		this.onToggleAddModal = this.onToggleAddModal.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);

		this.state = {
			productMarkups: [],
			isDeleteModalOpen: false,
			idToBeDelete: null,
			isAddModleOpen: false,
			highlightId: null
		};
	}

	componentDidMount() {
		this.setList()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.updateMarkupSuccessTimStamp !== this.props.updateMarkupSuccessTimStamp) {
      this.onToggleAddModal(false)
      this.onToggleDeleteModal(false)
			this.changeHighLigh(null)
    }

		if (prevProps.productMarkups !== this.props.productMarkups) {
			this.setList()
		}

		if (prevProps.highlightId !== this.props.highlightId) {
			this.changeHighLigh(this.props.highlightId)
		}
	}

	onFieldChange(e, i) {
		const deep = _cloneDeep(this.state.productMarkups)
		_set(deep, [i, e.target.name], e.target.value)

		this.setState({
			productMarkups: deep
		})
	}

	onSwitchChange(e, i) {
		const deep = _cloneDeep(this.state.productMarkups)
		_set(deep, [i, e.target.name], e.target.checked)

		this.setState({
			productMarkups: deep
		})
	}

	onToggleDeleteModal(bool, id) {
		this.setState({
			isDeleteModalOpen: bool,
			idToBeDelete: bool ? id : null,
		})
	}

	onAddClick() {
		this.onToggleAddModal(true)
	}

	onToggleAddModal(bool) {
		this.setState({
			isAddModleOpen: bool
		})
	}

	onSelectChange(name, value, i) {
		const deep = _cloneDeep(this.state.productMarkups)
		_set(deep, [i, name], value)

		this.setState({
			productMarkups: deep
		})
	}

	onDeleteClick() {
		this.props.handleDelete(_find(this.props.productMarkups, {id: this.state.idToBeDelete}))
	}

	onSubmitChangeClick(amendmentNotes) {
		this.props.handleUpdate(_map(this.state.productMarkups, obj => Object.assign({'amendmentNotes': amendmentNotes}, obj))) // include amendmentNotes
  }

	changeHighLigh(id) {
		this.setState({
			highlightId: id
		})
	}

	setList() {
		this.setState({
			productMarkups: this.props.productMarkups
		})
	}

  render() {
		return (
			<div>
				<div className="text-right pb-3">
					<Button onClick={() => this.onAddClick()} variant="outline-primary"><FontAwesomeIcon icon={faPlus} className="mr-1" />Add Markup</Button>
				</div>

				{this.state.productMarkups.length > 0 ?
					<div>
						<Table striped bordered>
							<thead>
								<tr>
									<th width="45">ID</th>
									<th>Item name</th>
									<th width="150">SKU</th>
									<th width="230">Company Code</th>
									<th width="130">Currency</th>
									<th width="100">Price Above</th>
									<th width="100">Roundup</th>
									<th width="110">Markup % (percent)</th>
									<th width="100">Markup (amount)</th>
									<th width="70">Enable</th>
									<th width="70"></th>
								</tr>
							</thead>
							<tbody>
								{this.state.productMarkups.map((sku, i) =>
									<tr key={i} className={this.state.highlightId === sku.id ? 'row-highlight' : ''}>
										<td><div className="text-muted">{sku.id}</div></td>
										<td><div className="text-muted">{sku.productname}</div></td>
										<td><div className="text-muted">{sku.skuname}</div></td>
										<td>
											<TypeCodeSelect
												selectedTypeCode={sku.typecode}
												name="typecode"
												index={i}
												handleSelect={this.onSelectChange}
											/>
										</td>
										<td>
											<CurrencySelect
												handleSelect={this.onSelectChange}
												selectedCode={sku.curr}
												index={i}
												name="curr"
											/>
										</td>
										<td>
											<Form.Control
												type="text"
												autoComplete="off"
												name="priceabove"
												onChange={(e) => this.onFieldChange(e, i)}
												value={sku.priceabove} />
										</td>
										<td>
											<Form.Control
												type="text"
												autoComplete="off"
												name="roundup"
												onChange={(e) => this.onFieldChange(e, i)}
												value={sku.roundup} />
										</td>
										<td>
											<Form.Control
												type="text"
												autoComplete="off"
												name="r_markpercent"
												onChange={(e) => this.onFieldChange(e, i)}
												value={sku.r_markpercent} />
										</td>
										<td>
											<Form.Control
												type="text"
												autoComplete="off"
												name="r_markamt"
												onChange={(e) => this.onFieldChange(e, i)}
												value={sku.r_markamt} />
										</td>
										<td className="text-center">
											<Form.Check
										    type="switch"
										    label=" "
												checked={sku.isenable}
												name="isenable"
												id={`switch-${i}`}
												onChange={(e) => this.onSwitchChange(e, i)}
												className="mt-2"
										  />
										</td>
										<td className="text-center">
											<Button variant="outline-danger" onClick={() => this.onToggleDeleteModal(true, sku.id)}><FontAwesomeIcon icon={faTrashAlt}  /></Button>
										</td>

									</tr>
								)}
							</tbody>
						</Table>
						<BtnWithAmendmentNotes
							text="Save Markups Change"
							isSticky
							handleClcik={this.onSubmitChangeClick}
						/>
					</div>:
					<div className="p-5 text-center card text-muted">No Markups.</div>
				}


				<AddMarkupModal
					handleClose={this.onToggleAddModal}
					handleConfirm={this.props.handleCreateMarkup}
					show={this.state.isAddModleOpen}
					itemList={this.props.itemList}
					itemSKU={this.props.itemSKU}
					handleGetItemSKU={this.props.handleGetItemSKU}
				/>

				<DeleteModal
					show={this.state.isDeleteModalOpen}
					handleConfirm={this.onDeleteClick}
					handleClose={() => this.onToggleDeleteModal(false)}
					description={this.state.isDeleteModalOpen ? `markup for : ${_find(this.props.productMarkups, {id: this.state.idToBeDelete}).productname} - ${_find(this.props.productMarkups, {id: this.state.idToBeDelete}).skuname} (id: ${this.state.idToBeDelete})` : ''}
				/>
			</div>
    );
  }
}

Markups.propTypes = {
	productMarkups: PropTypes.array,
	handleDelete: PropTypes.func,
	handleUpdate: PropTypes.func,
	updateMarkupSuccessTimStamp: PropTypes.number,
	itemList: PropTypes.object,
	handleGetItemSKU: PropTypes.func,
	handleCreateMarkup: PropTypes.func,
	itemSKU: PropTypes.array,
	highlightId: PropTypes.number,
}

export default Markups;
