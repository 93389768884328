import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle, faCircle, faEdit, faClipboardCheck, faCheck, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Button, Form, Tab, Card, Table, Nav, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Breadcrumbs, BreadcrumbItem} from "sancho";
import MarkdownEditor from '../components/MarkdownEditor';
import CustomDatePicker from '../components/CustomDatePicker';
import AddSkuModal from '../components/bookingDetailPage/AddSkuModal';
import EditSkuModal from '../components/bookingDetailPage/EditSkuModal';
import AddTravelerInfoModal from '../components/bookingDetailPage/AddTravelerInfoModal';
import TabMsg from '../components/bookingDetailPage/TabMsg';
import SupplierCodeSelect from '../components/SupplierCodeSelect';
import Loading from '../components/Loading';
import Auth from '../Auth/Auth';

import {prettyDateTime} from '../util/transformDate';
import {status} from '../util/constances';
import {
  getBookingDetail,
  updateBooking,
  retrieveBkgHistory,
  addBookingHistory,
  getBookingSKU,
  updateBookingSku,
  addBookingSku,
  getBookingExtraInfo,
  addBookingExtraInfoTravelers,
  deleteBookingExtraInfoTravelers,
  processToMOE,
  getBookingMessage,
  deleteBookingMessage,
  createBookingMessage,
  readBookingMessage,
  clearBookingDetail,
  markFulfill,
} from '../actions/booking';

import _startCase from 'lodash/startCase';
import _extend from 'lodash/extend';
import _cloneDeep from 'lodash/cloneDeep';
import _split from 'lodash/split';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import _sumBy from 'lodash/sumBy';
import _find from 'lodash/find';
import _map from 'lodash/map';

const auth = new Auth();

class BookingDetailPage extends Component {
  constructor(props) {
    super(props);

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onAddNoteClick = this.onAddNoteClick.bind(this);
    this.onMarkdownChange = this.onMarkdownChange.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onDateClick = this.onDateClick.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onTabSelect = this.onTabSelect.bind(this);
    this.onHistoryNoteFieldChange = this.onHistoryNoteFieldChange.bind(this);
    this.onExtraInfoFieldChange = this.onExtraInfoFieldChange.bind(this);
    this.onHoverTravelerInfo = this.onHoverTravelerInfo.bind(this);
    this.onDeleteTravelerClick = this.onDeleteTravelerClick.bind(this);
    this.onSendToMoeClick = this.onSendToMoeClick.bind(this);
    this.onItemMarkdownChange = this.onItemMarkdownChange.bind(this);
    this.onItemFieldChange = this.onItemFieldChange.bind(this);
    this.onAbleClick = this.onAbleClick.bind(this);
    this.onMarkFulfillClick = this.onMarkFulfillClick.bind(this);

    this.state = {
      form: null,
      tabIndex: 0,
      note: '',
      isAddSkuModalOpen: false,
      isEditSkuModalOpen: false,
      isAddTravelerInfoModalOpen: false,
      travelerErrorMsg: null,
      travelerErrorType: null,
      isFormDiabled: true
    };
  }

  componentDidMount() {
    this.props.getBookingDetail(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.clearBookingDetail()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bookingDetail !== this.props.bookingDetail) {
      this.setForm()
      this.props.getBookingSKU(this.props.match.params.id)
      this.props.getBookingExtraInfo(this.props.match.params.id)
    }

    if (!prevProps.shouldRefreshBooking && this.props.shouldRefreshBooking) {
      this.closeModal(false)
      this.props.getBookingDetail(this.props.match.params.id)
    }
    if (!prevProps.shouldRefreshBookingSKU && this.props.shouldRefreshBookingSKU) {
      this.closeModal()
      this.props.getBookingSKU(this.props.bookingDetail.get("productSySID"))
    }
    if (!prevProps.shouldRefreshBookingExtraInfo && this.props.shouldRefreshBookingExtraInfo) {
      this.closeModal()
      this.props.getBookingExtraInfo(this.props.bookingDetail.get("productSySID"))
    }

    if (!prevProps.shouldGetHistory && this.props.shouldGetHistory) {
      this.props.retrieveBkgHistory(this.props.bookingDetail.get("productSySID"))
    }

    if (!prevProps.shouldRefreshBookingMessage && this.props.shouldRefreshBookingMessage) {
      this.props.getBookingMessage(this.props.bookingDetail.get("productSySID"))
    }

    if (prevProps.bookingSku && prevProps.bookingSku !== this.props.bookingSku) {
      this.calculateQty()
    }

    if ((!prevProps.bookingSku && this.props.bookingSku && this.props.bookingExtraInfo) ||
      (!prevProps.bookingExtraInfo && this.props.bookingExtraInfo && this.props.bookingSku )
    ) {
      this.calculateQty()
    }

    if ((!prevProps.bookingExtraInfo && this.props.bookingExtraInfo) ||
    (prevProps.updatedBookingExtraInfoTimeStamp !== this.props.updatedBookingExtraInfoTimeStamp)) {
      this.setExtraInfo()
      this.calculateQty()
    }

  }

  onFieldChange(e) {
    this.setState({
      form: _extend(this.state.form, {
        [e.target.name]: e.target.value
      })
    })
  }

  onTabSelect(eventKey) {

    if (eventKey === 'history') {
      this.props.retrieveBkgHistory(this.props.bookingDetail.get("productSySID"))
    }

    if (eventKey === 'message' && !this.props.bookingMessage) {
      this.props.getBookingMessage(this.props.bookingDetail.get("productSySID"))
    }
  }

  onSelectChange(name, value) {
    this.setState({
      form: _extend(this.state.form, {
        [name]: value
      })
    })
  }

  onKeyDown(e) {
		if (e.key === 'Enter') {
			this.onAddNoteClick()
		}
	}

  onAddNoteClick() {
    this.props.addBookingHistory(this.props.bookingDetail.get("productSySID"), this.state.note)
  }

  onMarkdownChange(name, value) {
    if (name.includes('-')) {
      const deep = _cloneDeep(this.state.form.productDetail[_split(name, '-')[0]])
      _set(deep, [_split(name, '-')[1]], value)

      this.setState({
        form: _extend(this.state.form, {
          productDetail: _extend(this.state.form.productDetail, {
            [_split(name, '-')[0]]: deep
          })
        })
      })
    } else {
      this.setState({
        form: _extend(this.state.form, {
          productDetail: _extend(this.state.form.productDetail, {
            [name]: value
          })

        })
      })
    }
  }

  onItemMarkdownChange(name, value) {
    this.setState({
      form: _extend(this.state.form, {
        itemDetail: _extend(this.state.form.itemDetail, {
          [name]: value
        })
      })
    })
  }

  onItemFieldChange(e) {
    this.setState({
      form: _extend(this.state.form, {
        itemDetail: _extend(this.state.form.itemDetail, {
          [e.target.name]: e.target.value
        })
      })
    })
  }

  onExtraInfoFieldChange(e, type, index) {
    const deepClone = _cloneDeep(this.state.extraInfo)
    if (type === 'general') {
      _set(deepClone, ['general', index, 'value'], e.target.value)
    } else if (type === 'travelers') {
      _set(deepClone, ['travelers', index, 'Info', _findIndex(_get(deepClone, [type, index, 'Info']), {"extraInfoID": e.target.name}), 'value'], e.target.value)
    }

    this.setState({
      extraInfo: deepClone
    })
  }

  onSubmitClick() {
    this.props.updateBooking(this.state.form, this.state.extraInfo)
  }

  onDateClick(date, name) {
    this.setState({
      form: _extend(this.state.form, {
        [name]: date
      })
    })
  }

  onHistoryNoteFieldChange(e) {
    this.setState({
      note: e.target.value
    })
  }

  onHoverTravelerInfo(travelerSeq) {
    this.setState({
      travelerSeqToDelete: travelerSeq
    })
  }

  onDeleteTravelerClick(travelerSeq) {
    const extraInfoIdArray = _map(_find(this.props.bookingExtraInfo.travelers, {TravelerSeq: travelerSeq}).Info, 'extraInfoID')
    this.props.deleteBookingExtraInfoTravelers(this.props.bookingDetail.get("productSySID"), extraInfoIdArray)
  }

  onSendToMoeClick() {
    this.props.processToMOE(this.props.bookingDetail.get("productSySID"))
  }

  onMarkFulfillClick() {
    this.props.markFulfill(this.props.bookingDetail.get("productSySID"))
  }

  closeModal() {
    this.setState({
      isAddSkuModalOpen: false,
      isEditSkuModalOpen: false,
      isAddTravelerInfoModalOpen: false,
    })
  }

  calculateQty() {
    const bkgQty = this.props.bookingSku && _sumBy(this.props.bookingSku.bookings, 'quantity')
    const travelersQty = this.props.bookingExtraInfo.travelers ? this.props.bookingExtraInfo.travelers.length : 0

    if (travelersQty !== bkgQty) {
      this.setState({
        travelerErrorMsg: bkgQty > travelersQty ? `Add ${bkgQty - travelersQty} more traveler(s)` : `Delete ${travelersQty - bkgQty} traveler(s)`,
        travelerErrorType: bkgQty > travelersQty ? 'add' : 'delete'
      })
    } else {
      this.setState({
        travelerErrorMsg: null,
        travelerErrorType: null,
      })
    }
  }

  setForm() {
    this.setState({
      form: this.props.bookingDetail.toJS()
    })
  }

  setExtraInfo() {
    this.setState({
      extraInfo: this.props.bookingExtraInfo
    })
  }

  getMOEBtn(isStatusConfirmed) {
    if (isStatusConfirmed) {
      return (<Button
        variant="outline-primary"
        onClick={() => this.onSendToMoeClick(true)}>
        Send to MOE
      </Button>)
    } else {
      return (<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Send to MOE only if the status is <strong>Confirmed</strong></Tooltip>}>
        <span className="d-inline-block ">
          <Button
            variant="outline-primary"
            disabled
            style={{pointerEvents: 'none'}}
            onClick={() => this.onSendToMoeClick(true)}>
            Send to MOE
          </Button>
        </span>
      </OverlayTrigger>)
    }
  }

  onAbleClick() {
    if (!this.state.isFormDiabled) {
      this.setForm()
    }

    this.setState({
      isFormDiabled: !this.state.isFormDiabled
    })
  }

  render () {
    const bDetail = this.props.bookingDetail

    const hasUnreadMsg = this.props.myUnreadBookingMessage && bDetail && this.props.myUnreadBookingMessage.find(r =>(
      r.ProductSysID === bDetail.get('productSySID') &&
      (r.TypeCode !== auth.getCompanyCode() || r.Login !== auth.getUserCode())
    ))

    return (
      <div>
        <title>Booking Detail {bDetail && `#${bDetail.get("productSySID")}`} | Product Booking System</title>

          <div className="container">
            <Breadcrumbs className="mt-1 mb-3">
              <BreadcrumbItem>
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={'/booking-list'}>Booking List</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <div dangerouslySetInnerHTML={{
                  __html: bDetail ? `#${bDetail.get("productSySID")} <b>${bDetail.get("activityName")}</b>` : 'N/A'
                }}></div>
              </BreadcrumbItem>
            </Breadcrumbs>

            <h1 className="text-center">Booking Detail</h1>
            {bDetail &&
              <div>
                <Row className="my-4">
                  <Col lg="auto">Order ID <b>{bDetail.get("productSySID")}</b></Col>
                  <Col lg="auto">Booking created on <b>{prettyDateTime(bDetail.get("createOn"))}</b> by <b>{bDetail.get("email")}</b></Col>
                  <Col className="text-lg-right">{bDetail.get("isFulfill") && <div className="text-success"><FontAwesomeIcon icon={faClipboardCheck} /> Booking has been noted by fulfillment.</div>}</Col>
                </Row>
                <Tab.Container defaultActiveKey="detail">
                  <Card>
                    <Card.Header>
                      <Nav variant="tabs" onSelect={selectedKey => this.onTabSelect(selectedKey)}>
                        <Nav.Item><Nav.Link eventKey="detail">Detail</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="history">History</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="message">Message {hasUnreadMsg && <FontAwesomeIcon icon={faCircle} className="red" size="xs" />}</Nav.Link></Nav.Item>
                      </Nav>

                      <div className="float-right-btn">
                        <Button
                          className="mr-2"
                          variant="outline-primary"
                          disabled={bDetail.get("isFulfill")}
                          onClick={() => this.onMarkFulfillClick()}>
                          Mark as noted by fulfillment
                        </Button>

                        {this.getMOEBtn(bDetail.get("bookingStatus") === 'KK')}
                      </div>



                    </Card.Header>
                    <Card.Body>
                      <Tab.Content>
                        <Tab.Pane eventKey="detail">
                          <div className="text-right mb-2">
                            <Button onClick={()=>this.onAbleClick()}><FontAwesomeIcon icon={faEdit} /> {this.state.isFormDiabled ? 'Edit form' : 'Cancel change'}</Button>
                          </div>
                          {this.state.form &&
                            <div>
                              <Row>
                                <Form.Group as={Col} controlId="activityName">
                									<Form.Label >Product</Form.Label>
              										<Form.Control
              											type="text"
              											autoComplete="off"
              											name="activityName"
              											onChange={(e) => this.onFieldChange(e)}
              											value={this.state.form.activityName}
                                    disabled={this.state.isFormDiabled} />
                								</Form.Group>
                                <Form.Group as={Col} controlId="productName">
                									<Form.Label >Item</Form.Label>
              										<Form.Control
              											type="text"
              											autoComplete="off"
              											name="productName"
              											onChange={(e) => this.onFieldChange(e)}
              											value={this.state.form.productName}
                                    disabled={this.state.isFormDiabled} />
                								</Form.Group>
                              </Row>

                              <Row>
                                <Form.Group as={Col}>
                                  <Form.Label>Status</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="bookingStatus"
                                    onChange={(e) => this.onFieldChange(e)}
                                    value={this.state.form.bookingStatus}
                                    className={`service-status-${this.state.form.bookingStatus}`}
                                    disabled={this.state.isFormDiabled}
                                  >
                                    {status.map(sta =>
                                      <option value={sta.code} key={sta.code}>{sta.name}</option>
                                    )}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col}>
                                  <Form.Label>Spplier Code</Form.Label>
                                  <SupplierCodeSelect
                                    handleSelect={this.onSelectChange}
                                    selectedCode={this.state.form.engineCode}
                                    name="engineCode"
                                    disabled={this.state.isFormDiabled}
                                  />
                								</Form.Group>
                              </Row>

                              <Row>
                                <Form.Group as={Col} controlId="requestDate">
                									<Form.Label>Travel Date</Form.Label>
                                  <CustomDatePicker
                                    handleSelect={this.onDateClick}
                                    name="requestDate"
                                    value={this.state.form.requestDate}
                                    disabled={this.state.isFormDiabled}
                                  />
                								</Form.Group>

                                <Form.Group as={Col} controlId="timeslot">
                									<Form.Label >Timeslot <span className="text-muted pl-2">(<code>00:00</code> means no timeslot)</span></Form.Label>
              										<Form.Control
              											type="text"
              											autoComplete="off"
              											name="timeslot"
                                    maxLength="5"
              											onChange={(e) => this.onFieldChange(e)}
              											value={this.state.form.timeslot}
                                    disabled={this.state.isFormDiabled} />
                								</Form.Group>
                              </Row>

                              <Row>
                                <Form.Group as={Col} lg={6} controlId="engineRef">
                									<Form.Label>Booking Ref</Form.Label>
                                  <Form.Control
              											type="text"
              											autoComplete="off"
              											name="engineRef"
              											onChange={(e) => this.onFieldChange(e)}
              											value={this.state.form.engineRef}
                                    disabled={this.state.isFormDiabled} />
                								</Form.Group>
                              </Row>

                              <div className="mb-3">
                                <Form.Label>SKU</Form.Label>

                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th>Title</th>
                                      <th width="70">Qty</th>
                                      <th>Status</th>
                                      <th>Item Ref</th>
                                      <th>Sell</th>
                                      <th>Cost</th>
                                      <th>Net net</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.props.bookingSku ?
                                      this.props.bookingSku.bookings.map((bk, i) =>
                                      <tr key={i}>
                                        <td>{bk.skuName}</td>
                                        <td>{bk.quantity}</td>
                                        <td>{bk.itemStatus}</td>
                                        <td>{bk.itemRef}</td>
                                        <td>{bk.sellCurrency} {bk.sellTtlAmt}</td>
                                        <td>{bk.costCurrency} {bk.costTtlamt}</td>
                                        <td>{bk.netnetCurrency} {bk.netnetTtlamt}</td>
                                      </tr>
                                      ):
                                      <tr>
                                        <td colSpan="7">Loading...</td>
                                      </tr>
                                    }
                                    <tr>
                                      <td lg="3"></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        {this.props.bookingSku ?
                                          <div className="text-info font-weight-bold"><div className="small">Total Sell</div>{this.props.bookingSku.sellCurrency} {this.props.bookingSku.sellTtlAmt}</div>:
                                          <div>Loading..</div>
                                        }
                                      </td>
                                      <td>
                                        {this.props.bookingSku ?
                                          <div className="text-info font-weight-bold"><div className="small">Total Cost</div>{this.props.bookingSku.costCurrency} {this.props.bookingSku.costTtlamt}</div>:
                                          <div>Loading..</div>
                                        }
                                      </td>
                                      <td>
                                        {this.props.bookingSku ?
                                          <div className="text-info font-weight-bold"><div className="small">Total Net</div>{this.props.bookingSku.netCurrency} {this.props.bookingSku.netTtlamt}</div>:
                                          <div>Loading..</div>
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="7" className="text-center">
                                        <Button variant="outline-primary" onClick={() => this.setState({isAddSkuModalOpen: true})} className="mr-3" disabled={this.state.isFormDiabled}><FontAwesomeIcon icon={faPlus} className="mr-2" />Add SKU</Button>
                                        <Button variant="outline-primary" onClick={() => this.setState({isEditSkuModalOpen: true})} disabled={this.state.isFormDiabled}><FontAwesomeIcon icon={faEdit} className="mr-2" />Edit SKU</Button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>

                              {this.state.extraInfo && (this.state.extraInfo.general.length > 0 || this.state.extraInfo.travelers.length > 0) &&
                                <div className="mb-3">
                                  <ul className="layer">
                                    <li className="root layer-li">
                                      <Form.Label>Extra Information</Form.Label>
                                    </li>
                                    {this.state.extraInfo.general.length > 0 &&
                                      <li className="layer-li">
                                        <div>
                                          <div>General Info</div>
                                          <Table bordered className="mb-0">
                                            <tbody>
                                              {this.state.extraInfo.general.map((n, i) =>
                                                <tr key={i}>
                                                  <td className="font-weight-bold">{n.remark1}</td>
                                                  <td><Form.Control
                                                  type="text"
                                                  onChange={(e) => this.onExtraInfoFieldChange(e, 'general', i)}
                                                  value={n.value}
                                                  disabled={this.state.isFormDiabled} /></td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </Table>
                                        </div>
                                      </li>
                                    }
                                    {this.state.extraInfo.travelers.length > 0 &&
                                      <li className="layer-li">
                                        <div>Traveler Info
                                          {this.state.travelerErrorMsg && <span className="red"><FontAwesomeIcon icon={faExclamationCircle} className="ml-2 mr-1" />{this.state.travelerErrorMsg}</span>}
                                        </div>
                                        <Table bordered className={this.state.travelerErrorMsg ? 'table-danger' : ''}>
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              {this.state.extraInfo.travelers[0].Info.map((info, i) =>
                                                <th className="" key={i}>{info.remark1}</th>
                                              )}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.extraInfo.travelers.map((n, i) =>
                                              <tr key={i} onMouseOver={() => this.onHoverTravelerInfo(n.TravelerSeq)}>

                                                <td>{i+1}</td>
                                                {n.Info.map(input =>
                                                  <td key={input.extraInfoID}><Form.Control
                                                    type="text"
                                                    name={input.extraInfoID}
                                                    onChange={(e) => this.onExtraInfoFieldChange(e, 'travelers', i)}
                                                    value={input.value}
                                                    disabled={this.state.isFormDiabled} /></td>
                                                )}
                                                {this.state.travelerErrorType === 'delete' && this.state.travelerSeqToDelete === n.TravelerSeq &&
                                                  <td className="text-center delete-row"><Button onClick={() => this.onDeleteTravelerClick(n.TravelerSeq)}>Delete</Button></td>
                                                }
                                              </tr>
                                            )}
                                            {this.state.travelerErrorType === 'add' &&
                                              <tr>
                                                <td colSpan="3" className="text-center">
                                                  <Button variant="danger" onClick={() => this.setState({isAddTravelerInfoModalOpen: true})}>Click here to {this.state.travelerErrorMsg}</Button>
                                                </td>
                                              </tr>
                                            }
                                          </tbody>
                                        </Table>
                                      </li>
                                    }
                                  </ul>
                                </div>
                              }

                              <div className="mb-3">
                                <ul className="layer">
                                  <li className="root layer-li"><Form.Label>Activity Guidelines</Form.Label></li>
                                  {Object.keys(this.state.form.productDetail.guidelinesObj).map(key =>
                                  <li key={key} className="layer-li">
                                    <div>{_startCase(key)}</div>
                                    <MarkdownEditor
                                      name={`guidelinesObj-${key}`}
                                      handleSetValue={this.onMarkdownChange}
                                      value={this.state.form.productDetail.guidelinesObj[key]}
                                      disabled={this.state.isFormDiabled} />
                                  </li>
                                  )}
                                </ul>
                              </div>

                              <div className="mb-3">
                                <ul className="layer">
                                  <li className="root layer-li"><Form.Label>Usage</Form.Label></li>
                                  {Object.keys(this.state.form.productDetail.usageObj).map(key =>
                                  <li key={key} className="layer-li">
                                    <div>{_startCase(key)}</div>
                                    <MarkdownEditor
                                      name={`usageObj-${key}`}
                                      handleSetValue={this.onMarkdownChange}
                                      value={this.state.form.productDetail.usageObj[key]}
                                      disabled={this.state.isFormDiabled} />
                                  </li>
                                  )}
                                </ul>
                              </div>

                              <div className="mb-3">
                                <ul className="layer">
                                  <li className="root layer-li"><Form.Label>Item</Form.Label></li>
                                  <li className="layer-li">
                                    <div>Detail</div>
                                    <MarkdownEditor
                                      name={`detail`}
                                      handleSetValue={this.onItemMarkdownChange}
                                      value={this.state.form.itemDetail.detail}
                                      disabled={this.state.isFormDiabled} />
                                  </li>
                                  <li className="layer-li">
                                    <div>Voucher Usage</div>
                                    <MarkdownEditor
                                      name={`voucherUsage`}
                                      handleSetValue={this.onItemMarkdownChange}
                                      value={this.state.form.itemDetail.voucherUsage}
                                      disabled={this.state.isFormDiabled} />
                                  </li>
                                  <li className="layer-li">
                                    <div>Voucher Type Desc</div>
                                    <MarkdownEditor
                                      name={`voucherTypeDesc`}
                                      handleSetValue={this.onItemMarkdownChange}
                                      value={this.state.form.itemDetail.voucherTypeDesc}
                                      disabled={this.state.isFormDiabled} />
                                  </li>
                                  <li className="layer-li">
                                    <div>Confirmation Type</div>
                                    <Form.Control
                                      type="text"
                                      value={this.state.form.itemDetail.confirmationType}
                                      name="confirmationType"
                                      onChange={(e) => this.onItemFieldChange(e)}
                                      disabled={this.state.isFormDiabled}
                                    />
                                  </li>
                                  <li className="layer-li">
                                    <div>Confirmation Details</div>
                                    <MarkdownEditor
                                      name={`confirmationDetails`}
                                      handleSetValue={this.onItemMarkdownChange}
                                      value={this.state.form.itemDetail.confirmationDetails}
                                      disabled={this.state.isFormDiabled} />
                                  </li>
                                </ul>
                              </div>

                              <div className="mb-3">
                                <Form.Label>Terms and Condition</Form.Label>
                                <MarkdownEditor
                                  name="termsAndConditions"
                                  handleSetValue={this.onMarkdownChange}
                                  value={this.state.form.productDetail.termsAndConditions}
                                  disabled={this.state.isFormDiabled} />
                              </div>
                            </div>
                          }
                          <div className="text-center">
                            <Button onClick={() => this.onSubmitClick()} disabled={this.state.isFormDiabled}><FontAwesomeIcon icon={faCheck} className="mr-2" />Submit Change</Button>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="history">
                            <table className="table table-bordered mt-2 mb-5 table-striped">
                              <thead>
                                <tr>
                                  <th width="24">#</th>
                                  <th width="200">Date</th>
                                  <th>Notes</th>
                                  <th width="150">Layer</th>
                                  <th width="170">Created By</th>
                                </tr>
                              </thead>
                              {this.props.bookingHistory ?
                                <tbody>
                                  {this.props.bookingHistory.map((history, i) =>
                                    <tr key={i}>
                                     <td>{this.props.bookingHistory.length - i}</td>
                                     <td>{history.createon}</td>
                                     <td>{history.notes}</td>
                                     <td>{history.layer}</td>
                                     <td>{history.createby}</td>
                                   </tr>
                                  )}
                                </tbody>:
                                <tbody>
                                  <tr>
                                    <td colSpan="5" className="text-center relative p-5">No history.
                                    {this.props.componentLoading &&
                                      <Loading isAbsolute />
                                    }
                                    </td>
                                  </tr>
                                </tbody>
                              }
                            </table>


                          <div onKeyDown={(e) => this.onKeyDown(e)}>
                            <Form.Group as={Row} controlId="note" className="justify-content-center">
                              <Form.Label column xs="auto" className="text-right">
                                Add history note:
                              </Form.Label>
                              <Col xs="5">
                                <Form.Control
                                  type="text"
                                  value={this.state.note}
                                  name="note"
                                  onChange={(e) => this.onHistoryNoteFieldChange(e)}
                                  maxLength={250}
                                />
                              </Col>
                            </Form.Group>

                            <div className="text-center mt-4">
                              <Button
                                variant="primary"
                                type="button"
                                onClick={this.onAddNoteClick}
                                disabled={!this.state.note}
                              ><FontAwesomeIcon icon={faPlus} className="mr-2" />Add Note</Button>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="message">
                          <TabMsg
                            htlMessages={this.props.bookingMessage}
                            handleDeleteMsg={this.props.deleteBookingMessage}
                            handleCreateMsg={this.props.createBookingMessage}
                            handleReadMsg={this.props.readBookingMessage}
                            orderId={this.props.bookingDetail.get("productSySID")}
                            loading={this.props.componentLoading}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </Card.Body>
                  </Card>
                </Tab.Container>


                <AddSkuModal
        					handleClose={() => this.setState({isAddSkuModalOpen: false})}
        					handleConfirm={this.props.addBookingSku}
        					show={this.state.isAddSkuModalOpen}
        					orderid={this.props.bookingDetail.get("productSySID")}
        				/>

                {this.props.bookingSku &&
                  <EditSkuModal
                    skuList={this.props.bookingSku.bookings}
          					handleClose={() => this.setState({isEditSkuModalOpen: false})}
          					handleConfirm={this.props.updateBookingSku}
          					show={this.state.isEditSkuModalOpen}
          					orderid={this.props.bookingDetail.get("productSySID")}
          				/>
                }

                {this.props.bookingExtraInfo && this.props.bookingExtraInfo.travelers.length > 0 &&
                  <AddTravelerInfoModal
                    travelerSample={this.props.bookingExtraInfo.travelers[0].Info}
          					handleClose={() => this.setState({isAddTravelerInfoModalOpen: false})}
          					handleConfirm={this.props.addBookingExtraInfoTravelers}
          					show={this.state.isAddTravelerInfoModalOpen}
          					orderid={this.props.bookingDetail.get("productSySID")}
          				/>
                }
              </div>
            }
          </div>
      </div>
    );
  }
}


BookingDetailPage.propTypes = {
	bookingID: PropTypes.string,
	bookingDetail: PropTypes.object,
	shouldRefreshBooking: PropTypes.bool,
	shouldRefreshBookingSKU: PropTypes.bool,
	shouldRefreshBookingExtraInfo: PropTypes.bool,
	shouldGetHistory: PropTypes.bool,
	bookingHistory: PropTypes.array,
	bookingSku: PropTypes.object,
	bookingExtraInfo: PropTypes.object,
	updatedBookingExtraInfoTimeStamp: PropTypes.number,
	bookingMessage: PropTypes.array,
	shouldRefreshBookingMessage: PropTypes.bool,
	myUnreadBookingMessage: PropTypes.array,
};

function mapStateToProps(state) {
	return {
    bookingID: state.booking.bookingID,
    bookingDetail: state.booking.bookingDetail,
    shouldRefreshBooking: state.booking.shouldRefreshBooking,
    shouldRefreshBookingSKU: state.booking.shouldRefreshBookingSKU,
    shouldRefreshBookingExtraInfo: state.booking.shouldRefreshBookingExtraInfo,
    shouldGetHistory: state.booking.shouldGetHistory,
    bookingHistory: state.booking.bookingHistory,
    componentLoading: state.loading.componentLoading,
    bookingSku: state.booking.bookingSku,
    bookingExtraInfo: state.booking.bookingExtraInfo,
    updatedBookingExtraInfoTimeStamp: state.booking.updatedBookingExtraInfoTimeStamp,
    bookingMessage: state.booking.bookingMessage,
    shouldRefreshBookingMessage: state.booking.shouldRefreshBookingMessage,
    myUnreadBookingMessage: state.booking.myUnreadBookingMessage,
	}
}

const mapDispatchToProps = {
  getBookingDetail,
  updateBooking,
  retrieveBkgHistory,
  addBookingHistory,
  getBookingSKU,
  updateBookingSku,
  addBookingSku,
  getBookingExtraInfo,
  addBookingExtraInfoTravelers,
  deleteBookingExtraInfoTravelers,
  processToMOE,
  getBookingMessage,
  deleteBookingMessage,
  createBookingMessage,
  readBookingMessage,
  clearBookingDetail,
  markFulfill,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailPage);
