import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	pageLoading: false,
	componentLoading: false,
	imageLoading: false,
};

const loading = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_PRODUCT_LIST_REQUEST:
	case ActionTypes.GET_PRODUCT_DETAIL_REQUEST:
	case ActionTypes.GET_PRODUCT_ITEM_LIST_REQUEST:
	case ActionTypes.GET_PRODUCT_ITEM_DETAIL_REQUEST:
	case ActionTypes.UPDATE_DAILY_RATE_REQUEST:
	case ActionTypes.CREATE_SKU_REQUEST:
	case ActionTypes.UPDATE_PRODUCT_DETAIL_REQUEST:
	case ActionTypes.DELETE_PRODUCT_REQUEST:
	case ActionTypes.GET_BOOKING_LIST_REQUEST:
	case ActionTypes.UPDATE_BOOKING_DETAIL_REQUEST:
	case ActionTypes.CREATE_PRODUCT_REQUEST:
	case ActionTypes.ADD_BOOKING_SKU_REQUEST:
	case ActionTypes.UPDATE_EXTRA_INFO_REQUEST:
	case ActionTypes.DELETE_EXTRA_INFO_REQUEST:
	case ActionTypes.GET_BOOKING_DETAIL_REQUEST:
	case ActionTypes.UPDATE_BOOKING_SKU_REQUEST:
	case ActionTypes.ADD_BOOKING_TRAVELER_INFO_REQUEST:
	case ActionTypes.DELETE_BOOKING_TRAVELER_INFO_REQUEST:
	case ActionTypes.GET_DAILY_RATES_REQUEST:
	case ActionTypes.PROCESS_TO_MOE_REQUEST:
	case ActionTypes.MARK_FULFILL_REQUEST:
	case ActionTypes.GET_DETAIL_BY_TOKEN_REQUEST:
	case ActionTypes.GET_PRODUCT_MARKUP_REQUEST:
	case ActionTypes.CREATE_PRODUCT_MARKUP_REQUEST:
	case ActionTypes.VIEW_ALL_PRODUCT_MARKUP_REQUEST:
		return Object.assign({}, state, {
			pageLoading: true
		});
	case ActionTypes.GET_PRODUCT_LIST_SUCCESS:
	case ActionTypes.GET_PRODUCT_LIST_FAILURE:
	case ActionTypes.GET_PRODUCT_DETAIL_SUCCESS:
	case ActionTypes.GET_PRODUCT_DETAIL_FAILURE:
	case ActionTypes.GET_PRODUCT_ITEM_LIST_SUCCESS:
	case ActionTypes.GET_PRODUCT_ITEM_LIST_FAILURE:
	case ActionTypes.GET_PRODUCT_ITEM_DETAIL_SUCCESS:
	case ActionTypes.GET_PRODUCT_ITEM_DETAIL_FAILURE:
	case ActionTypes.UPDATE_DAILY_RATE_SUCCESS:
	case ActionTypes.UPDATE_DAILY_RATE_FAILURE:
	case ActionTypes.CREATE_SKU_SUCCESS:
	case ActionTypes.CREATE_SKU_FAILURE:
	case ActionTypes.UPDATE_PRODUCT_DETAIL_SUCCESS:
	case ActionTypes.UPDATE_PRODUCT_DETAIL_FAILURE:
	case ActionTypes.DELETE_PRODUCT_SUCCESS:
	case ActionTypes.DELETE_PRODUCT_FAILURE:
	case ActionTypes.GET_BOOKING_LIST_SUCCESS:
	case ActionTypes.GET_BOOKING_LIST_FAILURE:
	case ActionTypes.UPDATE_BOOKING_DETAIL_SUCCESS:
	case ActionTypes.UPDATE_BOOKING_DETAIL_FAILURE:
	case ActionTypes.CREATE_PRODUCT_SUCCESS:
	case ActionTypes.CREATE_PRODUCT_FAILURE:
	case ActionTypes.ADD_BOOKING_SKU_SUCCESS:
	case ActionTypes.ADD_BOOKING_SKU_FAILURE:
	case ActionTypes.UPDATE_EXTRA_INFO_SUCCESS:
	case ActionTypes.UPDATE_EXTRA_INFO_FAILURE:
	case ActionTypes.DELETE_EXTRA_INFO_SUCCESS:
	case ActionTypes.DELETE_EXTRA_INFO_FAILURE:
	case ActionTypes.GET_BOOKING_DETAIL_SUCCESS:
	case ActionTypes.GET_BOOKING_DETAIL_FAILURE:
	case ActionTypes.UPDATE_BOOKING_SKU_SUCCESS:
	case ActionTypes.UPDATE_BOOKING_SKU_FAILURE:
	case ActionTypes.ADD_BOOKING_TRAVELER_INFO_SUCCESS:
	case ActionTypes.ADD_BOOKING_TRAVELER_INFO_FAILURE:
	case ActionTypes.DELETE_BOOKING_TRAVELER_INFO_SUCCESS:
	case ActionTypes.DELETE_BOOKING_TRAVELER_INFO_FAILURE:
	case ActionTypes.GET_DAILY_RATES_SUCCESS:
	case ActionTypes.GET_DAILY_RATES_FAILURE:
	case ActionTypes.PROCESS_TO_MOE_SUCCESS:
	case ActionTypes.PROCESS_TO_MOE_FAILURE:
	case ActionTypes.MARK_FULFILL_SUCCESS:
	case ActionTypes.MARK_FULFILL_FAILURE:
	case ActionTypes.GET_DETAIL_BY_TOKEN_SUCCESS:
	case ActionTypes.GET_DETAIL_BY_TOKEN_FAILURE:
	case ActionTypes.GET_PRODUCT_MARKUP_SUCCESS:
	case ActionTypes.GET_PRODUCT_MARKUP_FAILURE:
	case ActionTypes.CREATE_PRODUCT_MARKUP_SUCCESS:
	case ActionTypes.CREATE_PRODUCT_MARKUP_FAILURE:
	case ActionTypes.VIEW_ALL_PRODUCT_MARKUP_SUCCESS:
	case ActionTypes.VIEW_ALL_PRODUCT_MARKUP_FAILURE:
		return Object.assign({}, state, {
			pageLoading: false
		});
	case ActionTypes.GET_PRODUCT_SKU_REQUEST:
	case ActionTypes.UPDATE_SKU_REQUEST:
	case ActionTypes.DELETE_SKU_REQUEST:
	case ActionTypes.UPDATE_ITEM_REQUEST:
	case ActionTypes.GET_BOOKING_HISTORY_REQUEST:
	case ActionTypes.GET_BOOKING_MESSAGE_REQUEST:
	case ActionTypes.DELETE_BOOKING_MESSAGE_REQUEST:
	case ActionTypes.CREATE_BOOKING_MESSAGE_REQUEST:
	case ActionTypes.READ_BOOKING_MESSAGE_REQUEST:
		return Object.assign({}, state, {
			componentLoading: true
		});
	case ActionTypes.GET_PRODUCT_SKU_SUCCESS:
	case ActionTypes.GET_PRODUCT_SKU_FAILURE:
	case ActionTypes.UPDATE_SKU_SUCCESS:
	case ActionTypes.UPDATE_SKU_FAILURE:
	case ActionTypes.DELETE_SKU_SUCCESS:
	case ActionTypes.DELETE_SKU_FAILURE:
	case ActionTypes.UPDATE_ITEM_SUCCESS:
	case ActionTypes.UPDATE_ITEM_FAILURE:
	case ActionTypes.GET_BOOKING_HISTORY_SUCCESS:
	case ActionTypes.GET_BOOKING_HISTORY_FAILURE:
	case ActionTypes.GET_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.GET_BOOKING_MESSAGE_FAILURE:
	case ActionTypes.DELETE_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.DELETE_BOOKING_MESSAGE_FAILURE:
	case ActionTypes.CREATE_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.CREATE_BOOKING_MESSAGE_FAILURE:
	case ActionTypes.READ_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.READ_BOOKING_MESSAGE_FAILURE:
		return Object.assign({}, state, {
			componentLoading: false
		});
	case ActionTypes.GET_PRODUCT_IMAGES_REQUEST:
	case ActionTypes.DELETE_PRODUCT_IMAGE_REQUEST:
	case ActionTypes.UPLOAD_PRODUCT_IMAGE_REQUEST:
		return Object.assign({}, state, {
			imageLoading: true
		});
	case ActionTypes.GET_PRODUCT_IMAGES_SUCCESS:
	case ActionTypes.GET_PRODUCT_IMAGES_FAILURE:
	case ActionTypes.DELETE_PRODUCT_IMAGE_SUCCESS:
	case ActionTypes.DELETE_PRODUCT_IMAGE_FAILURE:
	case ActionTypes.UPLOAD_PRODUCT_IMAGE_SUCCESS:
	case ActionTypes.UPLOAD_PRODUCT_IMAGE_FAILURE:
		return Object.assign({}, state, {
			imageLoading: false
		});
	default:
		return state;
	}
};

export default loading;
