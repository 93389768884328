import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Auth from '../Auth/Auth';
import Select from 'react-select';

import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _get from 'lodash/get';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

const endpoint = `${currentServerURL}/SearchTypecode`;
const method = 'POST'

class TypeCodeSelect extends Component {
	constructor(props) {
		super(props);
		this.onSelectInputChange = this.onSelectInputChange.bind(this);
		this.onSelectClick = this.onSelectClick.bind(this);

		this.state = {
			options: [],
			isSearching: false,
			currentObj: '',
		};

	}

	componentDidMount() {
		if (this.props.selectedTypeCode) {
			this.getTypeCodeLabel(this.props.selectedTypeCode)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.selectedTypeCode && prevProps.selectedTypeCode !== this.props.selectedTypeCode) {
			this.getTypeCodeLabel(this.props.selectedTypeCode)
		}
	}

	getTypeCodeLabel(string) {
		const body = {"keywords": string}
		fetch(endpoint, {
			method: method,
			body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
				if (result.responseSuccess) {
					let options = _map(result.typecodeList, (r) => ({
						'value': r.typecode,
						'label': r.typecode + ' - ' + r.typecodename
					}))
					this.setState({
						currentObj: _find(options, {'value': string})
					})
				}
			})
			.catch((error) => {
				console.log('error: ', error)
			});
	}

	onSelectInputChange = _debounce((string, action) => {
		const act = action.action
		if (act === "input-change") {
			console.log('onSelectInputChange', string)
			if (string) {
				this.setState({
					isSearching: true
				})

				const body = {"keywords": string}
		    fetch(endpoint, {
					method: method,
					body: JSON.stringify(body),
					headers: {
						Authorization: 'Bearer ' + auth.getAccessToken(),
						'Content-Type': 'application/json'
					}
				})
					.then(response => response.json())
					.then((result) => {
						if (result.responseSuccess ) {
							console.log(result.typecodeList)
							let options = _map(result.typecodeList, (r) => ({
								'value': r.typecode,
								'label': r.typecode + ' - ' + r.typecodename
							}))
							this.setState({
								options: options,
								isSearching: false
							})
						}
					})
					.catch((error) => {
						console.log('error: ', error)
						this.setState({
							isSearching: false
						})
					});

			} else {
				this.clearLoginOptions()
			}
		}
	 }, 1000)

	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.props.handleSelect(this.props.name, selectedOption.value, this.props.index)
			this.setState({
				currentObj: selectedOption
			})
		} else { // clear
			this.clearLoginOptions()
		}
	}

	clearLoginOptions() {
		this.setState({
			options: [],
			currentObj: ''
		});
		this.props.handleSelect(this.props.name, '')
	}

  render() {
		return (
			<div>
				{this.props.readOnly ?
					<p className="py-2 mb-0">{_get(this.state.currentObj, 'label')}</p> :
					<Select
						name="typeCodeSelect"
						value={this.state.currentObj}
						onChange={this.onSelectClick}
						options={this.state.options ? this.state.options : []}
						onInputChange={(string, action) => this.onSelectInputChange(string, action)}
						placeholder="Search Company"
						isClearable={this.props.isClearable}
						isLoading={this.state.isSearching}
					/>
				}
			</div>
    );
  }
}

TypeCodeSelect.propTypes = {
	isClearable: PropTypes.bool,
	handleSelect: PropTypes.func,
	selectedTypeCode: PropTypes.string,
	readOnly: PropTypes.bool,
	index: PropTypes.number,
	name: PropTypes.string,
}

export default TypeCodeSelect;
