import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Row, Col, Form} from 'react-bootstrap';
import Select from 'react-select';
import CurrencySelect from '../../components/CurrencySelect';
import TypeCodeSelect from '../../components/TypeCodeSelect';
import BtnWithAmendmentNotes from '../../components/BtnWithAmendmentNotes';

import _map from 'lodash/map';
import _find from 'lodash/find';

class AddMarkupModal extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onItemSelectClick = this.onItemSelectClick.bind(this);
    this.onSkuSelectClick = this.onSkuSelectClick.bind(this);
    this.onSwitchChange = this.onSwitchChange.bind(this);

		this.state = {
			"product_id": "",
			"sku_id": "",
			"typecode": "",
			"curr": "HKD",
			"r_markpercent": "",
			"r_markamt": "",
			"priceabove": "",
			"roundup": "",
			"isenable": false
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.show && this.props.show) {
			this.resetForm()
		}
	}

	resetForm() {
		this.setState({
			"product_id": "",
			"sku_id": "",
			"typecode": "",
			"curr": "HKD",
			"r_markpercent": "",
			"r_markamt": "",
			"priceabove": "",
			"roundup": "",
			"isenable": false
		})
	}

	onFieldChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onAddClick(amendmentNotes) {
		this.props.handleConfirm(Object.assign({'amendmentNotes': amendmentNotes}, this.state)) // include amendmentNotes
	}

	onSelectChange(name, value) {
		this.setState({
			[name]: value
		})
	}

	onKeyDown(event) {
    if (event.key === "Enter") {
			this.onAddClick()
    }
  };

	onItemSelectClick(option) {
		this.setState({
			product_id: option.value,
			sku_id: ''
		})
		this.props.handleGetItemSKU(option.value)
	}

	onSkuSelectClick(option) {
		this.setState({
			sku_id: option.value
		})
	}

	onSwitchChange(e) {
		this.setState({
			isenable: e.target.checked
		})
	}

  render() {
		let itemOptions = _map(this.props.itemList.toJS(), (r) => ({
			'value': r.productId,
			'label': r.title
		}))
		let skuOptions = (this.state.product_id && this.props.itemSKU) ? _map(this.props.itemSKU, (r) => ({
			'value': r.sku_id,
			'label': r.title
		})) : null

		return (
			<Modal centered show={this.props.show} onHide={() => this.props.handleClose(false)} onKeyDown={this.onKeyDown}>
				<Modal.Header>
					<Modal.Title>Add Mark up</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Row className="mb-3">
							<Col lg="5">Item*</Col>
							<Col>
								<Select
									name="product_id"
									onChange={this.onItemSelectClick}
									options={itemOptions}
									placeholder="Select Item"
									isLoading={!itemOptions}
									className="select-control"
								/>
							</Col>
						</Row>
						<Form.Group as={Row} >
							<Form.Label column lg="5">SKU*</Form.Label>
							<Col>
								<Select
									name='sku_id'
									value={this.state.sku_id ? _find(skuOptions, {value: this.state.sku_id}) : ''}
									onChange={this.onSkuSelectClick}
									options={skuOptions}
									placeholder="Select SKU"
									className={`select-control`}
									isDisabled={!this.state.product_id || !this.props.itemSKU}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Company Code*</Form.Label>
							<Col>
								<TypeCodeSelect
									selectedTypeCode={this.state.typecode}
									name="typecode"
									handleSelect={this.onSelectChange}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Currency</Form.Label>
							<Col><CurrencySelect
								handleSelect={this.onSelectChange}
								selectedCode={this.state.curr}
								name="curr"
							/></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Price Above</Form.Label>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="priceabove"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.priceabove} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Roundup</Form.Label>
							<Col>

								<Form.Control
									type="text"
									autoComplete="off"
									name="roundup"
									onChange={(e) => this.onFieldChange(e)}
									value={this.state.roundup} />
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Mark (Percent)</Form.Label>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="r_markpercent"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.r_markpercent} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Mark (Amt)</Form.Label>
							<Col><Form.Control
								type="text"
								autoComplete="off"
								name="r_markamt"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.r_markamt} /></Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column lg="5">Enable</Form.Label>
							<Col><Form.Check
								type="switch"
								label=" "
								checked={this.state.isenable}
								name="isenable"
								id="switch"
								onChange={(e) => this.onSwitchChange(e)}
								className="mt-2"
							/></Col>
						</Form.Group>

					</div>
				</Modal.Body>
				<Modal.Footer className="text-center">
					<BtnWithAmendmentNotes
						text="Add Markup"
						handleClcik={this.onAddClick}
						isDisabled={!this.state.product_id || !this.state.typecode || !this.state.sku_id}
						isAddIcon
					/>
				</Modal.Footer>
			</Modal>
	  );
	}
}

AddMarkupModal.propTypes = {
	handleClose: PropTypes.func,
	handleGetItemSKU: PropTypes.func,
	handleConfirm: PropTypes.func,
	show: PropTypes.bool,
	itemSKU: PropTypes.array,
}

export default AddMarkupModal;
